import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import { getListaProdProve } from "../Funciones/Productos";
import {
  firstLeterMayus,
  formatMoneda,
} from "../../../../../Lib/Funciones Generales/Generales";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Menos from "../../../../assets/Menos.svg";
import Mas from "../../../../assets/Mas.svg";
import Buscador from "../../../../includes/Buscador";
import { Col, Row } from "react-bootstrap";
import ModalCrearProductos from "./ModalCrearProductos";

function ModalSelectProd({
  proveedorId = 0,
  onClose = () => {},
  handleProductoSeleccionado = () => {},
  onlyInventariables = false,
  titulo = "Seleccionar productos",
  id_almacen = 0,
  id_sucursal = 0,
  isProductosDescuento = false,
  existencias = false,
  requiredTotal = true,
  onlyMateriaPrima = false,
  titulo_ = "Costo",
  //onlyProductosTerminados = false,
  MostrarBoton,
  productosCarrito,
  setProductosCarrito,
}) {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [productosDisponibles, setProductosDisponibles] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const disminuirItem = (newItem) => {
    const items = [...productosDisponibles];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD -= 1;
      if (items[itemIndex].CANTIDAD <= 0) {
        items.splice(itemIndex, 1);
      }
    } else {
    }
    setProductosDisponibles(items);
  };
  const addItem = (newItem) => {
    const items = [...productosDisponibles];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID === newItem.ID)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD += 1;
    } else {
      items.push(newItem);
    }
    setProductosDisponibles(items);
  };
  const handlInputChange = ({ target }) => {
    let ID = target.id;
    let Value = target.value;
    const items = [...productosDisponibles];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID == ID)
      : -1;
    if (itemIndex !== -1) {
      let cantidad = parseInt(Value);
      items[itemIndex].CANTIDAD = cantidad.toString();
    } else {
      alert("producto no encontrado");
    }
    setProductosDisponibles(items);
  };

  const getListaProductos = () => {
    getListaProdProve(
      proveedorId,
      //onlyProductosTerminados,
      onlyInventariables,
      onlyMateriaPrima,
      existencias,
      id_almacen,
      id_sucursal,
      ValueBusqueda
    )
      .then((resp) => {
        ///console.log(resp);
        if (resp.length > 0) {
          resp = resp.map((item) => {
            existencias
              ? requiredTotal
                ? (item.CANTIDAD = item.STOCK_ACTUAL)
                : (item.CANTIDAD = 1)
              : (item.CANTIDAD = 1);
            return item;
          });
        }
        setProductosDisponibles(resp);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    getListaProductos();
  }, [ValueBusqueda, ValueSelect]);

  const quitarProducto = (producto) => {
    const nuevosProductos = productosDisponibles.filter(
      (p) => p.ID !== producto.ID
    );
    setProductosDisponibles(nuevosProductos);
  };

  console.log("Lista Productos", productosDisponibles);

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={true}>
        <IconButton
          aria-label="Close"
          className="m-2"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon style={{ width: "30px", height: "30px" }} />
        </IconButton>
        {
          <DialogTitle>
            <h5 className="fw-bolder f-12 mt-2">{titulo}</h5>
          </DialogTitle>
        }
        <DialogContent style={{ minHeight: "70vh" }}>
          <div className="px-1 py-2">
            <Row>
              <Col xs={12} md={MostrarBoton ? 10 : 12}>
                {" "}
                <Buscador
                  placeholder="Buscar producto"
                  ValueBusqueda={ValueBusqueda}
                  setValueBusqueda={setValueBusqueda}
                />
              </Col>{" "}
              {MostrarBoton ? (
                <>
                  <Col xs={12} md={2}>
                    {" "}
                    <Button
                      style={{ float: "right" }}
                      className="btn btn-Crexendo font-AvenirMedium  py-1 px-4"
                      onClick={() => {
                        setShowModal(!showModal);
                      }}
                    >
                      Agregar productos
                    </Button>
                    {showModal && (
                      <ModalCrearProductos
                        onClose={() => {
                          setShowModal(!showModal);
                        }}
                        titulo="Crear productos para el ajuste"
                        productosDisponibles={productosDisponibles}
                        setProductosDisponibles={setProductosDisponibles}
                        getListaProductos={getListaProductos}
                      />
                    )}
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                  align="left"
                >
                  Descripcion
                </TableCell>
                <TableCell
                  className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                  align="left"
                >
                  {/*proveedorId == 0 &&
                  onlyInventariables == false &&
                  id_almacen == 0 &&
                  id_sucursal == 0 &&
                  isProductosDescuento == false &&
                  existencias == false
                    ? "Precio"
                    : "Costo"*/}
                  {titulo_}
                </TableCell>

                {onlyInventariables && (
                  <>
                    <TableCell
                      className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                      align="left"
                    >
                      Stock Actual.
                    </TableCell>
                    <TableCell
                      className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                      align="left"
                    >
                      Stock Max.
                    </TableCell>
                  </>
                )}
                {isProductosDescuento ? (
                  <></>
                ) : (
                  <TableCell
                    className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                    align="center"
                  ></TableCell>
                )}
                <TableCell
                  className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                  align="right"
                >
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productosDisponibles && Array.isArray(productosDisponibles)
                ? productosDisponibles.map((Productos) => (
                    <>
                      <TableRow key={Productos.ID}>
                        <TableCell
                          component="th"
                          scope="row"
                          className="d-none d-sm-table-cell"
                        >
                          {firstLeterMayus(Productos.DESC_CORTA)}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          className="d-none d-sm-table-cell"
                        >
                          {proveedorId == 0 &&
                          onlyInventariables == false &&
                          id_almacen == 0 &&
                          id_sucursal == 0 &&
                          isProductosDescuento == false &&
                          existencias == false
                            ? formatMoneda(Productos.PRECIO)
                            : formatMoneda(Productos.PRECIO_COMPRA)}
                        </TableCell>
                        {onlyInventariables && (
                          <>
                            <TableCell
                              component="th"
                              scope="row"
                              className="d-none d-sm-table-cell"
                            >
                              {Productos.STOCK_ACTUAL}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              className="d-none d-sm-table-cell"
                            >
                              {Productos.STOCK_MAX}
                            </TableCell>
                          </>
                        )}
                        {isProductosDescuento ? (
                          <></>
                        ) : (
                          <TableCell
                            component="th"
                            scope="row"
                            className="d-none d-sm-table-cell"
                            align="center"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                className="mx-2"
                                onClick={() => addItem(Productos)}
                              >
                                <img src={Mas} style={{ width: "15px" }} />
                              </IconButton>
                              <div
                                className="d-flex justify-content-center flex-column"
                                style={{ maxWidth: "150px" }}
                              >
                                <TextField
                                  style={{ maxWidth: "85px" }}
                                  size="small"
                                  fullWidth
                                  id={Productos.ID}
                                  label="Cantidad"
                                  variant="outlined"
                                  name={Productos.ID}
                                  value={
                                    isNaN(Productos.CANTIDAD)
                                      ? ""
                                      : Productos.CANTIDAD
                                  }
                                  //  helperText={Errores.cantidad ? "Cantidad no valida" : ""}
                                  onChange={handlInputChange}
                                />
                              </div>
                              <IconButton
                                aria-label="delete"
                                className="mx-2"
                                onClick={() => disminuirItem(Productos)}
                              >
                                <img src={Menos} style={{ width: "15px" }} />
                              </IconButton>
                            </div>
                          </TableCell>
                        )}
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                            onClick={() => {
                              handleProductoSeleccionado(
                                Productos,
                                Productos.CANTIDAD
                              );
                              quitarProducto(Productos);
                            }}
                          >
                            Agregar
                          </Button>
                        </TableCell>
                      </TableRow>
                    </>
                  ))
                : null}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default ModalSelectProd;
