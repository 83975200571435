import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import ExportarDanger from "../../../../assets/ExportarDanger.svg";
import ExportarSucess from "../../../../assets/ExportarSucess.svg";
import { Col, Row } from "react-bootstrap";
import TableZonas from "./Tabla";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import SelectOrdenamiento from "../../../../includes/SelectOrdenamiento";
import Buscador from "../../../../includes/Buscador";
import { getZonas } from "../Funciones/Zonas";
import ModalZonasEnvio from "./ModalZonasEnvio";
import MapIcon from "@mui/icons-material/Map";

const Zonas = () => {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [PrecioEnvio, setPrecioEnvio] = useState(0)

  useEffect(() => {
    getListaZonas();
  }, [ValueBusqueda, ValueSelect]);

  const getListaZonas = () => {
    setIsLoading(true);
    getZonas(0, 0, ValueSelect, ValueBusqueda)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Zonas`;
  }, []);

  return (
    <>
      <div class="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Zonas</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
            <Button onClick={() => setOpenModal(true)}
              className="btn btn-exp-Excel font-AvenirMedium"
              variant="text"
            >
              <MapIcon style={{ color: "#14b8a6", marginRight: '5px' }} />
              Cotiza tú envío
            </Button>
            {/* <Button
              className="btn  btn-exp-PDF  font-AvenirMedium"
              variant="text"
            >
              <img className="me-2" src={ExportarDanger} />
              Cotiza tú envóo
            </Button> */}
            <Link to="./add" style={{ textDecoration: "none" }}>
              <Button className="btn btn-Crexendo px-4 " variant="contained">
                Crear nueva
              </Button>
            </Link>
          </Stack>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12} md={8}>
            <Buscador
              placeholder="Buscar zona por nombre"
              ValueBusqueda={ValueBusqueda}
              setValueBusqueda={setValueBusqueda}
            />
          </Col>
          <Col xs={12} md={4}>
            <SelectOrdenamiento
              ValueSelect={ValueSelect}
              setValueSelect={setValueSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TableZonas
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
              ValueBusqueda={ValueBusqueda}
            ></TableZonas>
          </Col>
        </Row>
      </Card>

      {openModal && (
        <ModalZonasEnvio
          titulo='Cotización de envío'
          fnCerrar={() => setOpenModal(false)}
          PrecioEnvio={PrecioEnvio}
          setPrecioEnvio={setPrecioEnvio} />
      )}
    </>
  );
};

export default Zonas;
