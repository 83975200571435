import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import ExportarDanger from "./../../../../assets/ExportarDanger.svg";
import ExportarSucess from "./../../../../assets/ExportarSucess.svg";
import { Col, Row } from "react-bootstrap";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import SelectOrdenamiento from "../../../../../Includes/SelectOrdenamiento";
import Buscador from "../../../../../Includes/Buscador";
import Tabla from "./Tabla";
import { getLista } from "../Funciones/ProductosDescuentos";

//#region funciones de random
function fechaAleatoriaAntesDeHoy() {
  var hoy = new Date(); // fecha actual
  var anioActual = hoy.getFullYear(); // obtener el año actual
  var primerDiaAnio = new Date(anioActual, 0, 1); // fecha del primer día del año actual
  var tiempoHoy = hoy.getTime(); // tiempo actual en milisegundos
  var tiempoPrimerDiaAnio = primerDiaAnio.getTime(); // tiempo del primer día del año actual en milisegundos
  var milisegundosAtras = Math.floor(
    Math.random() * (tiempoHoy - tiempoPrimerDiaAnio)
  ); // número aleatorio de milisegundos entre el primer día del año y el día actual
  var fechaAleatoria = new Date(tiempoHoy - milisegundosAtras); // fecha aleatoria

  // formatear fecha en formato DD/MM/YYYY
  var dia = fechaAleatoria.getDate().toString().padStart(2, "0"); // obtener día y asegurarse de que tenga dos dígitos
  var mes = (fechaAleatoria.getMonth() + 1).toString().padStart(2, "0"); // obtener mes y asegurarse de que tenga dos dígitos
  var anio = fechaAleatoria.getFullYear().toString(); // obtener año

  var fechaFormateada = dia + "/" + mes + "/" + anio; // unir los componentes de la fecha en un string con el formato deseado
  return fechaFormateada;
}
function booleanoAleatorio() {
  var numAleatorio = Math.random(); // número aleatorio entre 0 y 1
  var booleano = numAleatorio >= 0.5; // booleano aleatorio con probabilidad del 50%
  return booleano;
}
//#endregion

function createData(
  ID,
  FECHA,
  DESCUENTO,
  ACTIVO,
  OBSERVACIONES,
  DESCIPCCION,
  PRODUCTOS
) {
  return {
    ID,
    FECHA,
    DESCUENTO,
    ACTIVO,
    OBSERVACIONES,
    DESCIPCCION,
    PRODUCTOS,
  };
}

export default function RepProductosDescuentos() {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  useEffect(() => {
    getListaProductosDescuento();
  }, [ValueBusqueda, ValueSelect]);

  const getListaProductosDescuento = () => {
    /*let newData = [];
    for (let index = 0; index < 5; index++) {
      newData.push(
        createData(
          index + 1,
          fechaAleatoriaAntesDeHoy(),
          Math.floor(Math.random() * 1000),
          booleanoAleatorio(),
          "desc",
          "DESCS - " + (index + 1),
          Math.floor(Math.random() * 78)
        )
      );
    }
    setTimeout(() => {
      setLista(newData);
      setIsLoading(false);
    }, 300);*/

    setIsLoading(true);
    getLista(0, 0, ValueSelect, ValueBusqueda)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Productos con descuento`;
  }, []);
  return (
    <>
      <div class="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Productos con descuento</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
            {/* <Button
              className="btn btn-exp-Excel font-AvenirMedium"
              variant="text"
            >
              <img className="me-2" src={ExportarSucess} />
              Exportar Excel
            </Button>
            <Button
              className="btn  btn-exp-PDF  font-AvenirMedium"
              variant="text"
            >
              <img className="me-2" src={ExportarDanger} />
              Exportar PDF
            </Button> */}
            <Link to="add" style={{ textDecoration: "none" }}>
              <Button className="btn btn-Crexendo px-4 " variant="contained">
                Crear nuevo
              </Button>
            </Link>
          </Stack>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        {/*<Row className="p-3">
          <Col xs={12} md={8}>
            <Buscador
              placeholder="Buscar por descripción"
              ValueBusqueda={ValueBusqueda}
              setValueBusqueda={setValueBusqueda}
            />
          </Col>
          <Col xs={12} md={4}>
            <SelectOrdenamiento
              ValueSelect={ValueSelect}
              setValueSelect={setValueSelect}
              currencies={[
                {
                  value: "1",
                  label: "Más recientes",
                },
                {
                  value: "2",
                  label: "Más antiguos",
                },
              ]}
            />
          </Col>
        </Row>*/}
        <Row style={{ marginTop: "" }}>
          <Col sm={12}>
            <Tabla
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
              ValueBusqueda={ValueBusqueda}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}
