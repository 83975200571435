import React from "react";
import { Avatar, TextField } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import SelectFuente from "./SelectFuente";
import Switch from "../../../../includes/Switch";

export default function TabGenerales({
  Values = {},
  img_url = "",
  handleOpenModal = () => {},
  Errores = {},
  handlInputChange = () => {},
  setValues,
}) {
  return (
    <>
      <Row className="p-3">
        <Col sm={12} md={2} className="p-3 ">
          <div className="d-flex justify-content-center">
            <Avatar
              className="shadow"
              sx={{ width: 120, height: 120 }}
              src={img_url}
            />
          </div>
          <strong
            className="w-100 text-center px-0 pt-3 d-flex justify-content-center"
            style={{ color: "#3ABE88", cursor: "pointer" }}
            onClick={handleOpenModal}
          >
            Editar foto
          </strong>
        </Col>

        <Col sm={12} md={10}>
          <Row>
            <Col sm={12} md={6} className="p-3">
              <TextField
                fullWidth
                name="nombre"
                label="Nombre"
                variant="outlined"
                value={Values.nombre}
                error={Errores.nombre}
                onChange={handlInputChange}
              />
            </Col>

            <Col sm={12} md={6} className="p-3">
              <TextField
                fullWidth
                name="apellidos"
                label="Apellidos"
                variant="outlined"
                value={Values.apellidos}
                error={Errores.apellidos}
                onChange={handlInputChange}
              />
            </Col>

            <Col sm={12} md={6} className="p-3">
              <TextField
                fullWidth
                name="correo"
                label="Correo electrónico"
                variant="outlined"
                error={Errores.correo}
                helperText={Errores.correo ? "Correo incorrecto" : ""}
                value={Values.correo}
                onChange={handlInputChange}
              />
            </Col>

            <Col sm={12} md={6} className="p-3">
              <TextField
                fullWidth
                name="telefono"
                label="Teléfono"
                variant="outlined"
                inputProps={{ maxLength: 10 }}
                helperText={Errores.telefono ? "Teléfono inválido" : ""}
                value={Values.telefono}
                error={Errores.telefono}
                onChange={handlInputChange}
              />
            </Col>
            <Col sm={12} md={6} className="p-3">
              <SelectFuente
                value={Values.fuente}
                error={Errores.fuente}
                name="fuente"
                label="Fuente"
                onChange={handlInputChange}
              />
            </Col>
            <Col sm={12} md={6} className="p-3">
              <Switch
                checked={Values.Mostrador}
                onChange={(checked) => {
                  setValues({ ...Values, Mostrador: checked });
                }}
                tituloActivo="Activar cliente mostrador"
                tituloInactivo="Desactivar cliente mostrador"
              />
              <label>Cliente mostrador</label>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
