import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Snackbar,
  styled,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { getInfoVenta } from "../../Funciones/Pedidos";
import { useState } from "react";
import Loading from "../../../../../Includes/Loading";
import { Col, Row } from "react-bootstrap";
import Carrito from "../../../PuntoDeVenta/Componentes/Carrito";
import { Link } from "react-router-dom";
import Switch from "../../../../includes/Switch";
import TabsDatosEntrega from "../../../PuntoDeVenta/Componentes/Tabs/TabsDatosEntrega";
import TabsDedicatoria from "../../../PuntoDeVenta/Componentes/Tabs/TabsDedicatoria";
import { GetDate } from "../../../Catalogos/ReporteVentas/Componentes/ObtenerFecha";
import { ConvertDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";
import { getPrecioZona } from "../../../Catalogos/Zonas/Funciones/Zonas";
import { getDireccionPrincipal } from "../../../../../Lib/Direcciones/Funciones/Direcciones";
import ModalDireccion from "../../../../../Lib/Direcciones/Componentes/ModalDireccion";
import ModalSelectDireccionCliente from "../../../../../Lib/Direcciones/Componentes/ModalSelectDireccionCliente";
import BuscarProductos from "../../../PuntoDeVenta/Componentes/BuscarProductos";
import ButtonTabs from "../../../Catalogos/AjusteMercancia/Componentes/ButtonTabs/ButtonTabs";
import { guardar } from "../../../PuntoDeVenta/Funciones/PuntoDeVente";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";

const MySwal = withReactContent(Swal);

const ModalEditarPedido = ({
  setShowDialogoModalEditar,
  pedidoSeleccionado,
  setPedidoSeleccionado,
}) => {
  const [categoria, setCategoria] = React.useState(0);
  const [IsLoading, setIsLoading] = useState(true);
  const [productosCarrito, setProductosCarrito] = useState([]);
  const [IDbeforeVenta, setIDbeforeVenta] = useState("0");
  const [showDiloglImprimir, setShowDiloglImprimir] = useState(false);
  const [showModalDireccion, SetshowModalDireccion] = useState(false);
  const [showModalMetodosPago, setShowModalMetodosPago] = useState(false);
  const [openNuevaDireccion, setOpenNuevaDireccion] = useState(false);
  const [precioEnvio, setPreioEnvio] = useState(0);
  const [tab, setTab] = React.useState(0);
  const [descuento, setDescuento] = useState({
    id: 0,
    descuento: 0,
    tipo: 0,
    max: 0,
  });
  const [expanded, setExpanded] = React.useState("panel1");
  const [Fecha, setFecha] = useState(GetDate());
  const [horarios, setHorarios] = useState({
    id: 0,
    inicio: "",
    fin: "",
  });
  const [isRentable, setIsRentable] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [Incluye_IVA, setIncluye_IVA] = useState(true);
  const [sucursalSelector, setSucursalSelector] = useState(0);
  const [Values, setValues] = useState({
    id: pedidoSeleccionado ?? "0",
    id_cliente: "0",
    nombre: "",
    id_sucursal: "0",
    id_direccion: 0,
    fecha_entrega: ConvertDate(GetDate()),
    observaciones: "",
    direccion: "",
    tipo_envio: 1,
    tipo_descuento: 0,
    descuento: 0,
    descuento_max: 0,
    subtotal: 0,
    total: 0,
    pagado: false,
    personalizable: false,
    Incluye_iva: true,
    productos: [],
    //productoscompo: [],
    codigo: "",
    id_zona: 0,
    ubicacion: "0 0",
    lat: 0,
    long: 0,
    horario_entrega: {
      inicio: "00:00:00",
      fin: "00:00:00",
    },
    metodos_pago: {
      tarjeta: 0,
      transferencia: 0,
      efectivo: 0,
      monedero: 0,
    },
    costo_envio: 0,
    metodo_pago: "",
    dedicatoria: "",
    envia: "",
    recibe: "",
    id_sucursal_venta: 0,
  });
  const [Errores, setErrores] = useState({
    id_cliente: false,
    id_sucursal: false,
    fecha_entrega: false,
    observaciones: false,
    observaciones: false,
    direccion: false,
    tipo_envio: false,
    horarios_entrega: false,
  });
  //#region handles
  const handleClose = () => {
    setOpenAlert(false);
  };
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const handleInputChange = ({ target }) => {
    let Value = target.value;
    const Name = target.name;

    if (Name == "id_cliente") {
      Values.nombre = target.nombre;
      Values.id_direccion = 0;
      Values.direccion = "";
    }
    if (Name == "id_sucursal") {
      Values.direccion = target.dir;
      Value = String(Value);
      Errores.direccion = false;
    }
    if (Name == "tipo_envio" && Value != Values.tipo_envio) {
      Values.id_sucursal = "0";
      Values.direccion = "";
      Values.id_direccion = 0;
    }
    if (Name == "direccion") {
      Values.id_direccion = target.id;
      Values.lat = target.lat;
      Values.long = target.long;
    }
    setValues({ ...Values, [Name]: Value });
    setErrores({ ...Errores, [Name]: false });
  };
  const handleInputChangeIVA = ({ target }) => {
    let Value = target.value;
    const Name = target.name;
    if (Value == true) {
      setIncluye_IVA(true);
    } else {
      setIncluye_IVA(false);
    }
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  //#endregion

  //#region funciones
  const openModalValidations = () => {
    if (Values.id_cliente == "" || Values.id_cliente == "0") {
      Errores.id_cliente = true;
      setErrores(Errores);
      setMensaje("Selecciona un cliente para ver direcciones");
      setOpenAlert(true);
    } else {
      SetshowModalDireccion(true);
    }
  };

  const Guardar = () => {
    setIsGuardando(true);

    MySwal.fire({
      title: "Atencion!",
      html: "¿Desea modificar esta venta?, esta acción no se puede deshacer",
      icon: "warning",
      confirmButtoColor: "#3ABE88",
      showConfirmButton: true,
      confirmButtonText: "Confirmar",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      timer: 10000,
    }).then((result) => {
      if (result.isConfirmed) {
        guardar(
          Values,
          Errores,
          setErrores,
          productosCarrito,
          isRentable,
          descuento,
          precioEnvio,
          Incluye_IVA
        )
          .then((data) => {
            if (data.codigo == "200") {
              setIsGuardando(false);
              MySwal.fire({
                title: "Correcto",
                html: data.mensaje,
                icon: "success",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: true,
                confirmButtonText: "Imprimir ticket",
                showCancelButton: true,
                cancelButtonText: "Cerrar",
                timer: 10000,
              }).then((result) => {
                if (result.isConfirmed) {
                  //abrir modal de ticket
                  //setIDbeforeVenta(data.respuesta.id);
                  //setShowDiloglImprimir(true);
                }
              });
            } else {
              setMensaje(data.mensaje);
              setOpenAlert(true);
              setIsGuardando(false);
            }
          })
          .catch((data) => {
            setMensaje(data.mensaje);
            setOpenAlert(true);
            setIsGuardando(false);
          });
      } else {
        setIsGuardando(false);
      }
    });
  };
  //#endregion

  //#region EFFECTS
  useEffect(() => {
    setValues({ ...Values, fecha_entrega: ConvertDate(Fecha) });
    Errores.fecha_entrega = false;
    setErrores(Errores);
  }, [Fecha]);

  useEffect(() => {
    Values.horario_entrega.inicio = horarios.inicio;
    Values.horario_entrega.fin = horarios.fin;
    setValues(Values);
    Errores.horarios_entrega = false;
    setErrores(Errores);
  }, [horarios]);

  useEffect(() => {
    let sumaPrecios = 0;
    let rent = false;
    productosCarrito.map((item) => {
      sumaPrecios =
        sumaPrecios + parseFloat(item.PRECIO) * parseFloat(item.CANTIDAD);
      if (item.RENTABLE == true) {
        rent = true;
      }
    });

    setIsRentable(rent);
    setValues({ ...Values, total: sumaPrecios });
  }, [productosCarrito]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("Incluye_IVA", String(Incluye_IVA));
    };

    const handleLoad = () => {
      const storedValue = localStorage.getItem("Incluye_IVA");
      if (storedValue) {
        setIncluye_IVA(storedValue === "true");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  useEffect(() => {
    if (
      Values.id_direccion != 0 &&
      Values.id_direccion != "" &&
      Values.id_direccion != "0"
    ) {
      getPrecioZona(Values.lat, Values.long)
        .then((data) => {
          if (data.length > 0) {
            setPreioEnvio(data[0].PRECIO_DE_ENVIO);
            setValues({
              ...Values,
              ubicacion: `${Values.long} ${Values.lat}`,
              id_zona: data[0].ID,
            });
          } else {
            setPreioEnvio(0);
            setValues({ ...Values, id_zona: 0 });
          }
        })
        .catch((data) => {
          console.log();
        });
    }
  }, [Values.id_direccion, Values.direccion]);

  useEffect(() => {
    if (
      Values.tipo_envio == 1 &&
      Values.id_cliente != "" &&
      Values.id_cliente != "0" &&
      Values.id_direccion == 0
    ) {
      getDireccionPrincipal(Values.id_cliente)
        .then((data) => {
          //console.log(data);
          if (data.length == 0) {
            setValues({ ...Values, direccion: "", id_direccion: 0 });
          } else {
            setValues({
              ...Values,
              direccion:
                "Calle " +
                data.CALLE +
                " " +
                data.NUMERO_EXT +
                " - " +
                data.COLONIA +
                ", " +
                data.NOMBRE_MUNICIPIO +
                ", " +
                data.NOMBRE_ESTADO +
                "/" +
                data.NOMBRE_CONTACTO +
                "/" +
                data.TELEFONO,
              id_direccion: data.ID,
              lat: data.LAT,
              long: data.LONG,
            });

            if (Errores.direccion) setErrores({ ...Errores, direccion: false });
          }
        })
        .catch((data) => {
          console.log(data);
        });
    }
    /*
      else {
      setValues({
        ...Values,
        direccion: "",
        //id_sucursal: 0,
        //tipo_envio: 0
      });
    }
      */
  }, [Values.id_cliente]);

  useEffect(() => {
    if (pedidoSeleccionado != "0") {
      setIsLoading(true);
      getInfoVenta(pedidoSeleccionado)
        .then((resp) => {
          //console.log(resp);
          const arrFecha = resp.Values[0].FECHA_DE_ENTREGA.split("/");
          const f = arrFecha[2] + "-" + arrFecha[1] + "-" + arrFecha[0];
          setPreioEnvio(resp.Values[0].COSTO_ENVIO);
          setFecha(f);
          setExpanded(resp.Values[0].TIPO_ENVIO == 1 ? "panel1" : "panel2");
          setSucursalSelector(resp.Values[0].ID_SUCURSAL_VENTA);
          setHorarios({
            ...horarios,
            inicio: resp.Values[0].ENTREGA_INICIO + ":00",
            fin: resp.Values[0].ENTREGA_FIN + ":00",
          });
          setValues({
            ...Values,
            id: resp.Values[0].ID,
            id_cliente: resp.Values[0].ID_CLIENTE,
            nombre: resp.Values[0].CLIENTE,
            id_sucursal: resp.Values[0].D_ID_SUCURSAL,
            fecha_entrega: f.toString(),
            observaciones: resp.Values[0].OBSERVACIONES,
            direccion:
              resp.Values[0].TIPO_ENVIO == 1
                ? resp.Values[0].DOMICILIO
                : resp.Values[0].DIRECCION_SUC,
            tipo_envio: resp.Values[0].TIPO_ENVIO,
            Incluye_iva: resp.Values[0].INCLUYE_IVA,
            horario_entrega: {
              inicio: resp.Values[0].ENTREGA_INICIO + ":00",
              fin: resp.Values[0].ENTREGA_FIN + ":00",
            },
            costo_envio: resp.Values[0].COSTO_ENVIO,
            dedicatoria: resp.Values[0].DEDICATORIA,
            envia: resp.Values[0].ENVIA,
            recibe: resp.Values[0].RECIBE,
            id_sucursal_venta: resp.Values[0].ID_SUCURSAL_VENTA,
          });
          const filteredProds = resp.detalle.map((row) => {
            return {
              ID: row.ID_PRODUCTO,
              DESCRIPCCION: row.PRODUCTO,
              PRECIO: row.PRECIO,
              CANTIDAD: row.CANTIDAD,
              RENTABLE: row.IS_RENTABLE,
              HORAS_RENTA: 24 * row.DIAS_RENTA,
              COMPOSICION: [],
            };
          });
          setProductosCarrito(filteredProds);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } else {
      setPedidoSeleccionado(0);
      setShowDialogoModalEditar(false);
    }
  }, []);

  //#endregion

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  return (
    <>
      <StyledSnackbar
        direction="right"
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Dialog open={true} fullWidth={true} maxWidth={"lg"}>
        <div className="position-absolute" style={{ right: 0 }}>
          <IconButton
            onClick={() => {
              setPedidoSeleccionado(0);
              setShowDialogoModalEditar(false);
            }}
          >
            <CloseIcon style={{ width: "30px", height: "30px" }} />
          </IconButton>
        </div>
        <div className="w-100 mt-3">
          <h4 className="text-center fw-bolder">Editar pedido</h4>
        </div>
        <DialogContent
          className="p-5 pt-0 "
          style={{ backgroundColor: "#f9f9f9" }}
        >
          {IsLoading ? (
            <div className="col-12  ">
              <Loading />
            </div>
          ) : (
            <>
              <Row>
                <Col
                  xs={12}
                  md={6}
                  className="d-flex flex-column justify-content-between"
                >
                  <div className="d-flex flex-column">
                    <div
                      class="flex-grow-1   "
                      style={{
                        maxHeight: "calc(100vh - 5rem)",
                        overflowY: "scroll",
                        overflowX: "hidden",
                        //overflow: "auto",
                      }}
                    >
                      <BuscarProductos
                        productosCarrito={productosCarrito}
                        setProductosCarrito={setProductosCarrito}
                        IVA={Incluye_IVA}
                        sucursalSelector={sucursalSelector}
                      />
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  className="d-flex flex-column justify-content-between bg-white"
                >
                  <div
                    className="flex-grow-1"
                    style={
                      {
                        //background: "orange",
                      }
                    }
                  >
                    <Row className="">
                      <Tabs
                        TabIndicatorProps={{
                          bgcolor: "#006064",
                          style: {
                            backgroundColor: "#006064",
                          },
                        }}
                        value={tab}
                        onChange={handleChangeTab}
                        variant="scrollable"
                        scrollButtons="auto"
                        textColor="#212529"
                        indicatorColor=""
                        aria-label="scrollable auto tabs example"
                      >
                        <Tab label="Productos" className="font-AvenirBold" />
                        <Tab label="Envío" className="font-AvenirBold" />
                        <Tab label="Dedicatoria" className="font-AvenirBold" />
                      </Tabs>

                      {tab == 0 ? (
                        <Col xs={12}>
                          <Carrito
                            //isVista
                            productosCarrito={productosCarrito}
                            setProductosCarrito={setProductosCarrito}
                            descuento={descuento}
                            setDescuento={setDescuento}
                            cambio={0}
                            setIsRentable={setIsRentable}
                            isRentable={isRentable}
                            precioEnvio={precioEnvio}
                            tipoEnvio={Values.tipo_envio}
                            IVA={Incluye_IVA}
                            Values={Values}
                          />
                          <div className="w-100 text-end pe-3">
                            <Link
                              className=" font-AvenirMedium text-end "
                              variant="text"
                              href="#"
                              underline="none"
                              style={{ color: "#006064", textAlign: "end" }}
                              onClick={() => {
                                //LimpiarDatos();
                                //console.log("datos limpios");
                              }}
                            >
                              Limpiar Carrito
                            </Link>
                          </div>

                          <div className="w-100 text-end pe-3">
                            <label htmlFor="">Incluye IVA</label>
                            <Switch
                              checked={Incluye_IVA}
                              name="INCLUYE_IVA"
                              error={Incluye_IVA}
                              onChange={(checked) => {
                                handleInputChangeIVA({
                                  target: {
                                    name: "INCLUYE_IVA",
                                    value: checked,
                                  },
                                });
                              }}
                              tituloActivo="Si"
                              tituloInactivo="No"
                              on
                            />
                          </div>
                        </Col>
                      ) : tab == 1 ? (
                        <TabsDatosEntrega
                          Values={Values}
                          handleInputChange={handleInputChange}
                          openModalValidations={openModalValidations}
                          expanded={expanded}
                          handleChange={handleChange}
                          Fecha={Fecha}
                          setFecha={setFecha}
                          Errores={Errores}
                          horarios={horarios}
                          setHorarios={setHorarios}
                          isEditar
                        />
                      ) : (
                        tab == 2 && (
                          <TabsDedicatoria
                            Values={Values}
                            handleInputChange={handleInputChange}
                          />
                        )
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={
              {
                //height: "80px",
              }
            }
          >
            <ButtonTabs
              tab={tab}
              setTab={setTab}
              tabsTotales={3}
              btnClass="btn  text-white"
              style={{
                background: "#65748B",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            />
            {/*<Button
              className="btn btn-Crexendo font-AvenirMedium   py-2 px-5 ms-4 d-flex justify-content-end w-100"
              variant="contained"
              onClick={() => {
                //console.log("ok");
                Guardar();
              }}
            >
              Guardar
            </Button>*/}

            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => Guardar()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "   Guardando..." : "        Guardar"}
              </span>
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
      {showModalDireccion && (
        <ModalSelectDireccionCliente
          SetshowModalDireccion={SetshowModalDireccion}
          setOpenNuevaDireccion={setOpenNuevaDireccion}
          //getDireciones={getDireciones}
          id_cliente={Values.id_cliente}
          id={Values.id_direccion}
          handlInputChange={handleInputChange}
        />
      )}
      {openNuevaDireccion && (
        <ModalDireccion
          SetshowModalDireccion={setOpenNuevaDireccion}
          SetshowModalDirecciones={SetshowModalDireccion}
          handlInputChanges={handleInputChange}
          id_cliente={Values.id_cliente}
        />
      )}
    </>
  );
};

export default ModalEditarPedido;
