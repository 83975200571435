import { useEffect, useState } from "react";
import { Alert, Card, Snackbar } from "@mui/material";
import { Row } from "react-bootstrap";
import Timeline from "@mui/lab/Timeline";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TablaHorariosEnvios from "./Tabla";
import { styled } from "@mui/material/styles";
import {
  Desactivar,
  crearDia,
  getHorariosEnvios,
} from "../Funciones/HorarioEnvio";
import ModalHorarioEnvio from "./ModalHorarioEnvio";
import Switch from "../../../../includes/Switch";

const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

export default function RepHorarioEnvio({
  id_sucursal = 0,
  is_save = true,
  Horarios,
  setHorarios,
}) {
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState({ texto: "", color: "" });
  const [openModal, setOpenModal] = useState({
    mostrar: false,
    horario: null,
    numeroSQL: 0,
    index: 0,
  });

  const [dias, setDias] = useState([
    { numeroSQL: 2, nombre: "Lunes", activo: false, id_sucursal },
    { numeroSQL: 3, nombre: "Martes", activo: false, id_sucursal },
    { numeroSQL: 4, nombre: "Miercoles", activo: false, id_sucursal },
    { numeroSQL: 5, nombre: "Jueves", activo: false, id_sucursal },
    { numeroSQL: 6, nombre: "Viernes", activo: false, id_sucursal },
    { numeroSQL: 7, nombre: "Sábado", activo: false, id_sucursal },
    { numeroSQL: 1, nombre: "Domingo", activo: false, id_sucursal },
  ]);

  // console.log("ID_SUCURSAL: ", id_sucursal)

  const clickAgregarHorario = (diaSemana) => {
    const dia = dias.find((x) => x.numeroSQL == diaSemana.numeroSQL);
    if (dia) {
      const newHorario = crearDia(
        diaSemana.numeroSQL,
        dia.id_sucursal,
        dia.activo ? 1 : 0
      );
      setOpenModal({
        mostrar: true,
        horario: newHorario,
        index: Horarios[diaSemana.numeroSQL].lista.length,
        isInsertar: true,
      });
    }
  };

  const clickEditarHorario = (horario, IndexHorario) => {
    setOpenModal({
      mostrar: true,
      horario: { ...horario, ACTIVO: horario.activo ? 1 : 0 },
      index: IndexHorario,
      isInsertar: false,
    });
  };

  // const onChangeSwitch = (id_sucursal, dia, checked) => {
  //   if (id_sucursal != undefined && dia != undefined && id_sucursal != 0) {
  //     Desactivar(id_sucursal, dia, checked)
  //       .then((resp) => {
  //         let diasSemanas = dias.map(diaSemana => {
  //           if (diaSemana.numeroSQL == dia) {
  //             diaSemana.activo = checked
  //           }
  //           return diaSemana
  //         })
  //         setDias([...diasSemanas])
  //         setMensaje({ texto: "Cambios guardados correctamente", color: "success" });
  //         setOpen(true);
  //       })
  //       .catch((resp) => {
  //         setMensaje({ texto: "Error al guardar los cambios", color: "error" });
  //         setOpen(true);
  //       });
  //   } else {
  //     let diasSemanas = dias.map(diaSemana => {
  //       if (diaSemana.numeroSQL == dia) {
  //         diaSemana.activo = checked
  //       }
  //       return diaSemana
  //     })
  //     setDias([...diasSemanas])
  //   }
  // };

  useEffect(() => {
    document.title = `Horarios de envío`;
  }, []);
  useEffect(() => {
    // console.log("ID_SUCURSAL_2: ", id_sucursal)

    getHorariosEnvios(id_sucursal)
      .then((resp) => {
        let data = JSON.parse(resp.data);
        let diasSemanas = [];
        if (data) {
          if (Array.isArray(data.HORARIOS)) {
            data.HORARIOS.map((horario) => {
              if (
                !Horarios[horario.DIA].lista.find((x) => x.ID == horario.ID)
              ) {
                Horarios[horario.DIA].lista.push({
                  ...horario,
                  ACTIVO: horario.activo ? 1 : 1,
                });
              }
            });
          }
          if (Array.isArray(data.DIAS_ACTIVOS)) {
            diasSemanas = dias.map((diaSemana) => {
              const activo = data.DIAS_ACTIVOS.find(
                (x) => x.DIA == diaSemana.numeroSQL
              );
              if (activo) {
                diaSemana.activo = true;
              }
              return diaSemana;
            });
            setDias([...diasSemanas]);
          }
        }
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {dias.map((dia, index) => {
          return (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot
                  style={{ width: 45, height: 45, backgroundColor: "#006064" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  {dia.nombre.substring(0, 2)}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                  {dia.nombre}

                  {/* <Switch
                      checked={dia.activo}
                      onChange={(checked, id) => {
                        onChangeSwitch(dia.id_sucursal, dia.numeroSQL, checked)
                      }}
                      id={dia.numeroSQL}
                      tituloActivo="Desactivar día "
                      tituloInactivo="Activar día"
                    /> */}
                </Typography>
                {Horarios[dia.numeroSQL].rango != "" && (
                  <Typography>{Horarios[dia.numeroSQL].rango}</Typography>
                )}

                <TablaHorariosEnvios
                  dia={dia}
                  clickAgregarHorario={clickAgregarHorario}
                  clickEditarHorario={clickEditarHorario}
                  Horarios={Horarios}
                  setHorarios={setHorarios}
                />
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>

      {openModal.mostrar && (
        <ModalHorarioEnvio
          Horarios={Horarios}
          isSave={is_save}
          idSucursal={id_sucursal}
          horario={openModal.horario}
          isInsertar={openModal.isInsertar}
          setOpen={setOpen}
          setMensaje={setMensaje}
          handleCerrarModal={() => {
            setOpenModal({ mostrar: false, horario: null });
          }}
          handleActualizar={(horario, fecha_actual) => {
            if (openModal.isInsertar) {
              Horarios[horario.DIA].lista.push({ ...horario });
            } else {
              Horarios[horario.DIA].lista = Horarios[horario.DIA].lista.map(
                (horarioSemana, index) => {
                  if (id_sucursal == 0) {
                    if (horarioSemana.ID == horario.ID) {
                      return horario;
                    }
                  } else {
                    if (index == openModal.index) {
                      return horario;
                    }
                  }

                  return horarioSemana;
                }
              );
            }

            Horarios[horario.DIA].lista = Horarios[horario.DIA].lista.sort(
              (a, b) => {
                return (
                  new Date(fecha_actual + " " + a.INICIO) -
                  new Date(fecha_actual + " " + b.INICIO)
                );
              }
            );
            setOpenModal({
              mostrar: false,
              horario: null,
              index: 0,
              isInsertar: true,
            });
          }}
        />
      )}

      <StyledSnackbar
        style={{ maxWidth: 380 }}
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={mensaje.color}
          sx={{ width: "100%" }}
        >
          {mensaje.texto}
        </Alert>
      </StyledSnackbar>
    </>
  );
}
