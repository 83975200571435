import React from "react";
import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getOpciones } from "../../Catalogos/Categorias/Funciones/Categorias";
import Loading from "../../../includes/Loading";
import { avatarLetters } from "../../../../Lib/Funciones Generales/Generales";

export default function Categorias({
  setCategoria,
  categoria,
  sucursal,
  ecommerce = false,
}) {
  const [Categorias, setCategorias] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    //console.log(sucursal)
    getOpciones(0, sucursal, ecommerce)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(resp);
        if (list.length != 0) {
          setCategorias(list);
        } else {
          setCategorias(list);
        }
        setIsLoading(false);
      })
      .catch((resp) => {
        setCategorias([]);
        setIsLoading(false);
      });
  }, [categoria]);
  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="  d-flex   flex-wrap  ">
        {Categorias.map((row) => (
          <div
            onClick={() => setCategoria(row.ID)}
            style={{}}
            className=" card-producto card p-3 m-3 shadow-sm d-flex justify-content-center  flex-column "
          >
            <center>
              {/*row.IMG ? (
                      <Avatar sx={{ width: 45, height: 45 }} src={row.IMG} />
                    ) : (
                      <Avatar
                        sx={{ bgcolor: "#006064", width: 45, height: 45 }}
                      >
                        {avatarLetters(row.DESCRIPCCION)}
                      </Avatar>
                    )*/}
              <Avatar sx={{ bgcolor: "#006064", width: 45, height: 45 }}>
                {avatarLetters(row.DESCRIPCCION)}
              </Avatar>
            </center>
            <p className="font-Avenir fw-bold parrafo2lineastruncate text-center">
              {row.DESCRIPCCION}
            </p>
          </div>
        ))}
      </div>
    </>
  );
}
