import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Stack } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import SelectStatusPedido from "../../../includes/SelectStatusPedido";
import { BasicDatePickers } from "../../../includes/BasicDatePickers";
import { GetDate } from "./Componentes/ObtenerFecha";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import SelectRepartidor from "../Repartidores/Componentes/SelectRepartidor";
import SelecFlorista from "../Administradores/Componentes/SelecFlorista";
import Switch from "../../../includes/Switch";
import SelectOrderPedidos from "../../../includes/SelectOrderPedidos";
import BACKEND_URL, { KEY } from "../../../../Context/backend";
import SelectSucursales from "../Sucursales/Componentes/SelectSucursales";

const MySwal = withReactContent(Swal);

function ReporteVentas() {
  const [fechaInicio, setFechaInicio] = useState(GetDate(-7));
  const [fechaFin, setFechaFin] = useState(GetDate());
  const [StatusVenta, setStatusVenta] = useState(1230);
  const [RepartidorSelecciona, setRepartidorSelecciona] = useState("0");
  const [FloristaSelecciona, setFloristaSelecciona] = useState("0");
  const [Pagado, setPagado] = useState(false);
  const [SelectOrder, setSelectOrder] = useState(1);
  const [SelectEmisor, setSelectEmisor] = useState(-1);
  //#region HANDLES
  const handleChanges = (event) => {
    setStatusVenta(event.target.value);
  };
  const handleChangesRep = (event) => {
    if (event.target.value != 0) {
      setRepartidorSelecciona(event.target.value);
    } else {
      setRepartidorSelecciona("0");
    }
  };
  const handleChangesOrder = (event) => {
    setSelectOrder(event.target.value);
  };
  const handleChangesFlor = (event) => {
    if (event.target.value != 0) {
      setFloristaSelecciona(event.target.value);
    } else {
      setFloristaSelecciona("0");
    }
  };
  const handleChangesPagado = (event) => {
    setPagado(event.target.value);
  };

  const handleChangeFechaIn = (event) => {
    const startDate = new Date(event);
    const endDate = new Date(fechaFin);
    startDate.setDate(startDate.getDate());
    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1;
    const day = startDate.getDate();
    let FECHA =
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day);
    setFechaInicio(FECHA);
  };
  const handleChangeFechaFIn = (event) => {
    const startDate = new Date(fechaInicio);
    const endDate = new Date(event);
    endDate.setDate(endDate.getDate());
    const year = endDate.getFullYear();
    const month = endDate.getMonth() + 1;
    const day = endDate.getDate();
    let FECHA =
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day);
    setFechaFin(FECHA);
  };
  //#endregion
  const generarReportePDF = () => {
    const startDate = new Date(fechaInicio);
    const endDate = new Date(fechaFin);
    const daysDifference = Math.floor(
      (endDate - startDate) / (1000 * 60 * 60 * 24)
    );
    if (daysDifference >= 31) {
      MySwal.fire({
        title: "Advertencia",
        html: "El rango maximo para consultar informacion es de 1 mes, intenta con un rango mas corto",
        icon: "warning",
        confirmButtonColor: "#006064",
        showConfirmButton: true,
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: false,
      });
      return;
    }
    window.open(
      `../pdf/reportepedidos/${fechaInicio}/${fechaFin}/${StatusVenta}/${RepartidorSelecciona}/${FloristaSelecciona}/${Pagado}/${SelectOrder}/${SelectEmisor}`,
      "_blank"
    );
  };
  useEffect(() => {
    document.title = `Pedidos`;
  }, []);

  return (
    <>
      <Card elevation={3} style={{ marginTop: "150px" }}>
        <CardContent className="p-3">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              Reporte de pedidos
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />
          <Row>
            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <BasicDatePickers
                fullWidth
                format="DD/MM/YYYY"
                label="Fecha de inicio"
                fecha={fechaInicio}
                setFecha={handleChangeFechaIn}
              />
            </Col>
            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <BasicDatePickers
                fullWidth
                format="DD/MM/YYYY"
                label="Fecha de fin"
                fecha={fechaFin}
                setFecha={handleChangeFechaFIn}
              />
            </Col>
            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <SelectStatusPedido
                ValueSelect={StatusVenta}
                handleChanges={handleChanges}
              />
            </Col>
            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <SelectRepartidor
                name="id_repartidor"
                Value={RepartidorSelecciona}
                handlInputChange={handleChangesRep}
              />
            </Col>
            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <SelecFlorista
                name="id_florista"
                Value={FloristaSelecciona}
                handlInputChange={handleChangesFlor}
              />
            </Col>
            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <SelectOrderPedidos
                ValueSelect={SelectOrder}
                handleChanges={handleChangesOrder}
              />
            </Col>
            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <SelectSucursales
                Label="Emisor"
                Value={SelectEmisor}
                handlInputChange={({ target }) => {
                  //console.log(target);
                  setSelectEmisor(target.value);
                }}
                currencies={[
                  {
                    ID: -1,
                    NOMBRE: "Todos",
                  },
                  {
                    ID: 0,
                    NOMBRE: "E-Commerce",
                  },
                ]}
              />
            </Col>

            <Col xs={12} md={4} style={{ marginTop: "15px" }}>
              <label>Pagado</label> <br />
              <Switch
                checked={Pagado}
                onChange={(checked) => {
                  handleChangesPagado({
                    target: {
                      name: "pagado",
                      value: checked,
                    },
                  });
                }}
                name="pagado"
                tituloActivo="Si"
                tituloInactivo="No"
              />
            </Col>
          </Row>
          <Stack
            className="py-4"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{ float: "right" }}
          >
            <Button
              onClick={() =>
                generarReportePDF(
                  StatusVenta,
                  RepartidorSelecciona,
                  FloristaSelecciona,
                  Pagado,
                  SelectOrder
                )
              }
              disabled={!fechaFin}
              className="font-AvenirMedium  py-2 px-4 "
              variant="contained"
              color="error"
            >
              Generar Reporte PDF
            </Button>
            <a
              target={"_blank"}
              href={
                BACKEND_URL +
                `pedidos/ReporteVentasExcel?FechaInicio=${fechaInicio}&FechaFin=${fechaFin}&Estatus=${StatusVenta}&ID_REPARTIDOR=${RepartidorSelecciona}&ID_FLORISTA=${FloristaSelecciona}&Pagado=${Pagado}&Order=${SelectOrder}&KEY=${KEY}&Emisor=${SelectEmisor}`
              }
            >
              <Button
                disabled={!fechaFin}
                className="font-AvenirMedium  py-2 px-4 "
                variant="contained"
                color="success"
              >
                Generar Reporte EXCEL
              </Button>
            </a>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}

export default ReporteVentas;
