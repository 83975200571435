import {
    Alert,
    Avatar,
    Button,
    Dialog,
    DialogContent,
    IconButton,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Snackbar
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Loading from "../../../../../Includes/Loading";
import { useState, useEffect} from "react";
import { asignarRepartidorPedido, getRepartidores } from "../../Funciones/Pedidos";
import { Col, Row } from "react-bootstrap";
import Buscador from "../Buscador";

const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
        "& .MuiSnackbar-root": {
            top: theme.spacing(15),
        },
    })
);

const ModalRepartidor = ({ setShowDialogAsignarRepartidor, pedidoSeleccionado, getListaPedidos }) => {


    const [open, setOpen] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [repartidores, setRepartidores] = useState([]);
    const [listaRespaldo, setListaRespaldo] = useState([]);
    const [repartidor, setRepartidor] = useState(0);
    const [IsLoading, setIsLoading] = useState(true);


    useEffect(() => {
        getRepartidores(pedidoSeleccionado)
            .then((resp) => {
                setRepartidor(resp.repartidor);
                setRepartidores(resp.data);
                setListaRespaldo(resp.data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false)
            });
    }, [])


    const handleChangeInput = ({ target }) => {
        let newList = []
        if (target.value != "") {
            newList = listaRespaldo.filter(x => x.NOMBRE_COMPLETO.toLowerCase().includes(target.value.toLowerCase()));
        }
        else {
            newList = listaRespaldo
        }
        setRepartidores(newList)
    }

    const AsignarRepartidorPedido = () => {
        new Swal({
            title: "¿Estas seguro de asignar repartidor a este pedido?",
            text: "esta acción no se puede deshacer",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "No, cancelar",
            confirmButtonText: "Si, estoy seguro",
            confirmButtonColor: "#3ABE88",
            denyButtonColor: "#65748B",
            reverseButtons: true,
        }).then(function (isConfirm) {
            console.log(isConfirm.isConfirmed);
            if (isConfirm.isConfirmed) {
                asignarRepartidorPedido(pedidoSeleccionado, repartidor)
                    .then((data) => {
                        if (data.codigo == "200") {
                            getListaPedidos();
                            MySwal.fire({
                                title: "Correcto",
                                html: data.respuesta.register,
                                icon: "success",
                                confirmButtoColor: "#3ABE88",
                                showConfirmButton: false,
                                timer: 1700,
                            }).then((result) => {
                                setShowDialogAsignarRepartidor(false);
                            });
                        } else {
                            setMensaje(data.mensaje);
                            setOpen(true);
                        }
                    })
                    .catch((data) => {
                        // alert(data.mensaje);
                        setMensaje(data.mensaje);
                        setOpen(true);
                    });
            }
        });
    };

    return (

        <>
            <StyledSnackbar
                direction="right"
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    onClose={() => setOpen(false)}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {mensaje}
                </Alert>
            </StyledSnackbar>

            <Dialog open={true} fullWidth={true} maxWidth={"md"}>
                <div className="position-absolute" style={{ right: 0 }}>
                    <IconButton
                        style={{ width: "60px", height: "60px" }}
                        onClick={() => {
                            setShowDialogAsignarRepartidor(false);
                        }}
                    >
                        <CloseIcon style={{ width: "60px", height: "60px" }} />
                    </IconButton>
                </div>

                <DialogContent
                    className="p-2 pt-0"
                    style={{ backgroundColor: "#f9f9f9" }}
                >
                    <div
                        className="w-100 mt-3 mb-5"
                        style={{ backgroundColor: "#f9f9f9" }}
                    >
                        <h4 className="text-center fw-bolder">Asignar Repartidor</h4>
                    </div>

                    <Row className="mx-0">
                        {IsLoading ? (
                            <div className="col-12  ">
                                <Loading />
                            </div>
                        ) : (
                            <>
                                <Col sm={12} md={5} className="p-2">
                                    <div style={{ maxHeight: "725px", overflowY: "scroll" }}>
                                        <h6 className="text-center fw-bolder mt-3">Repartidores</h6>
                                        <div className="px-3 pt-3 pb-2">
                                            <Buscador
                                                handleChangeBuscador={handleChangeInput}
                                                placeholder={"Buscar repartidor"}
                                            />
                                        </div>
                                        <List>
                                            {repartidores.map((row) => {
                                                return (
                                                    <ListItemButton
                                                        style={{
                                                            backgroundColor:
                                                                row.ID == repartidor ? "lightgray" : "",
                                                        }}
                                                        onClick={() => {
                                                            setRepartidor(row.ID);
                                                        }}
                                                    >
                                                        <ListItemAvatar>
                                                            {row.ID == repartidor ? (
                                                                <Avatar className="bg-success">
                                                                    <CheckCircleIcon />
                                                                </Avatar>
                                                            ) : (
                                                                <Avatar className="border  " src={row.IMG} />
                                                            )}
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={row.NOMBRE_COMPLETO}
                                                        />
                                                    </ListItemButton>
                                                );
                                            })}
                                        </List>
                                    </div>
                                </Col>
                                <Col sm={12} md={7} className="p-2">
                                    <Button
                                        disabled={repartidor == undefined || repartidor == "" || repartidor == 0 || repartidor == null}
                                        variant="contained"
                                        color="success"
                                        className="w-100"
                                        style={{ height: "80px" }}
                                        onClick={AsignarRepartidorPedido}
                                    >
                                        Confirmar
                                    </Button>
                                </Col>
                            </>
                        )
                        }
                    </Row>
                </DialogContent>


            </Dialog >
        </>
    )

}

export default ModalRepartidor