import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage"; 
import { Tooltip } from "@mui/material";
import Pdf from "../../../../assets/Pdf.svg";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { TableHead, Card } from "@mui/material"; 
import { useState, Fragment } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import View from "./Acciones/ViewDetalle";
import Print from "./Acciones/Print";
import CambiarEstatus from "./Acciones/CambiarEstatus";
import AsignarRepartidor from "./Acciones/AsignarRepartidor";
import AsignarFlorista from "./Acciones/AsignarFlorista";
import DatosFacturacion from "./Acciones/DatosFacturacion";
import { Link } from "react-router-dom";
import { usePermisos } from "../../../../Context/PermissionsContext";

const MySwal = withReactContent(Swal);

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function CollapsibleTable({
  Lista,
  setPedidoSeleccionado,
  setShowDialogAsignarFlorista,
  setShowDilogDetalle,
  setShowDiloglEstatutus,
  setShowDiloglImprimir,
  setShowDialogAsignarRepartidor,
  setShowDialogComprobante,
  pedidoSeleccionado,
  setShowDialogoModalEditar,
  setShowDialogFacturacion = () => {},
  estado,
}) {
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(15); 

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function RowPedido({ row, index }) {
    const [collapse, setCollapse] = useState({ open: false, detalle: false });

    const ConsultarDetalle = () => {
      setCollapse({ ...collapse, open: !collapse.open }); 
    };

    
    const { userPermissions } = usePermisos();

    return (
      <>
        <Fragment>
          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}> 
            <TableCell align="left">{row.FOLIO}</TableCell>
            <TableCell align="left">{row.FECHA_DE_ENTREGA}</TableCell>
            <TableCell align="left">
              {row.ENTREGA_INICIO + " - " + row.ENTREGA_FIN}
            </TableCell>
            <TableCell align="left">{row.CLIENTE}</TableCell>
            <TableCell align="left">{formatter.format(row.TOTAL)}</TableCell>
            <TableCell align="right">
             
              {row.TIPO_ENVIO == 1 && (
                <>
                  <AsignarRepartidor
                    setShowDialogAsignarRepartidor={
                      setShowDialogAsignarRepartidor
                    }
                    setPedidoSeleccionado={setPedidoSeleccionado}
                    id={row.ID}
                  />
                </>
              )}

{userPermissions.includes("Editar Pedidos") && (
                <>
                  {row.ID_ESTATUS == 1 &&
                  <Tooltip
                    title="Editar pedido"
                    placement="top"
                    className="me-2"
                  >
                      <IconButton onClick={()=>{
                        setShowDialogoModalEditar(true);
                        setPedidoSeleccionado(row.ID);}}>
                      <ModeEditIcon />
                    </IconButton>
                  </Tooltip>
}
                </>
              )  }

              <AsignarFlorista
                setShowDialogAsignarFlorista={setShowDialogAsignarFlorista}
                setPedidoSeleccionado={setPedidoSeleccionado}
                id={row.ID}
              />

              {estado == 2 &&
                (row.ID_FACTURA == "0" ? (
                  <DatosFacturacion
                    setShowDialogFacturacion={setShowDialogFacturacion}
                    setPedidoSeleccionado={setPedidoSeleccionado}
                    id={row.ID}
                  />
                ) : (
                  <Link
                    to={"/pdf/factura/" + row.ID_FACTURA}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                  >
                    <Tooltip
                      title="Ver factura"
                      placement="top"
                      className="me-2"
                    >
                      <IconButton>
                        <img src={Pdf} width={22} />
                      </IconButton>
                    </Tooltip>
                  </Link>
                ))}

              <Print
                setShowDiloglImprimir={setShowDiloglImprimir}
                setPedidoSeleccionado={setPedidoSeleccionado}
                id={row.ID}
              />
              <CambiarEstatus
                isDiv={false}
                setShowDiloglEstatutus={setShowDiloglEstatutus}
                setPedidoSeleccionado={setPedidoSeleccionado}
                id={row.ID}
              />
              <View
                setShowDilogDetalle={setShowDilogDetalle}
                setPedidoSeleccionado={setPedidoSeleccionado}
                id={row.ID}
              />
            </TableCell>
          </TableRow>

          {/* <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={collapse.open} timeout="auto" unmountOnExit>
                <DetalleCompra
                  pedidoSeleccionado={row.ID}
                  setShowDialogComprobante={setShowDialogComprobante}
                  setShowDiloglImprimir={setShowDiloglImprimir}
                />
              </Collapse>
            </TableCell>
          </TableRow> */}
        </Fragment>
      </>
    );
  }

  return (
    <Card variant="outlined" className="p-0 shadow" id="pedidos">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {/* <TableCell /> */}
              <TableCell> FOLIO </TableCell>
              <TableCell>FECHA DE ENTREGA</TableCell>
              <TableCell>HORA DE ENTREGA</TableCell>
              <TableCell>CLIENTE</TableCell>
              <TableCell>TOTAL</TableCell>
              <TableCell align="right">ACCIONES</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? Lista.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : Lista
            ).map((row, index) => (
              <RowPedido key={"row_pedido_" + row.ID} row={row} index={index} />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Card>

    // <>
    //   {estadoVistaLista == 0 ? (

    //   ) : (
    //     <Row className="w-100">
    //       {Lista && Array.isArray(Lista) && Lista.map((row, index) => (
    //         <CardPedido
    //           key={"card_pedido_" + row.ID}
    //           row={row}
    //           index={index} />
    //       ))}
    //     </Row>
    //   )}

    // </>
  );
}
