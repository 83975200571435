import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import { Col, Row, Stack } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import DatosCFDI from "./DatosCFDI";
import { useState } from "react";
import ModalSelectProd from "../../../Catalogos/Productos/Componentes/ModalSelectProd";
import ModalSelectCliente from "../../../Catalogos/Clientes/Componentes/ModalSeelctCliente";
import { useEffect } from "react";
import { getDatosEmisor, timbrarCFDI } from "../Funciones/Facturas";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loading from "../../../../includes/Loading";
import Conceptos from "./Conceptos";
import { LoadingButton } from "@mui/lab";

const NeuvaFactura = () => {
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [pagado, setPagado] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [showModalProductos, setShowModalProductos] = useState(false);
  const [showModalCliente, setShowModalCliente] = useState(false);
  const [productosCarrito, setProductosCarrito] = useState([]);
  const [emisor, setEmisor] = useState([]);
  const [datosReceptor, setDatosReceptor] = useState({
    id: 0,
    razon_social: "",
    regimen_fiscal: "",
    RFC: "",
  });
  const [Values, setValues] = useState({
    id_cliente: 0,
    fecha_emision: "0",
    tipo_comprobante: "I",
    condicion_pago: "",
    doc_relacionado: "00",
    uuid_relacion: "",
    serie: "",
    moneda: "MXN",
    tipo_cambio: "",
    forma_pago: "01",
    metodo_pago: "PUE",
    uso_cfdi: "G03",
    observaciones: "",
    pagado: true,
    conceptos: [],
  });

  const [Errores, setErrores] = useState({
    id_cliente: false,
    fecha_emision: false,
    tipo_comprobante: false,
    condicion_pago: false,
    doc_relacionado: false,
    uuid_relacion: false,
    serie: false,
    moneda: false,
    tipo_cambio: false,
    forma_pago: false,
    metodo_pago: false,
    uso_cfdi: false,
    observaciones: false,
    pagado: false,
  });

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";

    let NewValue = Value;

    if (Name == "tipo_cambio") {
      const regex = /^[0-9]*\.?[0-9]{0,2}$/;
      if (!regex.test(Value)) {
        NewValue = Values.tipo_cambio;
      }
    }
    setValues((Values) => ({
      ...Values,
      [Name]: NewValue,
    }));

    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  const handleProductoSeleccionado = (producto, cantidad = 1) => {
    const items = [...productosCarrito];

    console.log(producto);

    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PROIDDUCTO === producto.ID)
      : -1;
    if (itemIndex !== -1) {
      const productoExistente = items[itemIndex];
      productoExistente.CANTIDAD += cantidad;
    } else {
      items.push(producto);
    }

    setProductosCarrito(items);
  };

  const guardar = () => {
    setIsGuardando(true);
    timbrarCFDI(
      Values,
      Errores,
      setErrores,
      productosCarrito,
      datosReceptor,
      pagado
    )
      .then((data) => {
        console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          Swal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            confirmButtonText: "OK",
            timer: 10000,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          Swal.fire({
            title: "Error",
            html: data.mensaje,
            icon: "warning",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            confirmButtonText: "OK",
          }).then((result) => {
            setIsGuardando(false);
          });
        }
      })
      .catch((data) => {
        console.log(data);
        Swal.fire({
          title: "Error",
          html: data.mensaje,
          icon: "warning",
          confirmButtoColor: "#3ABE88",
          showConfirmButton: true,
          confirmButtonText: "OK",
        }).then((result) => {
          setIsGuardando(false);
        });
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getDatosEmisor()
      .then((data) => {
        if (data.emisor.length == 0) {
          Swal.fire({
            title: "Advertencia",
            html: "No se encontraron datos del emisor",
            icon: "warning",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            confirmButtonText: "Ok",
            timer: 10000,
          }).then((result) => {
            return;
          });
        }
        setEmisor(data.emisor);
        setTimeout(() => {
          setIsLoading(false);
        }, 0);
      })
      .catch((data) => {
        console.log(data);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {showModalProductos && (
        <ModalSelectProd
          handleProductoSeleccionado={handleProductoSeleccionado}
          proveedorId={0}
          onClose={() => {
            setShowModalProductos(false);
          }}
          titulo_="Precio"
        ></ModalSelectProd>
      )}
      {showModalCliente && (
        <ModalSelectCliente
          setShowDialog={setShowModalCliente}
          setDatosReceptor={setDatosReceptor}
          titulo="Seleccionar un receptor"
        />
      )}

      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Facturas
      </Button>

      <div className="container-fluid   ">
        <div className="row">
          {IsLoading ? (
            <div className="col-12">
              <Loading />
            </div>
          ) : (
            <>
              <div className="col-12 col-md-6">
                <Card elevation={3} className="mb-4">
                  <CardContent className="p-0">
                    <div className="p-3">
                      <h6 className="mb-0 text-left font-AvenirBold">EMISOR</h6>
                    </div>
                    <hr
                      style={{
                        backgroundColor: "#DFDEE0",
                        height: "1px",
                        opacity: "1",
                      }}
                      className="m-0 p-0"
                    />
                    <Row className="px-4">
                      <Col xs={12} className="p-1">
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Typography style={{ fontSize: 17, fontWeight: 600 }}>
                            Razón Social:
                          </Typography>
                          <Typography>{emisor.RAZON_SOCIAL}</Typography>
                        </Grid>
                      </Col>
                      <Col xs={12} className="p-1">
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Typography style={{ fontSize: 17, fontWeight: 600 }}>
                            RFC:{" "}
                          </Typography>
                          <Typography>{emisor.RFC}</Typography>
                        </Grid>
                      </Col>
                      <Col xs={12} className="p-1">
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Typography style={{ fontSize: 17, fontWeight: 600 }}>
                            Régimen Fiscal:
                          </Typography>
                          <Typography>
                            {emisor.CLAVE} - {emisor.DESCRIPCION}
                          </Typography>
                        </Grid>
                      </Col>
                    </Row>
                  </CardContent>
                </Card>
              </div>

              <div className="col-12 col-md-6">
                <Card elevation={3} className="mb-4">
                  <CardContent className="p-0">
                    <div className="p-3 d-flex justify-content-between flex-wrap">
                      <h6 className="mb-0 text-left font-AvenirBold">
                        RECEPTOR
                      </h6>
                      <div>
                        <Button
                          className="btn btn-Crexendo px-3 "
                          size="small"
                          variant="contained"
                          onClick={() => {
                            setShowModalCliente(true);
                          }}
                        >
                          Buscar receptor <SearchIcon className="ms-2" />
                        </Button>
                      </div>
                    </div>
                    <hr
                      style={{
                        backgroundColor: "#DFDEE0",
                        height: "1px",
                        opacity: "1",
                      }}
                      className="m-0 p-0"
                    />
                    <Row className="px-4">
                      <Col xs={12} className="p-1">
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Typography style={{ fontSize: 17, fontWeight: 600 }}>
                            Razón Social:
                          </Typography>
                          <Typography>{datosReceptor.razon_social}</Typography>
                        </Grid>
                      </Col>
                      <Col xs={12} className="p-1">
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Typography style={{ fontSize: 17, fontWeight: 600 }}>
                            RFC:{" "}
                          </Typography>
                          <Typography>{datosReceptor.RFC}</Typography>
                        </Grid>
                      </Col>
                      <Col xs={12} className="p-1">
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Typography style={{ fontSize: 17, fontWeight: 600 }}>
                            Régimen Fiscal:{" "}
                          </Typography>
                          <Typography>
                            {datosReceptor.regimen_fiscal}
                          </Typography>
                        </Grid>
                      </Col>
                    </Row>
                  </CardContent>
                </Card>
              </div>

              <div className="col-12  ">
                <Card elevation={3} className="mb-4">
                  <CardContent className="p-0">
                    <div className="p-3 d-flex justify-content-between flex-wrap">
                      <h6 className="mb-0 text-left font-AvenirBold">
                        DATOS DE CFDI
                      </h6>
                    </div>
                    <hr
                      style={{
                        backgroundColor: "#DFDEE0",
                        height: "1px",
                        opacity: "1",
                      }}
                      className="m-0 p-0"
                    />

                    <DatosCFDI
                      Values={Values}
                      Errores={Errores}
                      handlInputChange={handlInputChange}
                    />
                  </CardContent>
                </Card>
              </div>

              <div className="col-12  ">
                <Card elevation={3} className="mb-4">
                  <CardContent className="p-0">
                    <div className="p-3 d-flex justify-content-between flex-wrap">
                      <h6 className="mb-0 text-left font-AvenirBold">
                        CONCEPTOS
                      </h6>
                      <div>
                        <Button
                          className="btn btn-Crexendo px-3 "
                          size="small"
                          variant="contained"
                          onClick={() => {
                            setShowModalProductos(true);
                          }}
                        >
                          Buscar productos <SearchIcon className="ms-2" />
                        </Button>
                      </div>
                    </div>
                    <hr
                      style={{
                        backgroundColor: "#DFDEE0",
                        height: "1px",
                        opacity: "1",
                      }}
                      className="m-0 p-0"
                    />
                    <Conceptos
                      Values={Values}
                      pagado={pagado}
                      setPagado={setPagado}
                      productosCarrito={productosCarrito}
                      setProductosCarrito={setProductosCarrito}
                    />
                  </CardContent>

                  <CardActions>
                    <div className="text-end w-100">
                      <Stack
                        className="p-3"
                        spacing={2}
                        direction={{ xs: "column", sm: "row" }}
                        style={{}}
                      >
                        <Button
                          onClick={() => navigate(-1)}
                          className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                          variant="outlined"
                        >
                          Cancelar
                        </Button>

                        <LoadingButton
                          loading={IsGuardando}
                          loadingPosition="start"
                          disabled={IsLoading}
                          onClick={() => guardar()}
                          className="btn btn-Crexendo font-AvenirMedium  py-2 px-4  ms-2"
                          variant="contained"
                          xs={{ with: "100$" }}
                        >
                          <span className={IsGuardando ? "px-4" : "px-2"}>
                            {IsGuardando
                              ? "Timbrando factura..."
                              : "Generar factura"}
                          </span>
                        </LoadingButton>
                      </Stack>
                    </div>
                  </CardActions>
                </Card>
              </div>
              <div className="col-12  pt-5 m-t5"></div>
              <div className="col-12  pt-5 m-t5"></div>
              <div className="col-12  pt-5 m-t5"></div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NeuvaFactura;
