import React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, TextField, Typography } from "@mui/material";
import Ticket from "../../../Pedidos/Compomentes/Ticket";
import { formatMoneda } from "../../../../../Lib/Funciones Generales/Generales";
import Descuentos from "../Descuentos";

export default function TabsPago({
  setShowModalMetodosPago = () => {},
  showModalMetodosPago,
  Guardar = () => {},
  IsGuardando = false,
  id,
  totalCarrito = 0,
  Values = {},
  productosCarrito = [],
  horarios,
  productos,
  setProductosCarrito,
  setDescuento,
  descuento,
  mensaje,
  setMensaje,
  setOpenAlert,
  handleInputChange,
  precioEnvio = 0,
  IVA,
}) {
  let cambio = 0;
  let TotalPagado = 0;

  let total = 0;
  {
    productosCarrito.length > 0
      ? productosCarrito.map((row) => {
          //console.log(row);
          total += parseFloat(row.PRECIO) * row.CANTIDAD;
          //totalTiempo += row.DURACION * row.CANTIDAD;
          row.DESCUENTO = 0;
          row.TIPO_DESCUENTO = 0;
        })
      : (total = 0);
  }
  let totalDescuento =
    descuento.tipo == 0
      ? 0
      : descuento.tipo == 1
      ? (total * descuento.descuento) / 100 > descuento.max
        ? descuento.max
        : (total * descuento.descuento) / 100
      : descuento.descuento;

  useEffect(() => {}, []);
  const Valor_IVA = 16; // IVA del producto
  const IVA_FRACCION = Valor_IVA / 100 + 1;
  const RestaIVA = total / IVA_FRACCION;
  const valorCalculado = IVA ? total : RestaIVA.toFixed(2);
  const TOTAL_IVA = total - RestaIVA;
  // const valorCalculadoIVA = IVA ? TOTAL_IVA : TOTAL_IVA;
  const SubtotalIVA = IVA == false ? total : RestaIVA;
  const SUMRES = IVA ? "+" : "-";
  return (
    <>
      <div className=" col-12  w-100 d-flex flex-column justify-content-between">
        <div className="row">
          <Col xs={12} className="p-3">
            <div className="col-12">
              <h4 className="text-center">Resumen del pedido</h4>
            </div>

            <table className="w-100">
              <tr>
                <td className="fw-bold" valign="top">
                  Cliente
                </td>
                <td align="right" valign="top">
                  {Values.nombre}
                </td>
              </tr>
              <tr>
                <td className="fw-bold" align="top">
                  Entrega
                </td>
                <td align="right" valign="top">
                  {Values.fecha_entrega} {horarios.inicio} - {horarios.fin}
                </td>
              </tr>

              <tr>
                <td className="fw-bold" valign="top">
                  {" "}
                  Tipo de envio
                </td>
                <td align="right" valign="top">
                  {Values.tipo_envio == "1"
                    ? "Envío a domicilio"
                    : "Recoger el sucursal"}
                </td>
              </tr>
              <tr>
                <td className="fw-bold" valign="top">
                  Dirección
                </td>
                <td align="right" valign="top" style={{ fontSize: "11px" }}>
                  {Values.direccion}
                </td>
              </tr>

              <tr>
                <td className="fw-bold" valign="top">
                  {" "}
                  Envío{" "}
                </td>
                <td align="right" valign="top">
                  {formatMoneda(parseFloat(precioEnvio))}
                </td>
              </tr>

              <tr>
                <td className="fw-bold" valign="top">
                  {" "}
                  Total{" "}
                </td>
                <td align="right" valign="top">
                  <h4 className="fw-bold">
                    {totalDescuento > 0 ? (
                      <strike
                        className="text-muted"
                        style={{ fontSize: "15px" }}
                      >
                        <span
                          className="text-muted me-2"
                          style={{ fontSize: "15px" }}
                        >
                          {formatMoneda(valorCalculado + precioEnvio)}
                        </span>
                      </strike>
                    ) : (
                      <></>
                    )}
                    {formatMoneda(
                      parseFloat(
                        valorCalculado - totalDescuento < 0
                          ? 0
                          : valorCalculado -
                              totalDescuento +
                              Number(precioEnvio)
                      )
                    )}
                  </h4>
                </td>
              </tr>
            </table>

            <div className="col-12">
              <span className="fw-bold">
                Observaciones: <br />
              </span>
              {Values.observaciones}
            </div>
          </Col>
        </div>

        <div className="w-100">
          <Descuentos
            productosCarrito={productosCarrito}
            productos={productosCarrito}
            setProductosCarrito={setProductosCarrito}
            setDescuento={setDescuento}
            descuento={descuento}
            mensaje={mensaje}
            setMensaje={setMensaje}
            setOpenAlert={setOpenAlert}
            handleInputChange={handleInputChange}
            codigo={Values.codigo}
          />
        </div>
      </div>
    </>
  );
}
