export const printTicket = () => {
  try {
    const div = document.getElementById("divTiket");
    const divHtml = div.innerHTML;
    imprimirTexto(divHtml);
    return true;
  } catch (error) {
    return false;
  }
};

function imprimirTexto(texto) {
  var paginaTemporal =
    " <style>.border-dotted { font-size: 15px; white-space: nowrap;overflow: hidden;}</style>" +
    " <html><head><title>Tiket de compra</title></head><body>" +
    texto +
    "</body></html>";

  var ventana = window.open("", "");
  ventana.document.write(paginaTemporal);

  ventana.focus();

  setTimeout(function () {
    ventana.print();
    ventana.close();
  }, 2000);
}
