import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { guardar, getInfoPerfil } from "../Funciones/Perfiles";
import Loading from "../../../../includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import ModalCargarImagen from "../../../../../Lib/Imagen/Componentes/ModalCargarImagen";
import AccEditarImagen from "../../../../assets/AccEditar.svg";
import { IMG } from "../../../../../Lib/Funciones Generales/Constantes";
//import { Empresa } from "./Empresa";
//import { Colaborador } from "./Colaborador";
import { getLocalStorage } from "../../../../../Context/Storage";
import { useContext } from "react";
import { IconContext } from "../../../../includes/Menu";

const MySwal = withReactContent(Swal);

export default function Perfil() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [perfilType, setPerfilType] = useState(true);
  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    correo: "",
    telefono: "",
    img: AccEditarImagen,
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    apellido_paterno: false,
    apellido_materno: false,
    correo: false,
    telefono: false,
  });

  // Definicion de variables para la carga de imagenes
  const [openModal, setOpenModal] = React.useState(false);
  const avatar = useContext(IconContext);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    let random = "";
    if (Values.id != "0" && Values.id != 0) {
      random = "?" + Math.floor(Math.random() * 1000000);
    }
    setValues({ ...Values, img: img + random });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlInputChange = ({ target, buscador }) => {
    const Name = target ? target.name : buscador.name;
    const Value = target ? target.value : buscador.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    document.title = "Perfil";
    getInfoPerfil(AccEditarImagen)
      .then((resp) => {
        console.log("DATOS DEL PERFIL: ", resp);
        setValues(resp.Values);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, []);

  const GuardarCliente = () => {
    //setPerfilType(!perfilType);
    setIsGuardando(true);
    guardar(Values, Errores, setErrores, perfilType)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            console.log("I was closed by the timer");
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        // alert(data.mensaje);
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const img_url = Values.img == AccEditarImagen ? AccEditarImagen : Values.img;

  avatar.setUrl(Values.img == AccEditarImagen ? AccEditarImagen : Values.img);

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">Mi perfil</h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col
                sm={12}
                md={12}
                lg={4}
                className="d-flex flex-wrap align-items-center justify-content-center"
              >
                <Avatar
                  className="shadow"
                  sx={{ width: 120, height: 120 }}
                  src={img_url}
                />
                <strong
                  className="w-100 text-center px-0 pt-3"
                  style={{ color: "#3ABE88", cursor: "pointer" }}
                  onClick={handleOpenModal}
                >
                  Editar
                </strong>
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="nombre"
                  label="Nombre"
                  variant="outlined"
                  value={Values.nombre}
                  error={Errores.nombre}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="apellido_paterno"
                  label="Apellido Paterno"
                  variant="outlined"
                  value={Values.apellido_paterno}
                  error={Errores.apellido_paterno}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="apellido_materno"
                  label="Apellido Materno"
                  variant="outlined"
                  value={Values.apellido_materno}
                  error={Errores.apellido_materno}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="correo"
                  label="Correo"
                  variant="outlined"
                  value={Values.correo}
                  error={Errores.correo}
                  onChange={handlInputChange}
                  disabled
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="telefono"
                  label="Telefono"
                  variant="outlined"
                  value={Values.telefono}
                  error={Errores.telefono}
                  onChange={handlInputChange}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col sm={12} md={6} className="p-3 d-flex">
              <Stack
                className="p-3"
                spacing={2}
                direction={{ xs: "column", sm: "row" }}
                style={{}}
              >
                <LoadingButton
                  loading={IsGuardando}
                  loadingPosition="start"
                  disabled={IsLoading}
                  onClick={() => GuardarCliente()}
                  className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                  variant="contained"
                  xs={{ with: "100$" }}
                >
                  <span className={IsGuardando ? "px-4" : "px-2"}>
                    Actualizar datos
                  </span>
                </LoadingButton>
                <Button
                  onClick={() => navigate(-1)}
                  className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                  variant="outlined"
                >
                  Regresar
                </Button>
              </Stack>
            </Col>

            <Col
              sm={12}
              md={6}
              className="p-3 text-end d-flex flex-column justify-content-center"
            >
              <Link to={"update/"} style={{ textDecorationLine: "none" }}>
                <strong
                  className="w-100 text-center px-0 me-4 "
                  style={{ color: "#006064", cursor: "pointer" }}
                >
                  Cambiar contraseña
                </strong>
              </Link>
            </Col>
          </Row>
        </CardContent>
      </Card>
      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={IMG.TIPO_PERFIL}
          Titulo="Cargar imagen de perfil"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
        />
      )}
    </>
  );
}
