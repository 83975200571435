import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Error404 from "../../../../Includes/Error404";
import ReportesOrdenCompra from "../OrdenCompra/Reportes/ReportesOrdenCompra";
import ReportesRequisicion from "../RequisicionMercancia/Reportes/ReportesRequisicion";
import PDFVentas from "../ReporteVentas/Componentes/PDFVentas";
import PDFVentasProd from "../ReporteVentasProducto/Componentes/PDFVentasProd";
import PDFMercancia from "../ExistenciaMercancia/Componentes/PDFMercancia";
import PDFIngMerca from "../IngresoMercancia/Reporte/PDFIngMerca";
import PDFFactura from "../../Facturacion/Facturas/Componentes/PDFFactura";
import PDFTraspasoMerca from "../TraspasoMercancia/Reportes/PDFTraspasoMerca";
import PDFAjusteMerca from "../AjusteMercancia/Reporte/PDFAjusteMerca";
import PDFReStock from "../ReStock/Componentes/PDFReStock";

const ReportesPDF = () => {
  return (
    <Routes>
      <Route path="ordencompra/:id" element={<ReportesOrdenCompra />}></Route>
      <Route
        path="requisicionmercancia/:id"
        element={<ReportesRequisicion />}
      ></Route>
      <Route
        path="reportepedidos/:fecha1/:fecha2/:status/:id_repartidor/:id_florista/:pagado/:order/:emisor"
        element={<PDFVentas />}
      ></Route>
      <Route
        path="reporteventas/:fecha1/:fecha2/:cancelado/:productos"
        element={<PDFVentasProd />}
      ></Route>
      <Route
        path="exitenciamercancia/:sucursal/:almacen/:tipo/:existencia/:busqueda"
        element={<PDFMercancia />}
      ></Route>
      <Route
        path="pdfrestock/:sucursal/:almacen/:tipo/:existencia"
        element={<PDFReStock />}
      ></Route>
      <Route path="ingresomercancia/:id" element={<PDFIngMerca />}></Route>
      <Route path="factura/:id" element={<PDFFactura />}></Route>
      <Route
        path="traspasomercancia/:id"
        element={<PDFTraspasoMerca />}
      ></Route>
      <Route path="ajustemercancia/:id" element={<PDFAjusteMerca />}></Route>
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
};
export default ReportesPDF;
