import { TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getInfoByCodigo } from "../../Catalogos/Cupones/Funciones/Cupones";
export default function CodigoDescuento({
  descuentos,
  setDescuento = () => {},
  mensaje,
  setMensaje = () => {},
  setOpenAlert = () => {},
  handleInputChange = () => {},
  codigo = "",
}) {
  const [codigoDecuento, setCodigoDescuento] = useState(codigo);

  const handleInputChangeCode = ({ target }) => {
    const Name = target.name;
    const Value = target.value;

    setCodigoDescuento(Value);
    handleInputChange({ target: { name: "codigo", value: Value } });
  };

  useEffect(() => {
    if (descuentos == 0) {
      setCodigoDescuento("");
    }
  }, [descuentos]);

  const getInfoCupon = (codigo) => {
    getInfoByCodigo(codigo)
      .then((data) => {
        if (data.codigo == "200") {
          //console.log(data);
          const row = JSON.parse(data.respuesta);
          //console.log(row);
          setDescuento({
            id: row[0].ID,
            descuento: row[0].VALOR,
            tipo: row[0].TIPO_DESCUENTO,
            max: 1000,
          });
          //console.log("Descuento: ", descuento);
          //console.log("Row: ", row[0]);
          //setCodigoDescuento(codigo);
        } else {
          setMensaje(data.mensaje);
          setOpenAlert(true);
          setDescuento({
            id: 0,
            descuento: 0,
            tipo: 0,
            max: 0,
          });
        }
      })
      .catch((data) => {
        //console.log(data);
        setMensaje(data.mensaje);
        setOpenAlert(true);
        setDescuento({
          id: 0,
          descuento: 0,
          tipo: 0,
          max: 0,
        });
      });
  };

  return (
    <>
      <div className="d-flex flex-column justify-content-end mt-3 " style={{}}>
        <TextField
          className="flex-grow-1"
          id="outlined-basic"
          label="Código de descuento"
          placeholder="Ingresa el codigo de descuento"
          fullWidth
          variant="outlined"
          size="small"
          onChange={handleInputChangeCode}
          value={codigoDecuento}
        />
      </div>
      <div className="align-self-end mt-3 d-flex justify-content-between">
        <Link
          className=" font-AvenirMedium font-carrito"
          variant="text"
          href="#"
          underline="none"
          onClick={() => {
            setDescuento({ id: 0, descuento: 0, tipo: 0 });
          }}
          style={{ color: "#d14343", textAlign: "end" }}
        >
          Eliminar descuentos
        </Link>

        <Link
          className=" font-AvenirMedium "
          variant="text"
          href="#"
          underline="none"
          style={{ color: "#006064", textAlign: "end" }}
          onClick={() => {
            getInfoCupon(codigoDecuento);
          }}
        >
          Aplicar código
        </Link>
      </div>
    </>
  );
}
