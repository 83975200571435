import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Categorias from "./Categorias";
import Productos from "./Productos";
import Carrito from "./Carrito";
import Descuentos from "./Descuentos";
import {
  ConvertDate,
  GetDate,
} from "../../../../Lib/Funciones Generales/ObtenerFecha";
import {
  Alert,
  styled,
  Snackbar,
  Tab,
  Tabs,
  Button,
  Stack,
  Grid,
  Tooltip,
} from "@mui/material";
import { getClienteMostrador, guardar } from "../Funciones/PuntoDeVente";
import { Link, useParams, useNavigate  } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ModalSelectDireccionCliente from "../../../../Lib/Direcciones/Componentes/ModalSelectDireccionCliente";
import ModalDireccion from "../../../../Lib/Direcciones/Componentes/ModalDireccion";
import { getDireccionPrincipal } from "../../../../Lib/Direcciones/Funciones/Direcciones";
import { getPrecioZona } from "../../Catalogos/Zonas/Funciones/Zonas";
import TabsDatosEntrega from "./Tabs/TabsDatosEntrega";
import TabsDedicatoria from "./Tabs/TabsDedicatoria";
import TabsPago from "./Tabs/TabsPago";
import { useContext } from "react";
import { IconContext } from "../../../includes/Menu";
import ModalPagoVenta from "./ModalPagoVenta";
import "dayjs/locale/es-mx";
import dayjs from "dayjs";
import { setLocalStorage, getLocalStorage } from "../../../../Context/Storage";
import BackTab from "./Tabs/BackTab";
import NetxTab from "./Tabs/NetxTab";
import ButtonsTabs from "./Tabs/ButtonsTabs";
import BuscarProductos from "./BuscarProductos";
import SucursalSelector from "./Tabs/SucursalSelector";
import SyncIcon from "@mui/icons-material/Sync";
import Switch from "../../../../Includes/Switch";
import ModalTicket from "../../Pedidos/Compomentes/Dialogos/ModalTicket";

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const options = [
  'Cambiar de sucursal',
  'Corte de caja',
];

const ITEM_HEIGHT = 48;

const MySwal = withReactContent(Swal);

export default function PruebaContenedores() {
  const context = useContext(IconContext);

  //#region Variables de estado PV_PRODS_COMPO
  const { id } = useParams();
  const [categoria, setCategoria] = React.useState(0);
  const [query, setQuery] = React.useState("");
  const [productosCarrito, setProductosCarrito] = useState(
    getLocalStorage("PV_PRODS") == ""
      ? []
      : JSON.parse(getLocalStorage("PV_PRODS"))
  );
  const [IDbeforeVenta, setIDbeforeVenta] = useState("0");
  const [showDiloglImprimir, setShowDiloglImprimir] = useState(false);
  const [showModalDireccion, SetshowModalDireccion] = useState(false);
  const [showModalMetodosPago, setShowModalMetodosPago] = useState(false);
  const [openNuevaDireccion, setOpenNuevaDireccion] = useState(false);
  const [precioEnvio, setPreioEnvio] = useState(0);
  const [tab, setTab] = React.useState(0);
  const [descuento, setDescuento] = useState({
    id: 0,
    descuento: 0,
    tipo: 0,
    max: 0,
  });
  const [expanded, setExpanded] = React.useState(
    getLocalStorage("PV") == ""
      ? "panel1"
      : JSON.parse(getLocalStorage("PV")).tipo_envio == 1
      ? "panel1"
      : "panel2"
  );
  const [Fecha, setFecha] = useState(
    getLocalStorage("PV") == ""
      ? GetDate()
      : JSON.parse(getLocalStorage("PV")).fecha_entrega
  );
  const [horarios, setHorarios] = useState({
    id: 0,
    inicio: "",
    fin: "",
  });
  const [isRentable, setIsRentable] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [Incluye_IVA, setIncluye_IVA] = useState(true);
  const [Values, setValues] = useState(
    getLocalStorage("PV") == ""
      ? {
          id: id ?? "0",
          id_cliente: "0",
          nombre: "",
          id_sucursal: "0",
          id_direccion: 0,
          fecha_entrega: ConvertDate(GetDate()),
          observaciones: "",
          direccion: "",
          tipo_envio: 1,
          descuento: 0,
          tipo_descuento: 0,
          descuento_max: 0,
          subtotal: 0,
          total: 0,
          pagado: false,
          personalizable: false,
          Incluye_iva: true,
          productos: [],
          //productoscompo: [],
          codigo: "",
          id_zona: 0,
          ubicacion: "0 0",
          lat: 0,
          long: 0,
          horario_entrega: {
            inicio: "00:00:00",
            fin: "00:00:00",
          },
          metodos_pago: {
            tarjeta: 0,
            transferencia: 0,
            efectivo: 0,
            monedero: 0,
          },
          costo_envio: 0,
          metodo_pago: "",
          dedicatoria: "",
          envia: "",
          recibe: "",
          id_sucursal_venta: 0,
        }
      : JSON.parse(getLocalStorage("PV"))
  );


  //Navegar entre rutas
  let navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    //console.log("ssssdwd"+event.currentTarget)
    setAnchorEl(event.currentTarget);
  };

  const ChangeOption = (event) => {
    //console.log(event.target.innerText)

    let opcion = event.target.innerText;

    if(opcion === "Corte de caja")
    {
      navigate("/cortes");
    } 
    else if(opcion === "Cambiar de sucursal")
    {
      cambioSucursal();
    }

    setAnchorEl(null);

  };




  const [Errores, setErrores] = useState({
    id_cliente: false,
    id_sucursal: false,
    fecha_entrega: false,
    observaciones: false,
    direccion: false,
    tipo_envio: false,
    horarios_entrega: false,
  });
  //#endregion
  //console.log(Values);
  //console.log(getLocalStorage("PV"));
  //#region handles
  const handleClose = () => {
    setOpenAlert(false);
  };
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const handleInputChange = ({ target }) => {
    let Value = target.value;
    const Name = target.name;
    //console.log("Nombre", Name, "Valor", Value);

    if (Name == "id_cliente") {
      Values.nombre = target.nombre;
      Values.id_direccion = 0;
      Values.direccion = "";
    }
    if (Name == "id_sucursal") {
      Values.direccion = target.dir;
      Value = String(Value);
      Errores.direccion = false;
    }
    if (Name == "tipo_envio" && Value != Values.tipo_envio) {
      Values.id_sucursal = "0";
      Values.direccion = "";
      Values.id_direccion = 0;
    }
    if (Name == "direccion") {
      Values.id_direccion = target.id;
      Values.lat = target.lat;
      Values.long = target.long;
    }
    //Values[Name] = Value;
    //setValues(Values);
    setValues({ ...Values, [Name]: Value });
    //console.log(Values);
    setErrores({ ...Errores, [Name]: false });
  };
  const handleInputChangeIVA = ({ target }) => {
    let Value = target.value;
    const Name = target.name;
    console.log("Nombre", Name, "Valor", Value);
    if (Value == true) {
      setIncluye_IVA(true);
    } else {
      setIncluye_IVA(false);
    }
  };
  const handleChange = (panel) => (event, isExpanded) => {
    //console.log(panel);
    setExpanded(isExpanded ? panel : false);
  };
  //#endregion

  //#region funciones
  const openModalValidations = () => {
    if (Values.id_cliente == "" || Values.id_cliente == "0") {
      Errores.id_cliente = true;
      setErrores(Errores);
      setMensaje("Selecciona un cliente para ver direcciones");
      setOpenAlert(true);
    } else {
      SetshowModalDireccion(true);
    }
  };

  const Guardar = () => {
    setIsGuardando(true);
    /*MySwal.fire({
      title: "Correcto",
      html: "asdads",
      icon: "success",
      confirmButtoColor: "#3ABE88",
      showConfirmButton: true,
      confirmButtonText: "Ver ticket",
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      timer: 10000,
    }).then((result) => {
      console.log(result);
      setIsGuardando(false);
    });*/
    //setValues({ ...Values, productos: productosCarrito });
    //console.log(Values);
    guardar(
      Values,
      Errores,
      setErrores,
      productosCarrito,
      isRentable,
      descuento,
      precioEnvio,
      Incluye_IVA
    )
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: true,
            confirmButtonText: "Imprimir ticket",
            showCancelButton: true,
            cancelButtonText: "Cerrar",
            timer: 10000,
          }).then((result) => {
            setShowModalMetodosPago(false);
            setTab(0);
            setProductosCarrito([]);
            setValues({
              id: id ?? "0",
              id_cliente: "0",
              id_sucursal: "0",
              id_direccion: 0,
              fecha_entrega: ConvertDate(GetDate()),
              observaciones: "",
              direccion: "",
              tipo_envio: 1,
              descuento: 0,
              tipo_descuento: 0,
              descuento_max: 0,
              subtotal: 0,
              total: 0,
              pagado: false,
              personalizable: false,
              Incluye_iva: false,
              productos: [],
              codigo: "",
              id_zona: 0,
              ubicacion: "0 0",
              lat: 0,
              long: 0,
              horario_entrega: {
                inicio: "12:34:00",
                fin: "15:10:00",
              },
              metodos_pago: {
                tarjeta: 0,
                transferencia: 0,
                efectivo: 0,
                monedero: 0,
              },
              costo_envio: 0,
              metodo_pago: " ",
              dedicatoria: "",
              envia: "",
              recibe: "",
              id_sucursal_venta: sucursalSelector,
            });
            setDescuento({ id: 0, descuento: 0, tipo: 0, max: 0 });
            setFecha(GetDate());
            setIsRentable(false);
            setCategoria(0);
            setExpanded("panel1");
            setPreioEnvio(0);
            setLocalStorage("PV", "");
            setLocalStorage("PV_PRODS", "");
            setProductosCarrito([]);
            //console.log(Values);
            if (result.isConfirmed) {
              //abrir modal de ticket
              setIDbeforeVenta(data.respuesta.id);
              setShowDiloglImprimir(true);
            }
          });
        } else {
          setMensaje(data.mensaje);
          setOpenAlert(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        //console.log(data);
        setMensaje(data.mensaje);
        setOpenAlert(true);
        setIsGuardando(false);
      });
  };

  const LimpiarDatos = () => {
    new Swal({
      title: "¿Estas seguro de eliminar los datos?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        setValues({
          id: id ?? "0",
          id_cliente: "0",
          id_sucursal: "0",
          id_direccion: 0,
          fecha_entrega: ConvertDate(GetDate()),
          observaciones: "",
          direccion: "",
          tipo_envio: 1,
          descuento: 0,
          tipo_descuento: 0,
          descuento_max: 0,
          subtotal: 0,
          total: 0,
          pagado: false,
          personalizable: false,
          Incluye_iva: false,
          productos: [],
          codigo: "",
          id_zona: 0,
          ubicacion: "0 0",
          lat: 0,
          long: 0,
          horario_entrega: {
            inicio: "12:34:00",
            fin: "15:10:00",
          },
          metodos_pago: {
            tarjeta: 0,
            transferencia: 0,
            efectivo: 0,
            monedero: 0,
          },
          costo_envio: 0,
          metodo_pago: "",
          dedicatoria: "",
          envia: "",
          recibe: "",
          id_sucursal_venta: sucursalSelector,
        });
        setDescuento({ id: 0, descuento: 0, tipo: 0, max: 0 });
        setFecha(GetDate());
        setIsRentable(false);
        setCategoria(0);
        setExpanded("panel1");
        setPreioEnvio(0);
        setLocalStorage("PV", "");
        setLocalStorage("PV_PRODS", "");
        setProductosCarrito([]);
      }
    });
  };

  const cambioSucursal = () => {
    new Swal({
      title: "¿Estas seguro de cambiar de sucursal?",
      text: "",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      if (isConfirm.isConfirmed) {
        setSucursalSelector(0);
        setSucursalName("");
      }
    });
  };
  //#endregion

  //#region Inicio de effects
  useEffect(() => {
    setValues({ ...Values, fecha_entrega: ConvertDate(Fecha) });
    Errores.fecha_entrega = false;
    setErrores(Errores);
  }, [Fecha]);

  useEffect(() => {
    Values.horario_entrega.inicio = horarios.inicio;
    Values.horario_entrega.fin = horarios.fin;
    setValues(Values);
    Errores.horarios_entrega = false;
    setErrores(Errores);
    //console.log(horarios);
    //console.log("Se capto el cambio de horarios");
    //setErrores({ ...Errores, horarios_entrega: false });
  }, [horarios]);

  useEffect(() => {
    //console.log("checar si hay uno de renta");
    let sumaPrecios = 0;
    let rent = false;
    productosCarrito.map((item) => {
      //console.log(item);
      sumaPrecios =
        sumaPrecios + parseFloat(item.PRECIO) * parseFloat(item.CANTIDAD);
      if (item.RENTABLE == true) {
        //console.log(item);
        rent = true;
      }
    });

    setIsRentable(rent);
    setValues({ ...Values, total: sumaPrecios });
    setLocalStorage("PV_PRODS", JSON.stringify(productosCarrito));
  }, [productosCarrito]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Aquí guardamos el valor de Incluye_IVA en el almacenamiento local
      localStorage.setItem("Incluye_IVA", String(Incluye_IVA));
    };

    const handleLoad = () => {
      // Aquí recuperamos el valor de Incluye_IVA del almacenamiento local
      const storedValue = localStorage.getItem("Incluye_IVA");
      if (storedValue) {
        setIncluye_IVA(storedValue === "true");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  useEffect(() => {
    if (
      Values.id_direccion != 0 &&
      Values.id_direccion != "" &&
      Values.id_direccion != "0"
    ) {
      getPrecioZona(Values.lat, Values.long)
        .then((data) => {
          //console.log("precio envio", data);
          if (data.length > 0) {
            setPreioEnvio(data[0].PRECIO_DE_ENVIO);
            setValues({
              ...Values,
              ubicacion: `${Values.long} ${Values.lat}`,
              id_zona: data[0].ID,
            });
          } else {
            setPreioEnvio(0);
            setValues({ ...Values, id_zona: 0 });
          }
        })
        .catch((data) => {
          console.log();
        });
      //console.log("LAT: ", Values.lat);
      //console.log("LONG: ", Values.long);
    }
  }, [Values.id_direccion, Values.direccion]);

  useEffect(() => {
    if (
      Values.tipo_envio == 1 &&
      Values.id_cliente != "" &&
      Values.id_cliente != "0" &&
      Values.id_direccion == 0
    ) {
      getDireccionPrincipal(Values.id_cliente)
        .then((data) => {
          //console.log(data);
          if (data.length == 0) {
            setValues({ ...Values, direccion: "", id_direccion: 0 });
          } else {
            setValues({
              ...Values,
              direccion:
                "Calle " +
                data.CALLE +
                " " +
                data.NUMERO_EXT +
                " - " +
                data.COLONIA +
                ", " +
                data.NOMBRE_MUNICIPIO +
                ", " +
                data.NOMBRE_ESTADO +
                "/" +
                data.NOMBRE_CONTACTO +
                "/" +
                data.TELEFONO,
              id_direccion: data.ID,
              lat: data.LAT,
              long: data.LONG,
            });

            if (Errores.direccion) setErrores({ ...Errores, direccion: false });
          }
        })
        .catch((data) => {
          console.log(data);
        });
    }
    /*
      else {
      setValues({
        ...Values,
        direccion: "",
        //id_sucursal: 0,
        //tipo_envio: 0
      });
    }
      */
  }, [Values.id_cliente]);

  useEffect(() => {
    //console.log("Cambio de values dir", Values.id_direccion);
    //console.log("Cambio de values cli", Values.id_cliente);
    setLocalStorage("PV", JSON.stringify(Values));
  }, [Values]);

  //#endregion

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  //#region CAMBIO DE SUCURSAL
  const [sucursalSelector, setSucursalSelector] = useState(
    getLocalStorage("SUCURSAL_SELECTOR") == null ||
      getLocalStorage("SUCURSAL_SELECTOR") == undefined ||
      getLocalStorage("SUCURSAL_SELECTOR") == ""
      ? 0
      : getLocalStorage("SUCURSAL_SELECTOR")
  );
  const [sucursalName, setSucursalName] = useState(
    getLocalStorage("SUCURSAL") == null ||
      getLocalStorage("SUCURSAL") == undefined ||
      getLocalStorage("SUCURSAL") == ""
      ? 0
      : getLocalStorage("SUCURSAL")
  );

  useEffect(() => {
    setLocalStorage("SUCURSAL_SELECTOR", sucursalSelector);
    setLocalStorage("SUCURSAL", sucursalName);
    // Obtener el cliente del mostrador de la sucursal selecciona
    getClienteMostrador(sucursalSelector)
      .then((resp) => {
        setValues({
          ...Values,
          id_cliente: resp.id_cliente,
          nombre: resp.nombre,
          id_sucursal_venta: sucursalSelector,
        });
      })
      .catch((resp) => {
        MySwal.fire({
          title: "Error",
          html: resp.mensaje,
          icon: "error",
          confirmButtonColor: "#3ABE88",
          showConfirmButton: true,
          allowEscapeKey: false,
          allowEnterKey: false,
          allowOutsideClick: false,
        }).then(() => {});
      });
  }, [sucursalSelector]);
  //#endregion
  return (
    <>
      <>
        <div
          style={{
            height: "calc(100vh - 5rem)",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <StyledSnackbar
            direction="right"
            open={openAlert}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {mensaje}
            </Alert>
          </StyledSnackbar>

          {sucursalSelector == 0 ? (
            <>
              <SucursalSelector
                sucursalSelector={sucursalSelector}
                setSucursalSelector={setSucursalSelector}
                setSucursalName={setSucursalName}
              />
            </>
          ) : (
            <Row className="h-100">
              <Col
                xs={12}
                md={6}
                className="d-flex flex-column justify-content-between"
                style={
                  {
                    //background: "pink",
                    //padding: "5px"
                  }
                }
              >
                <div className="d-flex flex-column">
                  <div
                    class="flex-grow-1   "
                    style={{
                      maxHeight: "calc(100vh - 5rem)",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      //overflow: "auto",
                    }}
                  >
                    <BuscarProductos
                      productosCarrito={productosCarrito}
                      setProductosCarrito={setProductosCarrito}
                      IVA={Incluye_IVA}
                      sucursalSelector={sucursalSelector}
                    />
                    {/*{categoria == 0 && query == "" ? (
                  <Categorias
                    setCategoria={setCategoria}
                    categoria={categoria}
                  />
                ) : (
                  <Productos
                    setCategoria={setCategoria}
                    categoria={categoria}
                    productosCarrito={productosCarrito}
                    setProductosCarrito={setProductosCarrito}
                  />
                )}*/}
                  </div>
                  {/*

 <div className="h-300">
  <Descuentos
                productosCarrito={productosCarrito}
                productos={productosCarrito}
                setProductosCarrito={setProductosCarrito}
                setDescuento={setDescuento}
                descuento={descuento}
                mensaje={mensaje}
                setMensaje={setMensaje}
                setOpenAlert={setOpenAlert}
                handleInputChange={handleInputChange}
              />
  </div>


    */}
                </div>
              </Col>
              <Col
                xs={12}
                md={6}
                className="d-flex flex-column justify-content-between bg-white"
                style={
                  {
                    //background: "green"
                  }
                }
              >
                <div class="containerZic">
                  <div className="d-flex justify-content-between">
                    <h5
                      className="text-white fw-bolder"
                      onClick={() => {}}
                      style={{ background: "", width: "140px" }}
                    >
                      Nuevo pedido
                    </h5>
                    {/*<Tooltip title="Cambiar de sucursal">
                      <div
                        className="d-flex justify-content-end"
                        onClick={() => cambioSucursal()}
                        style={{ cursor: "pointer" }}
                      >
                        <h5
                          className="text-white fw-bolder"
                          style={{ background: "", width: "" }}
                        >
                          {sucursalName}
                        </h5>
                        <div className="d-flex align-self-auto ms-2">
                          <SyncIcon
                            style={{
                              color: "white",
                            }}
                          />
                        </div>
                      </div>
                    </Tooltip>*/}

                     <div class="d-flex flex-row justify-content-end align-items-start mb-0 flex-wrap">

                        <h5
                          className="text-white fw-bolder mt-1 mx-3"
                          style={{ background: "", width: "" }}
                        >
                          {sucursalName}
                        </h5>
                        
                              <IconButton
                                style={{color:"#FFF"}}
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                  'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={ChangeOption}
                                PaperProps={{
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '20ch',
                                  },
                                }}
                              >
                                {options.map((option) => (
                                  <MenuItem key={option} onClick={ChangeOption}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </Menu>
                            </div>
                  </div>
                </div>
                <div
                  className="flex-grow-1"
                  style={
                    {
                      //background: "orange",
                    }
                  }
                >
                  <Row className="">
                    <Col xs={12}>
                      <Tabs
                        TabIndicatorProps={{
                          bgcolor: "#006064",
                          style: {
                            backgroundColor: "#006064",
                          },
                        }}
                        value={tab}
                        onChange={handleChangeTab}
                        variant="scrollable"
                        scrollButtons="auto"
                        textColor="#212529"
                        indicatorColor=""
                        aria-label="scrollable auto tabs example"
                      >
                        <Tab label="Productos" className="font-AvenirBold" />
                        <Tab label="Envío" className="font-AvenirBold" />
                        <Tab label="Dedicatoria" className="font-AvenirBold" />
                        <Tab label="Pagar" className="font-AvenirBold" />
                      </Tabs>
                    </Col>
                    {tab == 0 ? (
                      <Col xs={12}>
                        <Carrito
                          //isVista
                          productosCarrito={productosCarrito}
                          setProductosCarrito={setProductosCarrito}
                          descuento={descuento}
                          setDescuento={setDescuento}
                          cambio={0}
                          setIsRentable={setIsRentable}
                          isRentable={isRentable}
                          precioEnvio={precioEnvio}
                          tipoEnvio={Values.tipo_envio}
                          IVA={Incluye_IVA}
                          Values={Values}
                        />
                        <div className="w-100 text-end pe-3">
                          <Link
                            className=" font-AvenirMedium text-end "
                            variant="text"
                            href="#"
                            underline="none"
                            style={{ color: "#006064", textAlign: "end" }}
                            onClick={() => {
                              LimpiarDatos();
                              //console.log("datos limpios");
                            }}
                          >
                            Limpiar Carrito
                          </Link>
                        </div>
                        <div className="w-100 text-end pe-3">
                          <label htmlFor="">Incluye IVA</label>
                          <Switch
                            checked={Incluye_IVA}
                            name="INCLUYE_IVA"
                            error={Incluye_IVA}
                            onChange={(checked) => {
                              handleInputChangeIVA({
                                target: {
                                  name: "INCLUYE_IVA",
                                  value: checked,
                                },
                              });
                            }}
                            tituloActivo="Si"
                            tituloInactivo="No"
                            on
                          />
                        </div>
                      </Col>
                    ) : tab == 1 ? (
                      <TabsDatosEntrega
                        Values={Values}
                        handleInputChange={handleInputChange}
                        openModalValidations={openModalValidations}
                        expanded={expanded}
                        handleChange={handleChange}
                        Fecha={Fecha}
                        setFecha={setFecha}
                        Errores={Errores}
                        horarios={horarios}
                        setHorarios={setHorarios}
                      />
                    ) : (
                      tab == 2 && (
                        <TabsDedicatoria
                          Values={Values}
                          handleInputChange={handleInputChange}
                        />
                      )
                    )}
                  </Row>

                  {tab == 3 && (
                    <TabsPago
                      horarios={horarios}
                      Values={Values}
                      totalCarrito={Number(Values.total) + Number(precioEnvio)}
                      setShowModalMetodosPago={setShowModalMetodosPago}
                      IsGuardando={IsGuardando}
                      Guardar={Guardar}
                      id={id}
                      productosCarrito={productosCarrito}
                      precioEnvio={precioEnvio}
                      productos={productosCarrito}
                      setProductosCarrito={setProductosCarrito}
                      setDescuento={setDescuento}
                      descuento={descuento}
                      mensaje={mensaje}
                      setMensaje={setMensaje}
                      setOpenAlert={setOpenAlert}
                      handleInputChange={handleInputChange}
                      IVA={Incluye_IVA}
                    />
                  )}
                </div>
                <div
                  style={
                    {
                      //background: "blue",
                    }
                  }
                >
                  <Row>
                    <Stack
                      className="p-3"
                      spacing={2}
                      direction={{ xs: "column", sm: "row" }}
                      style={{}}
                    >
                      <ButtonsTabs
                        setShowModalMetodosPago={setShowModalMetodosPago}
                        tab={tab}
                        setTab={setTab}
                      />
                    </Stack>
                  </Row>
                </div>
              </Col>
            </Row>
          )}
          {showModalDireccion ? (
            <ModalSelectDireccionCliente
              SetshowModalDireccion={SetshowModalDireccion}
              setOpenNuevaDireccion={setOpenNuevaDireccion}
              //getDireciones={getDireciones}
              id_cliente={Values.id_cliente}
              id={Values.id_direccion}
              handlInputChange={handleInputChange}
            />
          ) : (
            ""
          )}
          {openNuevaDireccion ? (
            <ModalDireccion
              SetshowModalDireccion={setOpenNuevaDireccion}
              SetshowModalDirecciones={SetshowModalDireccion}
              handlInputChanges={handleInputChange}
              id_cliente={Values.id_cliente}
            />
          ) : (
            ""
          )}
          {showModalMetodosPago ? (
            <ModalPagoVenta
              setShowModalPago={setShowModalMetodosPago}
              Values={Values}
              setValues={setValues}
              Errores={Errores}
              handlInputChange={handleInputChange}
              totalCarrito={Values.total}
              Guardar={Guardar}
              IsGuardando={IsGuardando}
              descuento={descuento}
              costoEnvio={precioEnvio}
              productosCarrito={productosCarrito}
              setProductosCarrito={setProductosCarrito}
              IVA={Incluye_IVA}
            />
          ) : (
            ""
          )}
          {showDiloglImprimir && (
            <ModalTicket
              pedidoSeleccionado={IDbeforeVenta}
              setShowDiloglImprimir={setShowDiloglImprimir}
              ticketFromVenta
            />
          )}
        </div>
      </>
    </>
  );
}
