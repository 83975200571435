import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  MenuItem,
  Box,
  Tabs,
  Tab,
  Alert,
  styled,
  Snackbar,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Monedas } from "../../../../../Lib/Funciones Generales/Constantes";
import {
  getInfoOrdenCompra,
  getListaDetalleOrden,
  GuardarOrdeCompra,
} from "../Funciones/OrdenCompra";
import TableDetalleOrden from "./TablaDetalleOrden";
import ModalSelectProd from "../../Productos/Componentes/ModalSelectProd";
import SelectSucursales from "../../Sucursales/Componentes/SelectSucursales";
import SelectAlmacenes from "../../Almacenes/Componentes/SelectAlmacenes";
import SelectProveedores from "../../Proveedores/Componentes/SelectProveedores";
import RepOrdenCompra from "./RepOrdenCompra";
import { getInfoProveedorDatos } from "../../Proveedores/Funciones/Proveedores";
import ButtonTabs from "../../AjusteMercancia/Componentes/ButtonTabs/ButtonTabs";
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);
const MySwal = withReactContent(Swal);

const OrdenCompra = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [openMSG, setOpenMSG] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [tab, setTab] = React.useState(0);
  const [productosCarrito, setProductosCarrito] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [idAlmacenPrincipal, setIdAlmacenPrincipal] = useState(0);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [Values, setValues] = useState({
    id: id ?? "0",
    id_sucursal: "0",
    id_almacen: "0",
    id_proveedor: "0",
    moneda: "MXN",
    nombre_contacto: "",
    telefono_contacto: "",
    comentarios: "",
    relacion: [...productosCarrito],
  });

  const [Errores, setErrores] = useState({
    id_sucursal: false,
    id_almacen: false,
    id_proveedor: false,
    moneda: false,
    nombre_contacto: false,
    telefono_contacto: false,
    comentarios: false,
  });

  //#region HANDLES
  const handleProveedorChange = (event) => {
    setSelectedProveedor(event.target.value);
    ObtenerdatosProvee(event.target.value);
    setErrores({ ...Errores, id_proveedor: !event.target.value });
  };

  const ObtenerdatosProvee = async (Proveedor) => {
    console.log(Proveedor);
    const response = await getInfoProveedorDatos(Proveedor);
    console.log(response);
    // Values.contacto = response.RAZON_SOCIAL;
    Values.nombre_contacto = response.CONTACTO;
    Values.telefono_contacto = response.TELEFONO;
    Values.id_proveedor = Proveedor;
    Values.relacion = [...productosCarrito];
    setValues(Values);
    // setValues([...Values]);
  };
  console.log(productosCarrito);
  console.log("Relacion", (Values.relacion = [...productosCarrito]));
  console.log(Values);

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value;
    console.log("Name: ", Name, " Value: ", Value);
    if (Name == "id_almacen") {
      setProductosCarrito([]);
    }

    setValues({
      ...Values,
      [Name]: Value,
    });
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  //#endregion

  const GuardarOrdenComrpa = () => {
    setIsGuardando(true);
    GuardarOrdeCompra(Values, Errores, setErrores, productosCarrito, id)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpenMSG(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpenMSG(true);
        setIsGuardando(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    if (id != undefined) {
      getInfoOrdenCompra(id)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            //setValues(resp);
            setIsLoading(false);
            setValues(resp.values);
            setSelectedProveedor(resp.values.id_proveedor);
            setProductosCarrito(resp.productos);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    document.title = `Orden de Compra`;
  }, []);

  const getListaDetalle = () => {
    setIsLoading(true);
    getListaDetalleOrden(id)
      .then((resp) => {
        setProductosCarrito([]);
        let list = JSON.parse(resp.data);
        var LISTA = list.filter((producto) => {
          const nuevoProducto = {
            ID_PRODUCTO: parseInt(producto.ID),
            DESCRIPCION_PRODUCTO: producto.DESC_CORTA,
            PRECIO: parseFloat(producto.PRECIO_COMPRA),
            CANTIDAD: 1,
          };
          return nuevoProducto;
        });
        setProductosCarrito(LISTA);
        setIsLoading(false);
      })
      .catch((resp) => {
        setProductosCarrito([]);
        setIsLoading(false);
      });
  };

  const handleProductoSeleccionado = (producto, cantidad = 1) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PRODUCTO === producto.ID)
      : -1;
    if (itemIndex !== -1) {
      const productoExistente = items[itemIndex];
      productoExistente.CANTIDAD += cantidad;
    } else {
      producto = {
        ID_PRODUCTO: parseInt(producto.ID),
        DESCRIPCION_PRODUCTO: producto.DESC_CORTA,
        PRECIO: 0, //parseFloat(producto.PRECIO_COMPRA),
        CANTIDAD: cantidad,
        PRODUCTO_TERMINADO: producto.PRODUCTO_TERMINADO,
      };

      items.push(producto);
    }
    setProductosCarrito(items);
  };

  // const caracteresRestantes = (tam, max) => {
  //   const total = max - Number(tam);

  //   if (Number(tam) == 0) {
  //     return "";
  //   } else {
  //     return `(${total})`;
  //   }
  // };
  return (
    <>
      <StyledSnackbar
        direction="right"
        open={openMSG}
        autoHideDuration={6000}
        onClose={() => {
          setOpenMSG(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => {
            setOpenMSG(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Orden de compra
      </Button>
      <Box className=" mb-2">
        <Tabs
          TabIndicatorProps={{
            bgcolor: "#3abe88",
            style: {
              backgroundColor: "#3abe88",
            },
          }}
          value={tab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="#212529"
          indicatorColor=""
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Datos de orden de compra" className="font-AvenirBold" />
          <Tab label="Agregar Productos" className="font-AvenirBold" />
        </Tabs>
      </Box>
      <Card elevation={3} className="mb-4">
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar orden de compra" : "Nueva orden de compra"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />
          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="p-3">
                {tab == 0 ? (
                  <>
                    <Col sm={12} md={6} className="p-3">
                      <FormControl fullWidth>
                        <SelectSucursales
                          Name="id_sucursal"
                          Value={Values.id_sucursal}
                          Error={Errores.id_sucursal}
                          handlInputChange={handlInputChange}
                        />
                      </FormControl>
                    </Col>
                    <Col sm={12} md={6} className="p-3">
                      <FormControl fullWidth>
                        <SelectAlmacenes
                          name="id_almacen"
                          Value={Values.id_almacen}
                          Error={Errores.id_almacen}
                          handlInputChange={handlInputChange}
                          Sucursal={Values.id_sucursal}
                          handleAlmacenPrincipal={(e) => {
                            setIdAlmacenPrincipal(e);
                          }}
                          principal={idAlmacenPrincipal}
                        />
                      </FormControl>
                    </Col>
                    <Col sm={12} md={6} className="p-3">
                      <FormControl fullWidth>
                        <SelectProveedores
                          Name="id_proveedor"
                          Value={Values.id_proveedor}
                          Error={Errores.id_proveedor}
                          handlInputChange={(event) => {
                            handlInputChange(event);
                            handleProveedorChange(event);
                          }}
                        />
                      </FormControl>
                    </Col>
                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        id="Moneda"
                        select
                        label="Moneda"
                        name="moneda"
                        helperText={Errores.moneda ? "Moneda no valida" : ""}
                        value={Values.moneda}
                        error={Errores.moneda}
                        onChange={handlInputChange}
                      >
                        {Monedas.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Col>
                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="nombre_contacto"
                        label="Nombre contacto"
                        variant="outlined"
                        inputProps={{ maxLength: 100 }}
                        helperText={
                          Errores.nombre_contacto
                            ? "Nombre comercial no valido"
                            : ""
                        }
                        value={Values.nombre_contacto}
                        error={Errores.nombre_contacto}
                        onChange={handlInputChange}
                      />
                    </Col>
                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        name="telefono_contacto"
                        label="Teléfono"
                        variant="outlined"
                        inputProps={{ maxLength: 10 }}
                        helperText={
                          Errores.telefono_contacto ? "Teléfono inválido" : ""
                        }
                        value={Values.telefono_contacto}
                        error={Errores.telefono_contacto}
                        onChange={handlInputChange}
                      />
                    </Col>
                    <Col sm={12} md={6} className="p-3">
                      <TextField
                        fullWidth
                        multiline
                        name="comentarios"
                        label="Comentarios"
                        variant="outlined"
                        inputProps={{ maxLength: 500 }}
                        helperText={
                          Errores.comentarios ? "Comentario no valido" : ""
                        }
                        value={Values.comentarios}
                        error={Errores.comentarios}
                        onChange={handlInputChange}
                      />
                    </Col>
                    {/* <Col sm={12} md={6} className="p-3">
                      <TextField
                        multiline
                        fullWidth
                        name="contacto"
                        variant="outlined"
                        label={`Contacto ${caracteresRestantes(
                          Values.contacto.length,
                          1000
                        )}`}
                        inputProps={{ maxLength: 1000 }}
                        value={Values.contacto}
                        error={Errores.contacto}
                        onChange={handlInputChange}
                      />
                    </Col> */}
                  </>
                ) : (
                  <>
                    <div>
                      <Button
                        // disabled={!selectedProveedor}
                        style={{ float: "right" }}
                        className="btn btn-Crexendo font-AvenirMedium  py-2 px-4"
                        onClick={handleOpen}
                      >
                        Agregar productos
                      </Button>
                      {open && (
                        <ModalSelectProd
                          handleProductoSeleccionado={
                            handleProductoSeleccionado
                          }
                          // proveedorId={selectedProveedor}
                          onClose={handleClose}
                          //onlyProductosTerminados={
                          //idAlmacenPrincipal == 0 ? true : false
                          //}
                          MostrarBoton={true}
                        />
                      )}
                    </div>
                    <Col sm={12} style={{ paddingTop: "15px" }}>
                      <TableDetalleOrden
                        handlInputChange={handlInputChange}
                        productosCarrito={productosCarrito ?? []}
                        setProductosCarrito={setProductosCarrito}
                        IsLoading={IsLoading}
                      ></TableDetalleOrden>
                    </Col>
                  </>
                )}
              </Row>
            </>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarOrdenComrpa()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
            {/*<Button
              onClick={() => {
                const nextTab = tab - 1 < 0 ? 0 : tab - 1;
                setTab(nextTab);
              }}
              className="btn btn-exp-Excel font-AvenirMedium py-2 px-4 ms-auto"
              variant="text"
            >
              <ArrowBackIosNewIcon style={{ fontSize: "20px" }} />
              Anterior
            </Button>
            <Button
              onClick={() => {
                const nextTab = tab + 1 > 3 ? 3 : tab + 1;
                setTab(nextTab);
              }}
              className="btn btn-exp-Excel font-AvenirMedium py-2 px-4 ms-n2"
              variant="text"
            >
              Siguiente
              <ArrowForwardIosIcon style={{ fontSize: "20px" }} />
            </Button>*/}
            <ButtonTabs tab={tab} setTab={setTab} />
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default OrdenCompra;
