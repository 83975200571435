import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { Card, Row } from "react-bootstrap";
import CardPedido from "./CardPedido";
import Loading from "../../../../Includes/Loading";

const Cuadricula = ({
  Lista
  , setPedidoSeleccionado
  , setShowDialogAsignarFlorista
  , setShowDilogDetalle
  , setShowDiloglEstatutus
  , setShowDiloglImprimir
  , setShowDialogAsignarRepartidor
  , setRepartidorSeleccionado
  , IsLoading
  , estado
  , setShowDialogoModalEditar
}) => {
  
  return (  <>
  
     {IsLoading ? (
        <div className="col-12  ">
          <Loading />
        </div>
      ) : (
        <>
        <Row  >
          {Lista &&
            Array.isArray(Lista) &&
            Lista.map((row, index) => (
              <CardPedido
                key={"card_pedido_" + row.ID}
                row={row}
                index={index}
                setPedidoSeleccionado={setPedidoSeleccionado}
                setShowDialogAsignarFlorista={setShowDialogAsignarFlorista}
                setShowDilogDetalle={setShowDilogDetalle}
                setShowDiloglEstatutus={setShowDiloglEstatutus}
                setShowDiloglImprimir={setShowDiloglImprimir}
                setShowDialogAsignarRepartidor={setShowDialogAsignarRepartidor}
                setRepartidorSeleccionado={setRepartidorSeleccionado} 
          setShowDialogoModalEditar={setShowDialogoModalEditar}

              />
            ))}
        </Row>
        </>
      )
      }
      </>
  );
};

export default Cuadricula;
