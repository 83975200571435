import { stringify } from "query-string";
import { postdData, postUrl, getData } from "../../../../Context/backend";
import {
  getDaysFromHours,
  obtenerMetodoPagoMasAlto,
} from "../../../../Lib/Funciones Generales/Generales";

export const getClienteMostrador = async (sucursalSelector) => {
  let queryParamsObj = {
    id_sucursal: sucursalSelector,
  };

  let data = [];
  const url = `ventas/getClienteMostrador?${stringify(queryParamsObj)}`;

  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este cliente",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        //console.log(data);
        let Values = {
          id_cliente: String(data.ID),
          nombre: String(data.NOMBRE) + " " + String(data.APELLIDO),
        };
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const guardar = async (
  Values,
  Errores,
  setErrores,
  productos = [],
  isRentable,
  descuento,
  precioEnvio,
  Incluye_IVA
) => {
  let error = false;

  if (
    !Values.id_cliente ||
    Values.id_cliente == "" ||
    Values.id_cliente == "0" ||
    Values.id_cliente == "undefined"
  ) {
    Errores.id_cliente = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.fecha_entrega || Values.fecha_entrega == "") {
    Errores.fecha_entrega = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.tipo_envio || Values.tipo_envio == 0) {
    Errores.tipo_envio = true;
    setErrores(Errores);
    error = true;
  }

  switch (Values.tipo_envio) {
    case 1:
      if (!Values.id_direccion || Values.id_direccion <= 0) {
        Errores.direccion = true;
        setErrores(Errores);
        error = true;
      }
      break;
    case 2:
      if (
        !Values.id_sucursal ||
        Values.id_sucursal == "" ||
        Values.id_sucursal == "0"
      ) {
        Errores.direccion = true;
        setErrores(Errores);
        error = true;
      }
      break;
    default:
      Errores.tipo_envio = true;
      setErrores(Errores);
      error = true;
      break;
  }
  /*if (!Values.direccion || Values.direccion == "") {
    Errores.direccion = true;
    setErrores(Errores);
    error = true;
  }*/
  if (
    !Values.horario_entrega.inicio ||
    !Values.horario_entrega.fin ||
    Values.horario_entrega.inicio == "" ||
    Values.horario_entrega.fin == "" ||
    Values.horario_entrega.inicio == "00:00:00" ||
    Values.horario_entrega.fin == "00:00:00" ||
    Values.horario_entrega.inicio == "00:00" ||
    Values.horario_entrega.fin == "00:00"
  ) {
    Errores.horarios_entrega = true;
    setErrores(Errores);
    error = true;
  }

  /*if (isRentable) {
    if (!Values.nombre_contacto || Values.nombre_contacto == "") {
      Errores.nombre_contacto = true;
      setErrores(Errores);
      error = true;
    }
    if (!Values.telefono_contacto || Values.telefono_contacto == "") {
      Errores.telefono_contacto = true;
      setErrores(Errores);
      error = true;
    }
  }*/

  const newArrProds = productos.map((item) => {
    //console.log("DV", item);
    return {
      id: item.ID,
      precio: item.PRECIO,
      cantidad: item.CANTIDAD,
      diaS_RENTA: String(
        item.RENTABLE ? getDaysFromHours(item.HORAS_RENTA) : 0
      ),
      COMPOSICION: item.COMPOSICION,
    };
  });
  Values.productos = newArrProds;

  // const newArrProdCompo = productoscompo.map((item) => {
  //   return {
  //     id: item.ID_PRODUCTO,
  //     precio: item.PRECIO,
  //     cantidad: item.CANTIDAD,
  //     DESCRIPCION_PRODUCTO: item.DESCRIPCION_PRODUCTO,
  //   };
  // });
  // console.log("Carrito Compo", newArrProdCompo);
  // Values.productoscompo = newArrProdCompo;

  Values.descuento = descuento.descuento ? descuento.descuento : 0;
  Values.tipo_descuento = descuento.tipo ? descuento.tipo : 0;
  Values.descuento_max = descuento.max ? descuento.max : 0;

  //console.log(Errores);
  if (productos.length <= 0 && !error) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Inserta al menos un producto" });
    });
  }

  if (Values.id_zona <= 0 && Values.tipo_envio == 1 && !error) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Ingresa una dirección con cobertura" });
    });
  }
  /*const vt = ValidateTimes(
    Values.horario_entrega.inicio,
    Values.horario_entrega.fin
  );
  if (vt.err && !error) {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: vt.msj });
    });
  }*/
  const metodoP = obtenerMetodoPagoMasAlto(Values.metodos_pago);
  //console.log("METODOS DE PAGO MAS ALTO: ", metodoP);
  switch (metodoP) {
    case "transferencia":
      Values.metodo_pago = "TRANSFERENCIA";
      break;
    case "monedero":
      Values.metodo_pago = "MONEDERO";
      break;
    case "tarjeta":
      Values.metodo_pago = "TARJETA";
      break;
    case "efectivo":
      Values.metodo_pago = "EFECTIVO";
      break;
    default:
      Values.metodo_pago = "";
      break;
  }
  //console.log("Pagado: ", Values.pagado);
  //console.log("Error: ", !error);
  if (Values.pagado && !error) {
    const MP =
      Values.metodos_pago.tarjeta +
      Values.metodos_pago.transferencia +
      Values.metodos_pago.efectivo +
      Values.metodos_pago.monedero;
    //console.log("1", Values.total);
    //console.log("2", Values.costo_envio);

    let totalDescuento =
      descuento.tipo == 0
        ? 0
        : descuento.tipo == 1
        ? (Values.total * descuento.descuento) / 100 > descuento.max
          ? descuento.max
          : (Values.total * descuento.descuento) / 100
        : descuento.descuento;

    const Valor_IVA = 16; // IVA del producto
    const IVA_FRACCION = Valor_IVA / 100 + 1;
    const RestaIVA = Values.total / IVA_FRACCION;
    const valorCalculado = Incluye_IVA ? Values.total : RestaIVA.toFixed(2);

    let total =
      parseFloat(valorCalculado - totalDescuento) +
      parseFloat(Values.costo_envio);
    console.log(total);
    Values.total = total;
    //let total = Values.total + Values.costo_envio;

    if (MP < total) {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Cubre el pago en su totalidad" });
      });
    }
  }
  Values.Incluye_iva = Incluye_IVA;
  //console.log(Values);
  if (!error) {
    let data = [];

    Values.id = Number(Values.id);
    Values.costo_envio = Number(precioEnvio);
    let queryParamsObj = {
      existRentables: isRentable,
    };

    Values.id_cliente = String(Values.id_cliente);
    Values.id_sucursal = String(Values.id_sucursal);
    const body = Values;
    //console.log("Detalle Ventas", body);

    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? `ventas/Insertar?${stringify(queryParamsObj)}`
        : `ventas/Editar`;

    const res = await postdData(url, body);
    //console.log(res);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;

        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};
