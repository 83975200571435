import { useEffect, useState, useRef } from "react";
import { getLocalStorageJWT } from "../Context/Storage";
import StartLoad from "../Includes/StartLoad";
import LoginManager from "./Components/Login/LoginManager";
import Menu from "./includes/Menu";
import { PermissionsProvider } from '../Context/PermissionsContext';
import { styled } from "@mui/material/styles";

const useStyles = styled({
  overriddenSuccess: {
    backgroundColor: "red !important"
  }
});

const AppManager = () => {
  
  const [isLoged, setIsLoged] = useState(false);
  const [load, setLoad] = useState(true);
  const getInfoUsuario = async () => {
    if (getLocalStorageJWT() != "") {
      setIsLoged(true);
    }
  };



  useEffect(() => {
    setLoad(true);
    getInfoUsuario().then(
      setTimeout(() => {
        setLoad(false);
      }, 1500)
    );
  }, []);


  return load ? <StartLoad /> : isLoged ? (
    <PermissionsProvider>
      <Menu />
    </PermissionsProvider>
  ) : (
    <LoginManager />
  );
};
export default AppManager;
