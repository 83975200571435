import {
    Button,
    Card,
    CardContent,
    Stack,
    TextField,
    Alert,
    styled,
    Snackbar,
  } from "@mui/material";
  import React, { useState, useEffect } from "react";
  import { Col, Row } from "react-bootstrap";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import { useNavigate } from "react-router-dom";
  import Loading from "../../../../../Includes/Loading";
  import LoadingButton from "@mui/lab/LoadingButton";
  import { useParams } from "react-router-dom";
  import withReactContent from "sweetalert2-react-content";
  import Swal from "sweetalert2";
  import { getLocalStorage } from "../../../../../Context/Storage";

  import {
    getInfoCorte,
    GuardarCorte,
  } from "../Funciones/CorteCaja";

  
  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  const MySwal = withReactContent(Swal);
  
  const CortesCaja = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [IsLoading, setIsLoading] = useState(false);
    const [IsGuardando, setIsGuardando] = useState(false);
    const [open, setOpen] = useState(false);
    const [openMSG, setOpenMSG] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tab, setTab] = React.useState(0);
    const [detalle, setDetalle] = useState([]);
    let navigated = useNavigate();

  
    const [Values, setValues] = useState({
      fTotalTarjeta: 0,
      fTotalEfectivo: 0,
      fTotalMonedero: 0,
      fTotalTransferencia: 0,
      fTotalCompras: 0,
      observaciones: ""
    });
  
    const [Errores, setErrores] = useState({
      fTotalTarjeta: false,
      fTotalEfectivo: false,
      fTotalMonedero: false,
      fTotalTransferencia: false,
      fTotalCompras: false,
      observaciones: false,

    });

    const [sucursalSelector, setSucursalSelector] = useState(
      getLocalStorage("SUCURSAL_SELECTOR") == null ||
        getLocalStorage("SUCURSAL_SELECTOR") == undefined ||
        getLocalStorage("SUCURSAL_SELECTOR") == ""
        ? 0
        : getLocalStorage("SUCURSAL_SELECTOR")
    );
  
     console.log("ID_SUCURSAL:"+ sucursalSelector)
  
    const handlInputChange = ({ target }) => {
      let Name = target.name;
      let Value = target.value;
      //console.log("Name: ", Name, " Value: ", Value);

  
      setValues({
        ...Values,
        [Name]: Value,
      });
      setErrores({
        ...Errores,
        [Name]: false,
      });
    };
    const handleChangeTab = (event, newValue) => {
      setTab(newValue);
    };
  
    //#endregion
  
    const GuardarCorteCaja = () => {
      setIsGuardando(true);
      GuardarCorte(Values, Errores, setErrores, detalle, sucursalSelector)
        .then((data) => {
          if (data.codigo == "200") {
            setIsGuardando(false);
            MySwal.fire({
              title: "Correcto",
              html: data.mensaje,
              icon: "success",
              confirmButtoColor: "#3ABE88",
              showConfirmButton: false,
              timer: 1700,
            }).then((result) => {
              navigate(-1);
            });
          } else {
            setMensaje(data.mensaje);
            setOpenMSG(true);
            setIsGuardando(false);
          }
        })
        .catch((data) => {
          setMensaje(data.mensaje);
          setOpenMSG(true);
          setIsGuardando(false);
        });
    };
  
    useEffect(() => {
      setIsLoading(true);
      const timer = setTimeout(() => {
        getInfoCorte(sucursalSelector)
          .then((resp) => {
            if (resp?.err?.length == 0) {
              MySwal.fire({
                title: "Error",
                html: resp.mensaje,
                icon: "error",
                confirmButtonColor: "#3ABE88",
                showConfirmButton: true,
                allowEscapeKey: false,
                allowEnterKey: false,
                allowOutsideClick: false,
              }).then(() => {
                setIsLoading(false);
                navigate(-1);
              });
            } else {
              //setValues(resp);
              //console.log("aqui"+ JSON.stringify(resp));
              setIsLoading(false);
              setValues(resp.values);
              setDetalle(resp.detalle);

        
            }
          })
          .catch((resp) => {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          });

        }, 1300);
        return () => clearTimeout(timer);
      
    }, []);
  


    useEffect(() => {

      document.title = `Corte de caja`;
      
      const timer = setTimeout(() => {
        if(Values.fTotalCompras === 0){
          MySwal.fire({
            title: "Error",
            html: "No hay ventas suficientes para crear corte",
            icon: "warning",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            navigated("/cortes");
          });
        }
      }, 2000);
      return () => clearTimeout(timer);
    }, [Values]);




  
    return (
      <>
        <StyledSnackbar
          direction="right"
          open={openMSG}
          autoHideDuration={6000}
          onClose={() => {
            setOpenMSG(false);
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={() => {
              setOpenMSG(false);
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            {mensaje}
          </Alert>
        </StyledSnackbar>
  
        <Button
          onClick={() => navigate(-1)}
          className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
          variant="text"
        >
          <ArrowBackIcon className="me-3" />
          Crear nuevo corte
        </Button>

        <Card elevation={3} className="mb-4">
          <CardContent className="p-0">
            <div className="p-3">
              <h6 className="mb-0 text-left font-AvenirBold">
                Nuevo corte
              </h6>
            </div>
            <hr
              style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
              className="m-0 p-0"
            />
            {IsLoading ? (
              <Row className="px-3">
                <Col sm={12} className="p-3">
                  <Loading />
                </Col>
              </Row>
            ) : (
              <>
                <Row className="p-3">
                
                <Col sm={12} md={3} className="p-3">

                <TextField
                          fullWidth
                          multiline
                          name="fTotalTarjeta"
                          label="Tarjeta"
                          variant="outlined"
                          inputProps={{ maxLength: 500 }}
                          helperText={
                            Errores.fTotalTarjeta ? "Total de tarjeta inválido" : ""
                          }
                          value={Values.fTotalTarjeta.toFixed(2)}
                          error={Errores.fTotalTarjeta}
                          onChange={handlInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />

                  </Col>


                  <Col sm={12} md={3} className="p-3">

                   <TextField
                            fullWidth
                            multiline
                            name="fTotalEfectivo"
                            label="Efectivo"
                            variant="outlined"
                            inputProps={{ maxLength: 500 }}
                            helperText={
                              Errores.fTotalEfectivo ? "Total de efectivo inválido" : ""
                            }
                            value={Values.fTotalEfectivo.toFixed(2)}
                            error={Errores.fTotalEfectivo}
                            onChange={handlInputChange}
                            InputProps={{
                              readOnly: true,
                            }}
                          />

                  </Col>


                  <Col sm={12} md={3} className="p-3">

                  <TextField
                          fullWidth
                          multiline
                          name="fTotalMonedero"
                          label="Monedero"
                          variant="outlined"
                          inputProps={{ maxLength: 500 }}
                          helperText={
                            Errores.fTotalMonedero ? "Total de monedero inválido" : ""
                          }
                          value={Values.fTotalMonedero.toFixed(2)}
                          error={Errores.fTotalMonedero}
                          onChange={handlInputChange}
                          InputProps={{
                            readOnly: true,
                          }}
                        />

                  </Col>


                  <Col sm={12} md={3} className="p-3">

                    <TextField
                            fullWidth
                            multiline
                            name="fTotalTransferencia"
                            label="Transferencia"
                            variant="outlined"
                            inputProps={{ maxLength: 500 }}
                            helperText={
                              Errores.fTotalTransferencia ? "Total de transferencia inválido" : ""
                            }
                            value={Values.fTotalTransferencia.toFixed(2)}
                            error={Errores.fTotalTransferencia}
                            onChange={handlInputChange}
                            InputProps={{
                              readOnly: true,
                            }}
                          />

                    </Col>
  
         
  

                    <Col sm={12} md={3} className="p-3">

                      <TextField
                              fullWidth
                              multiline
                              name="fTotalCompras"
                              label="Total compras"
                              variant="outlined"
                              inputProps={{ maxLength: 500 }}
                              helperText={
                                Errores.fTotalCompras ? "Total de compras inválido" : ""
                              }
                              value={Values.fTotalCompras}
                              error={Errores.fTotalCompras}
                              onChange={handlInputChange}
                              InputProps={{
                                readOnly: true,
                              }}
                            />

                      </Col>


                      <Col sm={12} md={6} className="p-3">
                        <TextField
                          fullWidth
                          multiline
                          name="observaciones"
                          label="Observaciones"
                          variant="outlined"
                          inputProps={{ maxLength: 500 }}
                          helperText={
                            Errores.observaciones ? "Observaciones inválidas" : ""
                          }
                          value={Values.observaciones}
                          error={Errores.observaciones}
                          onChange={handlInputChange}
                        />
                      </Col>
                   
                </Row>
              </>
            )}
            <Stack
              className="p-3"
              spacing={2}
              direction={{ xs: "column", sm: "row" }}
              style={{}}
            >
              <LoadingButton
                loading={IsGuardando}
                loadingPosition="start"
                disabled={IsLoading}
                onClick={() => GuardarCorteCaja()}
                className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardando ? "px-4" : "px-2"}>
                  {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
                </span>
              </LoadingButton>
              <Button
                onClick={() => navigate(-1)}
                className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                variant="outlined"
              >
                Cancelar
              </Button>
             
            </Stack>
          </CardContent>
        </Card>
      </>
    );
  };
  
  export default CortesCaja;
  