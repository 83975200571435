export function validarUUID(uuid) {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
}

export function getTiempoFromMinutos(minutos) {
  if (parseInt(minutos) < 60) return minutos + " Minutos";
  if (parseInt(minutos) >= 60 && parseInt(minutos) < 1440) {
    let min = minutos % 60;
    let horas = (minutos - min) / 60;
    return (
      Math.round(horas) +
      " Hrs " +
      (min > 0 ? " " + Math.round(min) + " Min" : "")
    );
  }
  if (Math.round(parseInt(minutos) / 60) == 24) {
    return Math.round(parseInt(minutos) / 60 / 24) + " días";
  }
  if (Math.round(parseInt(minutos) / 60) > 24) {
    let horas = (parseInt(minutos) / 60) % 24;
    let dias = (parseInt(minutos) / 60 - horas) / 24;

    return dias + (dias == 1 ? " Día " : " Días ") + Math.round(horas) + " Hrs";
  }
}

function Unidades(num) {
  switch (num) {
    case 1:
      return "UN";
    case 2:
      return "DOS";
    case 3:
      return "TRES";
    case 4:
      return "CUATRO";
    case 5:
      return "CINCO";
    case 6:
      return "SEIS";
    case 7:
      return "SIETE";
    case 8:
      return "OCHO";
    case 9:
      return "NUEVE";
  }

  return "";
} //Unidades()

function Decenas(strSin, numUnidades) {
  if (numUnidades > 0) return strSin + " Y " + Unidades(numUnidades);

  return strSin;
} //DecenasY()

function Centenas(num) {
  let centenas = Math.floor(num / 100);
  let decenas = num - centenas * 100;

  switch (centenas) {
    case 1:
      if (decenas > 0) return "CIENTO " + Decenas(decenas);
      return "CIEN";
    case 2:
      return "DOSCIENTOS " + Decenas(decenas);
    case 3:
      return "TRESCIENTOS " + Decenas(decenas);
    case 4:
      return "CUATROCIENTOS " + Decenas(decenas);
    case 5:
      return "QUINIENTOS " + Decenas(decenas);
    case 6:
      return "SEISCIENTOS " + Decenas(decenas);
    case 7:
      return "SETECIENTOS " + Decenas(decenas);
    case 8:
      return "OCHOCIENTOS " + Decenas(decenas);
    case 9:
      return "NOVECIENTOS " + Decenas(decenas);
  }

  return Decenas(decenas);
} //Centenas()

function Miles(num) {
  let divisor = 1000;
  let cientos = Math.floor(num / divisor);
  let resto = num - cientos * divisor;

  let strMiles = Seccion(num, divisor, "UN MIL", "MIL");
  let strCentenas = Centenas(resto);

  if (strMiles == "") return strCentenas;

  return strMiles + " " + strCentenas;
} //Miles()

function Millones(num) {
  let divisor = 1000000;
  let cientos = Math.floor(num / divisor);
  let resto = num - cientos * divisor;

  let strMillones = Seccion(num, divisor, "UN MILLON DE", "MILLONES DE");
  let strMiles = Miles(resto);

  if (strMillones == "") return strMiles;

  return strMillones + " " + strMiles;
} //Millones()

function Seccion(num, divisor, strSingular, strPlural) {
  let cientos = Math.floor(num / divisor);
  let resto = num - cientos * divisor;

  let letras = "";

  if (cientos > 0)
    if (cientos > 1) letras = Centenas(cientos) + " " + strPlural;
    else letras = strSingular;

  if (resto > 0) letras += "";

  return letras;
} //Seccion()

export function convertirNumeroATexto(num) {
  var data = {
    numero: num,
    enteros: Math.floor(num),
    centavos: Math.round(num * 100) - Math.floor(num) * 100,
    letrasCentavos: "",
    letrasMonedaPlural: "PESOS", //"PESOS", 'Dólares', 'Bolívares', 'etcs'
    letrasMonedaSingular: "PESO", //"PESO", 'Dólar', 'Bolivar', 'etc'

    letrasMonedaCentavoPlural: "CENTAVOS",
    letrasMonedaCentavoSingular: "CENTAVO",
  };

  if (data.centavos > 0) {
    data.letrasCentavos =
      "CON " +
      (function () {
        if (data.centavos == 1)
          return (
            Millones(data.centavos) + " " + data.letrasMonedaCentavoSingular
          );
        else
          return Millones(data.centavos) + " " + data.letrasMonedaCentavoPlural;
      })();
  }

  if (data.enteros == 0)
    return "CERO " + data.letrasMonedaPlural + " " + data.letrasCentavos;
  if (data.enteros == 1)
    return (
      Millones(data.enteros) +
      " " +
      data.letrasMonedaSingular +
      " " +
      data.letrasCentavos
    );
  else
    return (
      Millones(data.enteros) +
      " " +
      data.letrasMonedaPlural +
      " " +
      data.letrasCentavos
    );
} //NumeroALetras()

export function recortarHoraAgregarTexto(hora) {
  //console.log(hora);
  let nuevaHora = hora?.slice(0, 5);
  nuevaHora += " Hrs";
  //console.log(nuevaHora);
  return nuevaHora;
}

export function getTiempoFromMinutosToHoras(minutos) {
  if (parseInt(minutos) < 60) return minutos + " Minutos";
  if (parseInt(minutos) >= 60) {
    let min = minutos % 60;
    let horas = (minutos - min) / 60;
    return (
      Math.round(horas) +
      " Hrs " +
      (min > 0 ? " " + Math.round(min) + " Min" : "")
    );
  }
}

export function getPorcentaje(porcentaje = "") {
  let porciento = porcentaje.toString();
  let clase = "info";
  if (porciento <= 20) {
    return (clase = "bg-warning");
  } else if (porciento <= 50) {
    return (clase = "bg-info");
  } else if (porciento <= 70) {
    return (clase = "bg-primary");
  } else if (porciento == 100) {
    return (clase = "bg-success");
  }
}
export function getPorcentaje1(porcentaje = "") {
  let porciento = porcentaje.toString();
  let clase = "info";
  if (porciento <= 20) {
    return (clase = "bg-dark");
  } else if (porciento <= 50) {
    return (clase = "bg-dark");
  } else if (porciento <= 70) {
    return (clase = "bg-dark");
  } else if (porciento == 100) {
    return (clase = "bg-dark");
  }
}

export function firstLeterMayus(miOracion) {
  //console.log(miOracion);
  miOracion = miOracion ?? "";
  miOracion = miOracion.toLowerCase();
  const palabras = miOracion.split(" ");
  for (let i = 0; i < palabras.length; i++) {
    palabras[i] =
      palabras[i][0] == undefined || palabras[i][0] == ""
        ? ""
        : palabras[i][0].toUpperCase() + palabras[i].substr(1);
  }
  return palabras.join(" ");
}

export function format2decimales(valu) {
  return valu.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatMoneda(number) {
  const precio = number;
  const precioFormateado = precio.toLocaleString("es-MX", {
    style: "currency",
    currency: "MXN",
  });
  return precioFormateado;
}

export function avatarLetters(texto = "") {
  try {
    const palabras = texto.toLowerCase().split(" ");
    const palabrasExcluidas = [
      "de",
      "los",
      "al",
      "la",
      "el",
      "las",
      "y",
      "con",
      "por",
      "para",
      "a",
      "en",
    ];

    let palabrasIncluidas = palabras
      .filter((palabra) => !palabrasExcluidas.includes(palabra))
      .slice(0, 2);
    //console.log(palabrasIncluidas);
    let inicial1 = palabrasIncluidas[0].charAt(0);
    let inicial2 =
      palabrasIncluidas.length > 1
        ? palabrasIncluidas[1].charAt(0)
        : palabrasIncluidas[0].charAt(1);
    if (texto.length === 1) {
      return inicial1.toUpperCase();
    }
    return inicial1.toUpperCase() + inicial2.toUpperCase();
  } catch {
    return "0";
  }
}

export function getDaysFromHours(horas = 0) {
  return horas / 24;
}

export function obtenerMetodoPagoMasAlto(metodos_pago) {
  let metodoPagoMasAlto = "";
  let valorMasAlto = 0;

  // Recorremos cada propiedad del objeto y comparamos su valor con el valor más alto actual
  for (const metodoPago in metodos_pago) {
    const valorActual = metodos_pago[metodoPago];
    if (valorActual > valorMasAlto) {
      valorMasAlto = valorActual;
      metodoPagoMasAlto = metodoPago;
    }
  }

  return metodoPagoMasAlto;
}

export const getMachineId = () => {
  let machineId = localStorage.getItem("MachineId");

  if (!machineId) {
    machineId = crypto.randomUUID();
    localStorage.setItem("MachineId", machineId);
  }

  //console.log("MAQUINA: ", machineId)
  return machineId;
};

export const caracteresRestantes = (tam, max) => {
  const total = max - Number(tam);

  if (Number(tam) == 0) {
    return "";
  } else {
    return `(${total})`;
  }
};

export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};
