import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  Avatar,
  Typography,
} from "@mui/material";
//import Textarea from '@mui/joy/Textarea';
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { guardarCategoria, getInfoCategoria, getSucursalesByProd } from "../Funciones/Categorias";
import ModalCargarImagen from "../../../../../Lib/Imagen/Componentes/ModalCargarImagen";
import { IMG } from "../../../../../Lib/Funciones Generales/Constantes";
import AccEditarImagen from "./../../../../assets/EditarImagen.svg";
import Switch from "../../../../includes/Switch";

const MySwal = withReactContent(Swal);

const Categoria = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    setValues({ ...Values, img: img });
  };

  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    descripcion: "",
    is_ecommerce: false,
    img: AccEditarImagen,
  });
  const [Errores, setErrores] = useState({
    nombre: false,
    descripcion: false,
  });

  const [isAll, setIsAll] = useState(false);
  const [sucursales, setSucursales] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    console.log("Nombre", target.name, "Valor", target.value);
    setValues({
      ...Values,
      [target.name]: target.value,
    });
    setErrores({
      ...Errores,
      [target.name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoCategoria(id)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
    } else {
      setIsLoading(false);
    }
    getSucursalesByProd(id == undefined ? 0 : id)
      .then((resp) => {
        if (resp?.err?.length == 0) {
          setSucursales([]);
        } else {
          setSucursales(JSON.parse(resp.data));
        }
      })
      .catch((resp) => {
        setSucursales([]);
      });
  }, []);

  const GuardarCategoria = () => {
    setIsGuardando(true);
    guardarCategoria(Values, Errores, setErrores, sucursales)
      .then((data) => {
        //console.log(data);
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  const img_url = Values.img == AccEditarImagen ? AccEditarImagen : Values.img;

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Categorías
      </Button>
      <Card elevation={3}>
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar categoría" : "Nueva categoría"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              <Col sm={12} className="d-flex justify-content-end">
                <Switch
                  className="align-self-center"
                  checked={Values.is_ecommerce}
                  name="is_ecommerce"
                  error={Errores.is_ecommerce}
                  onChange={(checked) => {
                    handlInputChange({
                      target: {
                        name: "is_ecommerce",
                        value: checked,
                      },
                    });
                  }}
                />
                <label className="mt-2">Es E-Commerce</label>
              </Col>

              <Col sm={12} md={2}>
                <div className="d-flex justify-content-center">
                  <Avatar
                    className="shadow"
                    sx={{ width: 120, height: 120 }}
                    src={img_url}
                  />
                </div>
                <strong
                  className="w-100 text-center px-0 d-flex justify-content-center"
                  style={{ color: "#3ABE88", cursor: "pointer" }}
                  onClick={handleOpenModal}
                >
                  Editar foto
                </strong>
              </Col>
              <Col sm={12} md={5} className="p-3 mt-3">
                <TextField
                  fullWidth
                  name="nombre"
                  label="Nombre"
                  variant="outlined"
                  value={Values.nombre}
                  error={Errores.nombre}
                  helperText={Errores.nombre ? "Nombre no valido" : ""}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={12} md={5} className="p-3 mt-3">
                <TextField
                  multiline
                  fullWidth
                  name="descripcion"
                  label="Descripción"
                  maxRows={3}
                  inputProps={{ maxLength: 249 }}
                  value={Values.descripcion}
                  error={Errores.descripcion}
                  placeholder="Descripción"
                  helperText={
                    Errores.descripcion ? "Descripción no valida" : ""
                  }
                  onChange={handlInputChange}
                  style={{ width: "100%", height: "80px" }}
                />
              </Col>

              <div className="row">
                <div className="col-12 col-md-7">
                  <h6
                    className="mt-3"
                    lassName="font-Avenir mt-0 p-0"
                    style={{ fontSize: "13px" }}
                  >
                    Al activar las sucursales, éstas podran vender este prodcuto
                    desde el punto de venta
                  </h6>
                </div>

                <div className="col-12 col-md-5 text-end ">
                  <Typography className="w-100 ">
                    {"Seleccionar todos"}
                    <Switch
                      tituloActivo="Desactivar"
                      tituloInactivo="Activar"
                      id={0}
                      checked={isAll}
                      onChange={(checked) => {
                        const resultado = sucursales.map((objeto) => {
                          return { ...objeto, ACTIVO: checked ? 1 : 0 };
                        });
                        setSucursales([...resultado]);
                        setIsAll(checked);
                      }}
                    />
                  </Typography>
                </div>
                <div className="col-12 mt-2 border-bottom"></div>

                <div className="col-12 col-md-6 col-lg-4">
                  {sucursales.map((prod, index) => {
                    if (index % 3 === 0) {
                      return (
                        <div className="d-flex justify-content-between border-bottom">
                          <Typography>{prod.DESCRIPCION}</Typography>
                          <Switch
                            checked={prod.ACTIVO == 1}
                            tituloActivo="Desactivar"
                            tituloInactivo="Activar"
                            id={prod.ID}
                            onChange={(checked) => {
                              const indice = sucursales.findIndex(
                                (objeto) => objeto.ID === prod.ID
                              );
                              if (indice !== -1) {
                                sucursales[indice].ACTIVO = checked ? 1 : 0;
                                setSucursales([...sucursales]);
                              }
                            }}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  {sucursales.map((prod, index) => {
                    if (index % 3 === 1) {
                      return (
                        <div className="d-flex justify-content-between border-bottom">
                          <Typography>{prod.DESCRIPCION}</Typography>
                          <Switch
                            checked={prod.ACTIVO == 1}
                            tituloActivo="Desactivar"
                            tituloInactivo="Activar"
                            id={prod.ID}
                            onChange={(checked) => {
                              const indice = sucursales.findIndex(
                                (objeto) => objeto.ID === prod.ID
                              );
                              if (indice !== -1) {
                                sucursales[indice].ACTIVO = checked ? 1 : 0;
                                setSucursales([...sucursales]);
                              }
                            }}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  {sucursales.map((prod, index) => {
                    if (index % 3 === 2) {
                      return (
                        <div className="d-flex justify-content-between border-bottom">
                          <Typography>{prod.DESCRIPCION}</Typography>
                          <Switch
                            checked={prod.ACTIVO == 1}
                            tituloActivo="Desactivar"
                            tituloInactivo="Activar"
                            id={prod.ID}
                            onChange={(checked) => {
                              const indice = sucursales.findIndex(
                                (objeto) => objeto.ID === prod.ID
                              );
                              if (indice !== -1) {
                                sucursales[indice].ACTIVO = checked ? 1 : 0;
                                setSucursales([...sucursales]);
                              }
                            }}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarCategoria()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>
      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={IMG.TIPO_CATEGORIAS}
          Titulo="Cargar imagen de usuario"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
        />
      )}
    </>
  );
};

export default Categoria;
