import {
  Alert,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  Tabs,
  Box,
  Tab,
  TextareaAutosize,
  Grid,
  Typography,
  FormGroup,
  Divider,
  MenuItem,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import Switch from "../../../../includes/Switch";
import ProductoServicioSat from "../../../../../Lib/SAT/Componentes/ProductoServicio";
import UnidadMedidaSat from "../../../../../Lib/SAT/Componentes/UnidadMedidad";
import {
  Monedas,
  Impuestos,
} from "../../../../../Lib/Funciones Generales/Constantes";

export default function TabSat({
  handlInputChange = () => {},
  Values = {},
  Errores = {},
}) {
  return (
    <>
      <Col sm={12} className="p-3 d-flex justify-content-between">
        <div className="w-100">
          <h6 className="font-AvenirMedium m-0 p-0">
            Utilizar funciones de facturacion
          </h6>
          <h6 lassName="font-Avenir mt-0 p-0" style={{ fontSize: "13px" }}>
            Sí activas esta opción podras cofigurar la información del producto
            requerida para facturar
          </h6>
        </div>
        <FormGroup>
          <div className="d-flex justify-content-center">
            <p
              className="my-0 algin-self-center font-Avenir mt-2 text-center"
              style={{ fontSize: "13px" }}
            >
              {Values.isFacturable ? "Activo" : "Desactivado"}
            </p>

            <Switch
              className="algin-self-center"
              checked={Values.isFacturable}
              name="isFacturable"
              error={Errores.isFacturable}
              onChange={(checked) => {
                handlInputChange({
                  target: {
                    name: "isFacturable",
                    value: checked,
                  },
                });
              }}
            />
          </div>
        </FormGroup>
      </Col>
      {Values.isFacturable && (
        <>
          {/* <Col sm={12} md={6} className="p-3">
            <TextField
              fullWidth
              name="unidad_medida"
              label="Unidad de medida"
              variant="outlined"
              value={Values.unidad_medida}
              error={Errores.unidad_medida}
              //type="number"
              onChange={handlInputChange}
            />
          </Col> */}
          <Col sm={12} md={6} className="p-3">
            <UnidadMedidaSat
              Value={Values.clave_unidad_medida_sat}
              Error={Errores.clave_unidad_medida_sat}
              handlInputChange={handlInputChange}
            />
          </Col>
          <Col sm={12} md={6} className="p-3">
            <ProductoServicioSat
              Value={Values.clave_codigo_prod_sat}
              Error={Errores.clave_codigo_prod_sat}
              handlInputChange={handlInputChange}
            />
          </Col>
          <Col sm={12} md={6} className="p-3">
            <TextField
              fullWidth
              select
              label="Impuestos"
              name="iva"
              helperText={Errores.iva ? "Impuesto no valido" : ""}
              value={Values.iva}
              error={Errores.iva}
              onChange={handlInputChange}
            >
              {Impuestos.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <Col sm={12} md={6} className="p-3">
            <TextField
              fullWidth
              id="Moneda"
              select
              label="Moneda"
              name="moneda"
              helperText={Errores.moneda ? "Moneda no valida" : ""}
              value={Values.moneda}
              error={Errores.moneda}
              onChange={handlInputChange}
            >
              {Monedas.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Col>
        </>
      )}
      <Divider light />
    </>
  );
}
