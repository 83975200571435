import {
    Alert,
    Button,
    Card,
    CardContent,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Snackbar,
    Stack,
    TextField,
} from "@mui/material";
//import Textarea from '@mui/joy/Textarea';
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { guardarZona, getInfoZona, getGeocercaCadaZona, getListaPuntos } from "../Funciones/Zonas";
import HelperError from "../../../../includes/HelperError";

import Mapa from "../Funciones/Mapa";
import { GoogleMap, Marker, Polygon, Polyline } from '@react-google-maps/api';
import "../../../../../Lib/Funciones Generales/Prototipos";
import ModalZonasEnvio from "./ModalZonasEnvio";

const containerStyle = {
    width: '100%',
    height: '30rem'
};
const MySwal = withReactContent(Swal);
// import ZonasGeocerca from "./ZonasGeocerca";


const Zona = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [IsLoading, setIsLoading] = useState(true);
    const [IsGuardando, setIsGuardando] = useState(false);
    const [open, setOpen] = useState(false);
    const [mensaje, setMensaje] = useState("");

    const [ListaZonas, setListaZonas] = useState([]);
    const [ListaPuntos, setListaPuntos] = useState([]);

    const [Values, setValues] = useState({
        id: id ?? "0",
        nombre: "",
        precio_de_envio: 0,
        descripcion: "",
        geocerca: [],
    });
    const [Errores, setErrores] = useState({
        nombre: false,
        descripcion: false,
    });






    const zoomMap = 13
    const centerMap = { lat: 20.661724, lng: -103.349921 }
    const clickMapa = (lat, lng) => {
        crearMarker({ lat, lng })
    }
    const moverMarker = (marker, index) => {
        const { lat, lng } = marker.latLng
        actualizarMarker(index, lat(), lng())
    }
    const {
        onLoad,
        onUnmount,
        onMapClick,
        crearMarker,
        actualizarMarker,
        eliminarMarker,
        isLoaded,
        Zoom,
        ZoomInit,
        Map
    } = Mapa({ clickMapa, centerMap, zoomMap, ListaPuntos, setListaPuntos })

    if (ZoomInit) {
        console.log("GEOCERCA: ", ListaPuntos)
    }


    const handleClose = () => {
        setOpen(false);
    };

    const handlInputChange = ({ target }) => {
        let Name = target.name;
        let Value = target.value;
        if (Name == "precio_de_envio") {
            Value = Value.toDecimal();
        }
        setValues({
            ...Values,
            [Name]: Value,
        });
        setErrores({
            ...Errores,
            [Name]: false,
        });
    };

    const GetCoordenadasPorZona = () => {
        getGeocercaCadaZona()
            .then((resp) => {
                const Lista = [];
                if (Array.isArray(resp)) {
                    resp.map((zona) => {
                        if (zona.ID != Values.id) {
                            const LISTA_PUNTOS = getListaPuntos(zona.LISTA_PUNTOS)
                            Lista.push({ ...zona, LISTA_PUNTOS })
                        }
                    })
                }
                setListaZonas(Lista)
            })
            .catch((resp) => {

            });
    }

    useEffect(() => {
        setIsLoading(true);

        if (id != undefined) {
            getInfoZona(id)
                .then((resp) => {
                    if (resp?.err?.length == 0) {
                        MySwal.fire({
                            title: "Error",
                            html: resp.mensaje,
                            icon: "error",
                            confirmButtonColor: "#3ABE88",
                            showConfirmButton: true,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            allowOutsideClick: false,
                        }).then(() => {
                            setIsLoading(false);
                            navigate(-1);
                        });
                    } else {
                        resp.geocerca.pop()
                        setValues(resp);
                        setListaPuntos(resp.geocerca)
                        // console.log("GEOCERCA: ", resp.geocerca)
                        setIsLoading(false);


                    }
                })
                .catch((resp) => {
                    MySwal.fire({
                        title: "Error",
                        html: resp.mensaje,
                        icon: "error",
                        confirmButtonColor: "#3ABE88",
                        showConfirmButton: true,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        allowOutsideClick: false,
                    }).then(() => {
                        setIsLoading(false);
                        navigate(-1);
                    });
                });
        } else {
            setIsLoading(false);
        }
        window.indexPunto = 0;
    }, []);

    useEffect(() => {
        if (!IsLoading && Map) {
            GetCoordenadasPorZona()
        }
    }, [IsLoading, Map])

    console.log("------------ FINAL ------------", ListaZonas)

    const GuardarZona = () => {
        setIsGuardando(true);
        guardarZona(Values, Errores, setErrores, ListaPuntos)
            .then((data) => {
                console.log(data);
                if (data.codigo == "200") {
                    setIsGuardando(false);
                    MySwal.fire({
                        title: "Correcto",
                        html: data.mensaje,
                        icon: "success",
                        confirmButtoColor: "#3ABE88",
                        showConfirmButton: false,
                        timer: 1700,
                    }).then((result) => {
                        navigate(-1);
                    });
                } else {
                    setMensaje(data.mensaje);
                    setOpen(true);
                    setIsGuardando(false);
                }
            })
            .catch((data) => {
                setMensaje(data.mensaje);
                setOpen(true);
                setIsGuardando(false);
            });
    };

    const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
        ({ theme }) => ({
            "& .MuiSnackbar-root": {
                top: theme.spacing(15),
            },
        })
    );

    return (
        <>
            <StyledSnackbar
                direction="right"
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                    {mensaje}
                </Alert>
            </StyledSnackbar>
            <Button
                onClick={() => navigate(-1)}
                className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
                variant="text"
            >
                <ArrowBackIcon className="me-3" />
                Zonas
            </Button>
            <Card elevation={3}>
                <CardContent className="p-0">
                    <div className="p-3">
                        <h6 className="mb-0 text-left font-AvenirBold">
                            {id ? "Editar zona" : "Nueva zona"}
                        </h6>
                    </div>
                    <hr
                        style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
                        className="m-0 p-0"
                    />

                    {IsLoading ? (
                        <Row className="px-3">
                            <Col sm={12} className="p-3">
                                <Loading />
                            </Col>
                        </Row>
                    ) : (
                        <Row className="p-3">
                            <Col sm={12} md={6} className="p-3">
                                <TextField
                                    fullWidth
                                    name="nombre"
                                    label="Nombre"
                                    variant="outlined"
                                    value={Values.nombre}
                                    error={Errores.nombre}
                                    helperText={Errores.nombre ? "Nombre no valido" : ""}
                                    onChange={handlInputChange}
                                />
                            </Col>

                            <Col sm={12} md={6} className="p-3">
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="outlined-adornment-amount">
                                        Precio de envio
                                    </InputLabel>
                                    <OutlinedInput
                                        startAdornment={
                                            <InputAdornment position="start">$</InputAdornment>
                                        }
                                        name="precio_de_envio"
                                        value={Values.precio_de_envio}
                                        error={Errores.precio_de_envio}
                                        label="Precio de envio"
                                        placeholder="Precio de envio"
                                        onChange={handlInputChange}
                                    />
                                </FormControl>
                                {
                                    <HelperError Error={Errores.precio_de_envio} Mensaje={"Precio de envio no valido"} />
                                }
                            </Col>

                            <Col sm={12} className="p-3">
                                <TextField
                                    multiline
                                    fullWidth
                                    name="descripcion"
                                    label="Descripción"
                                    maxRows={3}
                                    inputProps={{ maxLength: 249 }}
                                    value={Values.descripcion}
                                    error={Errores.descripcion}
                                    placeholder="Descripción"
                                    helperText={
                                        Errores.descripcion ? "Descripción no valida" : ""
                                    }
                                    onChange={handlInputChange}
                                    style={{ width: "100%", height: "80px" }}
                                />
                            </Col>





                            {
                                !IsLoading && isLoaded && (

                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        onLoad={onLoad}
                                        onUnmount={onUnmount}
                                        onClick={onMapClick}
                                        zoom={Zoom}
                                    >
                                        {
                                            ZoomInit && Map && (
                                                <>
                                                    {
                                                        ListaPuntos.map((punto, index) => (
                                                            <Marker
                                                                onDragEnd={marker => moverMarker(marker, index)}
                                                                onDblClick={() => eliminarMarker(index)}
                                                                draggable={true}
                                                                position={{
                                                                    lat: punto.lat,
                                                                    lng: punto.lng,
                                                                }}
                                                                icon={{
                                                                    path: window.google.maps.SymbolPath.CIRCLE,
                                                                    scale: 4,
                                                                }}
                                                            />
                                                        ))
                                                    }
                                                    <Polyline
                                                        path={ListaPuntos}
                                                        onLoad={() => {
                                                            console.log("LINEAS CARGADAS")
                                                        }}
                                                        options={{
                                                            strokeColor: "#202124",
                                                            strokeOpacity: 0.5,
                                                            strokeWeight: 2,
                                                        }}
                                                    />

                                                    {
                                                        ListaZonas.map(zona => (
                                                            <Polygon
                                                                path={zona.LISTA_PUNTOS}
                                                                options={{
                                                                    fillColor: "#202124",
                                                                    fillOpacity: 0.25,
                                                                    strokeColor: "#202124",
                                                                    strokeOpacity: 0.5,
                                                                    strokeWeight: 2,
                                                                }}
                                                            />
                                                        ))
                                                    }

                                                    )
                                                </>
                                            )
                                        }

                                    </GoogleMap>
                                )
                            }





                        </Row>
                    )}
                    <Stack
                        className="p-3"
                        spacing={2}
                        direction={{ xs: "column", sm: "row" }}
                        style={{}}
                    >
                        <LoadingButton
                            loading={IsGuardando}
                            loadingPosition="start"
                            disabled={IsLoading}
                            onClick={() => GuardarZona()}
                            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                            variant="contained"
                            xs={{ with: "100$" }}
                        >
                            <span className={IsGuardando ? "px-4" : "px-2"}>
                                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
                            </span>
                        </LoadingButton>
                        <Button
                            onClick={() => navigate(-1)}
                            className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                            variant="outlined"
                        >
                            Cancelar
                        </Button>
                    </Stack>
                </CardContent>
            </Card>



        </>
    );
};

export default Zona;
