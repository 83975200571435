import {
  Alert,
  Tabs,
  Box,
  Tab,
  Avatar,
  Button,
  Card,
  CardContent,
  Snackbar,
  Stack,
  TextField,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Divider,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { guardar, getInfoEmpresa } from "../Funciones/Empresas";
import Loading from "../../../../includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import RegimenFiscal from "../../../../../Lib/SAT/Componentes/RegimenFiscal";
import ModalCargarImagen from "../../../../../Lib/Imagen/Componentes/ModalCargarImagen";
import AccEditarImagen from "../../../../assets/EditarImagen.svg";
import { IMG } from "../../../../../Lib/Funciones Generales/Constantes";
import "../../../../../Lib/Funciones Generales/Prototipos";
import Certificados from "./Certificados";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

const Empresa = () => {
  //#region Variables de estado
  const [openModal, setOpenModal] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    correo: "",
    telefono: "",
    img: AccEditarImagen,
    direccion: "",
    rfc: "",
    razon_social: "",
    regimen_fiscal: "",
    cp: "",
    comision_fija: "",
    porcentaje_comision: "",
    cer_key: "",
    cer_cer: ""
  });
  const [Errores, setErrores] = useState({
    nombre: false,
    correo: false,
    telefono: false,
    direccion: false,
    rfc: false,
    razon_social: false,
    regimen: false,
    cp: false,
    comision_fija: false,
    porcentaje_comision: false,
  });
  const [tab, setTab] = React.useState(0);
  //#endregion

  //#region Handles
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    setValues({ ...Values, img: img });
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    if (Name == "cp" || Name == "telefono") {
      NewValue = { ...NewValue, [Name]: Value.toNumber() };
    }

    if (Name == "comision_fija" || Name == "porcentaje_comision") {
      NewValue = { ...NewValue, [Name]: Value.toDecimal() };
    }

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };
  //#endregion

  //#region Funciones
  const GuardarEmpresa = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        // alert(data.mensaje);
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };
  //#endregion

  //#region Effects

  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoEmpresa(id, AccEditarImagen)
        .then((resp) => {
          //console.log(resp);
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
          //setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);
  //#endregion

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const img_url = Values.img == AccEditarImagen ? AccEditarImagen : Values.img;

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Empresas
      </Button>
      <Box className=" mb-2">
        <Tabs
          TabIndicatorProps={{
            bgcolor: "#3abe88",
            style: {
              backgroundColor: "#3abe88",
            },
          }}
          value={tab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="#212529"
          indicatorColor=""
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Datos generales" className="font-AvenirBold" />
          <Tab label="Certificados" className="font-AvenirBold" />
        </Tabs>
      </Box>

      <Card elevation={3} className="mb-4">
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id ? "Editar empresa" : "Editar empresa"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />

          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <Row className="p-3">
              {tab == 0 ? (
                <>
                  <Col
                    sm={12}
                    md={12}
                    lg={4}
                    className="d-flex flex-wrap align-items-center justify-content-center"
                  >
                    <Avatar
                      className="shadow"
                      sx={{ width: 120, height: 120 }}
                      src={img_url}
                    />
                    <strong
                      className="w-100 text-center px-0 pt-3"
                      style={{ color: "#3ABE88", cursor: "pointer" }}
                      onClick={handleOpenModal}
                    >
                      Editar foto
                    </strong>
                  </Col>
                  <Col sm={12} md={4} className="p-3 mt-5">
                    <TextField
                      fullWidth
                      name="nombre"
                      label="Nombre"
                      variant="outlined"
                      value={Values.nombre}
                      error={Errores.nombre}
                      helperText={Errores.nombre ? "Nombre no valido" : ""}
                      onChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} md={4} className="p-3 mt-5">
                    <TextField
                      fullWidth
                      name="correo"
                      label="Correo electrónico"
                      variant="outlined"
                      helperText={Errores.correo ? "Correo incorrecto" : ""}
                      value={Values.correo}
                      error={Errores.correo}
                      onChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} md={4} className="p-3">
                    <TextField
                      fullWidth
                      name="telefono"
                      label="Teléfono"
                      variant="outlined"
                      inputProps={{ maxLength: 10 }}
                      helperText={Errores.telefono ? "Teléfono inválido" : ""}
                      value={Values.telefono}
                      error={Errores.telefono}
                      onChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} md={4} className="p-3">
                    <TextField
                      fullWidth
                      name="direccion"
                      label="Dirección"
                      variant="outlined"
                      helperText={
                        Errores.direccion ? "Dirección no valida" : ""
                      }
                      value={Values.direccion}
                      error={Errores.direccion}
                      onChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} md={4} className="p-3">
                    <TextField
                      fullWidth
                      name="rfc"
                      label="RFC"
                      variant="outlined"
                      helperText={Errores.rfc ? "RFC invalido" : ""}
                      value={Values.rfc}
                      error={Errores.rfc}
                      onChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} md={4} className="p-3">
                    <TextField
                      fullWidth
                      name="razon_social"
                      label="Razón social"
                      variant="outlined"
                      helperText={
                        Errores.razon_social ? "Razón social invalida" : ""
                      }
                      value={Values.razon_social}
                      error={Errores.razon_social}
                      onChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} md={4} className="p-3">
                    <RegimenFiscal
                      Value={Values.regimen_fiscal}
                      Error={Errores.regimen_fiscal}
                      handlInputChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} md={4} className="p-3">
                    <TextField
                      fullWidth
                      name="cp"
                      inputProps={{ maxLength: 5 }}
                      label="Código postal"
                      variant="outlined"
                      helperText={Errores.cp ? "Código postal invalido" : ""}
                      value={Values.cp}
                      error={Errores.cp}
                      onChange={handlInputChange}
                    />
                  </Col>
                  <Col sm={12} md={12} className="p-3">
                    <TextField
                      name="url_pagina"
                      disabled
                      fullWidth
                      label="URL de la pagina"
                      variant="outlined"
                      value={Values.url_pagina}
                    />
                  </Col>
                  {/*
                  <code>
                    
                    <pre>{JSON.stringify(Values, null, 2)}</pre>
                    
                  </code>
                    */}
                  <br />
                  <br />
                  <br />
                  <br />
                  <Divider light />
                  <br />
                  <div className="w-100">
                    <h6 className="font-AvenirMedium m-0 p-0">
                      Comisiones por transacción exitosa
                    </h6>
                    <h6
                      lassName="font-Avenir mt-0 p-0"
                      style={{ fontSize: "13px" }}
                    >
                      Comisiones establecidas para una previsualización de
                      transacciones con tarjeta de crédito o débito
                    </h6>
                  </div>
                  <Col sm={12} md={3} className="p-3">
                    <FormControl fullWidth>
                      <InputLabel htmlFor="outlined-adornment-amount">
                        Comision conekta
                      </InputLabel>
                      <OutlinedInput
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        name="comision_fija"
                        helperText={
                          Errores.comision_fija ? "Comision fija no valido" : ""
                        }
                        value={Values.comision_fija}
                        error={Errores.comision_fija}
                        label="Comision fija"
                        placeholder="Comision fija"
                        onChange={handlInputChange}
                      />
                    </FormControl>
                  </Col>

                  <Col sm={12} md={3} className="p-3">
                    <FormControl fullWidth>
                      <InputLabel htmlFor="outlined-adornment-amount">
                        Comisión bancaria
                      </InputLabel>
                      <OutlinedInput
                        startAdornment={
                          <InputAdornment position="start">%</InputAdornment>
                        }
                        name="porcentaje_comision"
                        helperText={
                          Errores.porcentaje_comision
                            ? "Porcentaje Comisión no valida"
                            : ""
                        }
                        value={Values.porcentaje_comision}
                        error={Errores.porcentaje_comision}
                        label="Comisión bancaria"
                        placeholder="Comisión bancaria"
                        onChange={handlInputChange}
                      />
                    </FormControl>
                  </Col>

                  <Col sm={12} md={3} className="p-3">
                    <FormControl fullWidth>
                      <InputLabel htmlFor="outlined-adornment-amount">
                        I.V.A
                      </InputLabel>
                      <OutlinedInput
                        startAdornment={
                          <InputAdornment position="start">%</InputAdornment>
                        }
                        name=""
                        value={"16"}
                        label="I.V.A"
                        placeholder="I.V.A"
                        onChange={handlInputChange}
                        disabled
                      />
                    </FormControl>
                  </Col>
                </>
              ) : (
                <Certificados
                  Errores={Errores}
                  Values={Values}
                  handlInputChange={handlInputChange}
                  id={Values.id}
                  RFC={Values.rfc}
                  cer_string={Values.cer_cer}
                  key_string={Values.cer_key}
                />
              )}
            </Row>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarEmpresa()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
          </Stack>
        </CardContent>
      </Card>

      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={IMG.EMPRESA}
          Titulo="Cargar imagen de empleado"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
        />
      )}
    </>
  );
};

export default Empresa;
{
  /* <code>
<pre>
  {
    JSON.stringify(Values, null, 2)
  }
</pre>
</code> */
}
