import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getOpciones } from "../Funciones/Categorias";

const Categoria = ({
  Value,
  Error,
  handlInputChange,
  Name = "id_categoria",
  Opciones = [],
}) => {
  const opcionInicial = { ID: 0, DESCRIPCCION: "Categoría" };
  const [ValorFiltrado, setFiltro] = useState();
  const [Categorias, setCategorias] = useState({
    cargado: false,
    lista: [opcionInicial],
  });

  useEffect(() => {
    getOpciones()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(list);
        if (list.length != 0) {
          setCategorias({ cargado: true, lista: [...Opciones, ...list] });
        } else {
          setCategorias({
            cargado: true,
            lista: [{ ID: 0, DESCRIPCCION: "Sin categorías disponibles" }],
          });
        }
      })
      .catch((resp) => {
        setCategorias({
          cargado: true,
          lista: [{ ID: 0, DESCRIPCCION: "Sin categorías disponibles" }],
        });
      });
  }, []);

  const getOpcion = () => {
    const opcion = Categorias.lista.find((x) => x.ID == Value);
    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };

  return Categorias.cargado ? (
    <Autocomplete
      fullWidth
      defaultValue={getOpcion}
      isOptionEqualToValue={(option, value) => {
        return option.ID == value.ID;
      }}
      inputValue={ValorFiltrado}
      onChange={(e, value) => {
        if (value) {
          handlInputChange({ target: { name: Name, value: String(value.ID) } });
        } else {
          handlInputChange({ target: { name: Name, value: "" } });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={Categorias.lista}
      getOptionLabel={(option) => {
        return option.DESCRIPCCION;
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={Error}
            helperText={Error ? "Selecciona una categoría" : ""}
            label="Categoría"
          />
        );
      }}
    />
  ) : (
    <TextField fullWidth disabled={true} value={"Categoría"} />
  );
};

export default Categoria;
