import {
    Alert,
    Button,
    Card,
    CardContent,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Snackbar,
    Stack,
    TextField,
    Select,
    MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Switch from "../../../../../Includes/Switch";
import { BasicDatePicker } from "../../../../includes/DatePicker";

import { GetDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";
import { ConvertDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";

import HelperError from "../../../../includes/HelperError";
import { guardarCuponera } from "../Funciones/Cupones";

const MySwal = withReactContent(Swal);

export default function Cupon() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [IsLoading, setIsLoading] = useState(true);
    const [IsGuardando, setIsGuardando] = useState(false);
    const [open, setOpen] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [fecha, setFecha] = useState(GetDate());
    const [Values, setValues] = useState({
        id: id ?? "0",
        tipo: false,
        tipoDescuento: "1",
        valor: "",
        numeroCupones: "",
        maxUsos: "",
        codigo: "",
        vigencia: GetDate(),
    });
    const [Errores, setErrores] = useState({
        tipo: false,
        tipoDescuento: false,
        valor: false,
        numeroCupones: false,
        maxUsos: false,
        codigo: false,
        vigencia: false,
    });

    const handleClose = () => {
        setOpen(false);
    };
    const handlInputChange = ({ target }) => {
        let Name = target.name;
        let Value = target.value;
        let NewValue = {
            ...Values,
            [Name]: Value,
        };

        if (Name == "valor") {
            NewValue = { ...NewValue, valor: Value.toNumber() };
        }
        if (Name == "tipo") {
            NewValue = { ...NewValue, tipo: Value };
        }
        if (Name == "numeroCupones") {
            NewValue = { ...NewValue, numeroCupones: Value.toNumber() };
        }
        if (Name == "maxUsos") {
            NewValue = { ...NewValue, maxUsos: Value.toNumber() };
        }

        setValues(NewValue);
        setErrores({
            ...Errores,
            [Name]: false,
        });
    };

    useEffect(() => {
        if (fecha != "") {
            setValues({ ...Values, vigencia: ConvertDate(fecha) });
            setErrores({ ...Errores, vigencia: false });
        }
    }, [fecha]);
    const ErrorFecha =
        Errores.vigencia || Values.vigencia == "" || Values.vigencia == null
            ? true
            : false;

    const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
        ({ theme }) => ({
            "& .MuiSnackbar-root": {
                top: theme.spacing(15),
            },
        })
    );

    const GuardarCuponera = () => {
        setIsGuardando(true);
        guardarCuponera(Values, Errores, setErrores)
            .then((data) => {
                if (data.codigo == "200") {
                    setIsGuardando(false);
                    MySwal.fire({
                        title: "Correcto",
                        html: data.mensaje,
                        icon: "success",
                        confirmButtoColor: "#3ABE88",
                        showConfirmButton: false,
                        timer: 1700,
                    }).then((result) => {
                        console.log("I was closed by the timer");
                        navigate(-1);
                    });
                } else {
                    setMensaje(data.mensaje);
                    setOpen(true);
                    setIsGuardando(false);
                }
            })
            .catch((data) => {
                setMensaje(data.mensaje);
                setOpen(true);
                setIsGuardando(false);
            });
    };

    useEffect(() => {
        setIsLoading(false);
    });

    return (
        <>
            <StyledSnackbar
                direction="right"
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                    {mensaje}
                </Alert>
            </StyledSnackbar>
            <Button
                onClick={() => navigate(-1)}
                className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
                variant="text"
            >
                <ArrowBackIcon className="me-3" />
                Cuponera
            </Button>

            <Card elevation={3}>
                <CardContent className="p-0">
                    <div className="p-3">
                        <h6 className="mb-0 text-left font-AvenirBold">
                            {id ? "Editar cuponera" : "Nueva cuponera"}
                        </h6>
                    </div>
                    <hr
                        style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
                        className="m-0 p-0"
                    />

                    {IsLoading ? (
                        <Row className="px-3">
                            <Col sm={12} className="p-3">
                                <Loading />
                            </Col>
                        </Row>
                    ) : (
                        <Row className="p-3">
                            <Col sm={12} className="p-3 pt-1 text-end">
                                <Switch
                                    checked={Values.tipo}
                                    onChange={(checked) => {
                                        handlInputChange({
                                            target: {
                                                name: "tipo",
                                                value: checked,
                                            },
                                        });
                                    }}
                                    //id={Values.ID}
                                    tituloActivo="Codigo"
                                    tituloInactivo="Cupones"
                                    on
                                />
                                <label>Tipo Cupon/Codigo</label>
                            </Col>
                            {Values.tipo ? (
                                <Col sm={12} md={10}>
                                    <Row>
                                        <Col sm={12} md={4} className="p-3">
                                            <TextField
                                                fullWidth
                                                name="codigo"
                                                label="Codigo"
                                                variant="outlined"
                                                value={Values.codigo}
                                                error={Errores.codigo}
                                                onChange={handlInputChange}
                                            />
                                        </Col>
                                        <Col sm={12} md={4} className="p-3">
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="outlined-adornment-amount">
                                                    Cantidad
                                                </InputLabel>
                                                <OutlinedInput
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            {Values.tipoDescuento == "1" ? "%" : "$"}
                                                        </InputAdornment>
                                                    }
                                                    label="Cantidad"
                                                    placeholder="Cantidad"
                                                    name="valor"
                                                    value={Values.valor}
                                                    error={Errores.valor}
                                                    onChange={handlInputChange}
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col sm={12} md={4} className="p-3">
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="outlined-adornment-amount">
                                                    Tipo Descuento
                                                </InputLabel>
                                                <Select
                                                    name="tipoDescuento"
                                                    value={Values.tipoDescuento}
                                                    label="Tipo Descuento"
                                                    onChange={handlInputChange}
                                                >
                                                    <MenuItem value={"1"}>Porcentaje</MenuItem>
                                                    <MenuItem value={"2"}>Neto</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Col>
                                        <Col sm={12} md={4} className="p-3">
                                            <TextField
                                                fullWidth
                                                name="maxUsos"
                                                label="Maximo Usos"
                                                variant="outlined"
                                                value={Values.maxUsos}
                                                error={Errores.maxUsos}
                                                onChange={handlInputChange}
                                            />
                                        </Col>

                                        <Col sm={12} md={4} className="p-3">
                                            <BasicDatePicker
                                                format="DD/MM/YYYY"
                                                label="Vigencia"
                                                fecha={fecha}
                                                setFecha={setFecha}
                                            />
                                            {
                                                <HelperError
                                                    Error={ErrorFecha}
                                                    Mensaje="Error en la fecha de vigencia"
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            ) : (
                                <Col sm={12} md={10}>
                                    <Row>
                                        <Col sm={12} md={4} className="p-3">
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="outlined-adornment-amount">
                                                    Tipo Descuento
                                                </InputLabel>
                                                <Select
                                                    name="tipoDescuento"
                                                    value={Values.tipoDescuento}
                                                    label="Tipo Descuento"
                                                    onChange={handlInputChange}
                                                >
                                                    <MenuItem value={"1"}>Porcentaje</MenuItem>
                                                    <MenuItem value={"2"}>Neto</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Col>

                                        <Col sm={12} md={4} className="p-3">
                                            <FormControl fullWidth>
                                                <InputLabel htmlFor="outlined-adornment-amount">
                                                    Cantidad
                                                </InputLabel>
                                                <OutlinedInput
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            {Values.tipoDescuento == "1" ? "%" : "$"}
                                                        </InputAdornment>
                                                    }
                                                    label="Cantidad"
                                                    placeholder="Cantidad"
                                                    name="valor"
                                                    value={Values.valor}
                                                    error={Errores.valor}
                                                    onChange={handlInputChange}
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col sm={12} md={4} className="p-3">
                                            <TextField
                                                fullWidth
                                                name="numeroCupones"
                                                label="Numero Cupones"
                                                variant="outlined"
                                                onChange={handlInputChange}
                                                value={Values.numeroCupones}
                                                error={Errores.numeroCupones}
                                            />
                                        </Col>
                                        <Col sm={12} md={4} className="p-3">
                                            <BasicDatePicker
                                                format="DD/MM/YYYY"
                                                label="Vigencia"
                                                fecha={fecha}
                                                setFecha={setFecha}
                                            />
                                            {
                                                <HelperError
                                                    Error={ErrorFecha}
                                                    Mensaje="Error en la fecha de vigencia"
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    )}
                    <Stack
                        className="p-3"
                        spacing={2}
                        direction={{ xs: "column", sm: "row" }}
                        style={{}}
                    >
                        <LoadingButton
                            loading={IsGuardando}
                            loadingPosition="start"
                            disabled={IsLoading}
                            onClick={() => GuardarCuponera()}
                            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                            variant="contained"
                            xs={{ with: "100$" }}
                        >
                            <span className={IsGuardando ? "px-4" : "px-2"}>
                                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
                            </span>
                        </LoadingButton>
                        <Button
                            onClick={() => navigate(-1)}
                            className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                            variant="outlined"
                        >
                            Cancelar
                        </Button>
                    </Stack>
                </CardContent>
            </Card>
        </>
    );
}
