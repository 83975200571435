import React from "react";
import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import {
  Avatar,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { avatarLetters } from "../../../../../Lib/Funciones Generales/Generales";

import { getOpcionesSucursal } from "../../../Catalogos/Sucursales/Funciones/Sucursales";

export default function SucursalSelector({
  sucursalSelector,
  setSucursalSelector = () => {},
  setSucursalName = () => {},
}) {
  const [sucursales, setSucursales] = useState([]);
  const cargarOps = () => {
    getOpcionesSucursal()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(list);
        setSucursales(list);
      })
      .catch((resp) => {
        console.log(resp);
      });
  };
  useEffect(() => {
    //console.log("cambio de sucursal");
    cargarOps();
  }, []);
  return (
    <>
      <div className="w-100 h-100 d-flex flex-wrap justify-content-center align-content-center">
        {sucursales?.map((row) => (
          <div
            className=""
            onClick={() => {
              setSucursalName(row.NOMBRE);
              setSucursalSelector(row.ID);
            }}
          >
            <div
              style={{}}
              className="card-sucursal card p-3 m-3 shadow-sm d-flex justify-content-center  flex-column "
            >
              <center>
                <Avatar
                  sx={{ bgcolor: "#006064" }}
                  style={{ width: "60px", height: "60px" }}
                >
                  {avatarLetters(row.NOMBRE)}
                </Avatar>
              </center>
              <p className="font-Avenir fw-bold parrafo2lineastruncate text-center my-1">
                {row.NOMBRE}
              </p>
              <p className="font-font-AvenirMedium fw-bold text-truncate text-center my-2">
                {row.DIRECCION}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
