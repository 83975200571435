import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import ExportarDanger from "../../../../assets/ExportarDanger.svg";
import ExportarSucess from "../../../../assets/ExportarSucess.svg";
import { Col, Row } from "react-bootstrap";
import TableProductos from "./Tabla";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import SelectOrdenamiento from "../../../../includes/SelectOrdenamiento";
import Buscador from "../../../../includes/Buscador";
import dayjs from "dayjs";
import { getLista } from "../Funciones/Productos";
import Select from "../../../../../Includes/Select";

const addData = (ID, FECHA, CODIGO, DESCRIPCCION, RENTABLE, ACTIVO) => {
  return { ID, FECHA, CODIGO, DESCRIPCCION, RENTABLE, ACTIVO };
};

function fechaAleatoriaAntesDeHoy() {
  var hoy = new Date(); // fecha actual
  var anioActual = hoy.getFullYear(); // obtener el año actual
  var primerDiaAnio = new Date(anioActual, 0, 1); // fecha del primer día del año actual
  var tiempoHoy = hoy.getTime(); // tiempo actual en milisegundos
  var tiempoPrimerDiaAnio = primerDiaAnio.getTime(); // tiempo del primer día del año actual en milisegundos
  var milisegundosAtras = Math.floor(
    Math.random() * (tiempoHoy - tiempoPrimerDiaAnio)
  ); // número aleatorio de milisegundos entre el primer día del año y el día actual
  var fechaAleatoria = new Date(tiempoHoy - milisegundosAtras); // fecha aleatoria

  // formatear fecha en formato DD/MM/YYYY
  var dia = fechaAleatoria.getDate().toString().padStart(2, "0"); // obtener día y asegurarse de que tenga dos dígitos
  var mes = (fechaAleatoria.getMonth() + 1).toString().padStart(2, "0"); // obtener mes y asegurarse de que tenga dos dígitos
  var anio = fechaAleatoria.getFullYear().toString(); // obtener año

  var fechaFormateada = dia + "/" + mes + "/" + anio; // unir los componentes de la fecha en un string con el formato deseado
  return fechaFormateada;
}
function booleanoAleatorio() {
  var numAleatorio = Math.random(); // número aleatorio entre 0 y 1
  var booleano = numAleatorio >= 0.5; // booleano aleatorio con probabilidad del 50%
  return booleano;
}

export default function RepProductos() {
  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);
  const [tipoProducto, setTipoProducto] = useState(0);

  useEffect(() => {
    getListaCliente();
  }, [ValueBusqueda, ValueSelect, tipoProducto]);

  const getListaCliente = () => {
    //console.log("obtener info productos");
    /*let newData = [];
    for (let index = 0; index < 5; index++) {
      newData.push(
        addData(
          index,
          fechaAleatoriaAntesDeHoy(),
          `00${index}`,
          "descrip",
          booleanoAleatorio(),
          booleanoAleatorio()
        )
      );
    }
    setLista(newData);
    setIsLoading(false);
    */
    setIsLoading(true);
    getLista(0, 0, ValueSelect, ValueBusqueda, tipoProducto)
      .then((resp) => {
        let list = JSON.parse(resp.data);
        //console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Productos`;
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Productos</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
            {/* <Button
              className="btn btn-exp-Excel font-AvenirMedium"
              variant="text"
            >
              <img className="me-2" src={ExportarSucess} />
              Exportar Excel
            </Button>
            <Button
              className="btn  btn-exp-PDF  font-AvenirMedium"
              variant="text"
            >
              <img className="me-2" src={ExportarDanger} />
              Exportar PDF
            </Button> */}
            <Link to="./add" style={{ textDecoration: "none" }}>
              <Button
                className="btn btn-Crexendo font-AvenirMedium px-4 "
                variant="contained"
              >
                Crear nuevo
              </Button>
            </Link>
          </Stack>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <Col xs={12} md={6}>
            <Buscador
              placeholder="Buscar productos por descripcion corta o codigo"
              ValueBusqueda={ValueBusqueda}
              setValueBusqueda={setValueBusqueda}
            />
          </Col>
          <Col xs={12} md={3}>
            <Select
              titulo="Tipo"
              ValueSelect={tipoProducto}
              setValueSelect={setTipoProducto}
              opciones={[
                { value: 0, label: "Todos" },
                { value: 1, label: "Prod. terminado" },
                { value: 2, label: "Materia prima" },
                { value: 3, label: "Inventariables" },
                { value: 4, label: "E-commerce" },
                { value: 5, label: "Perecedero" },
                { value: 6, label: "Alquiler" },
              ]}
            />
          </Col>
          <Col xs={12} md={3}>
            <SelectOrdenamiento
              currencies={[
                {
                  value: "1",
                  label: "Más recientes",
                },
                {
                  value: "2",
                  label: "Más antiguos",
                },
                {
                  value: "3",
                  label: "Alfabéticamente descendente",
                },
                {
                  value: "4",
                  label: "Alfabéticamente ascendente",
                },
              ]}
              ValueSelect={ValueSelect}
              setValueSelect={setValueSelect}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TableProductos
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
}
