import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  DialogActions,
} from "@mui/material";
import {
  firstLeterMayus,
  formatMoneda,
} from "../../../../../Lib/Funciones Generales/Generales";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Menos from "../../../../assets/Menos.svg";
import Mas from "../../../../assets/Mas.svg";

export default function ModalProcesar({
  onClose = () => {},
  titulo = "",
  id = 0,
  getProductos = async () => {},
  save = () => {},
  Boton = "",
}) {
  const [productosDisponibles, setProductosDisponibles] = useState([]);
  const [desactivado, setDesactivado] = useState(true);
  useEffect(() => {
    getProductos(id)
      .then((data) => {
        console.log("Data de modal [" + id + "]: ", data);
        if (data.productos && data.productos.length > 0) {
          setProductosDisponibles(data.productos);
        } else {
          const d = JSON.parse(data.data);
          setProductosDisponibles(d);
        }
        setDesactivado(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  console.log(productosDisponibles);
  return (
    <>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <IconButton
          aria-label="Close"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">{titulo}</h6>
        </DialogTitle>

        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="left"
                  >
                    Descripcion
                  </TableCell>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="left"
                  >
                    Precio
                  </TableCell>
                  <TableCell
                    className="font-Avenir font-AvenirBold"
                    align="left"
                  >
                    Cantidad
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productosDisponibles
                  ? productosDisponibles.map((Productos) => (
                      <>
                        <TableRow key={Productos.ID}>
                          <TableCell component="th" scope="row" className="">
                            {firstLeterMayus(Productos.DESCRIPCION_PRODUCTO)}
                          </TableCell>
                          <TableCell component="th" scope="row" className="">
                            {formatMoneda(Productos.PRECIO)}
                          </TableCell>
                          <TableCell component="th" scope="row" className="">
                            {Productos.CANTIDAD}
                          </TableCell>
                        </TableRow>
                      </>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
            variant="contained"
            disabled={desactivado}
            onClick={() => {
              save(id);
              //onClose();
            }}
          >
            {Boton}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
