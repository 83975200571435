import React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  MenuItem,
  Box,
  Tabs,
  Alert,
  styled,
  Tab,
  Snackbar,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ModalSelectProd from "../../Productos/Componentes/ModalSelectProd";
import TableDetalleOrden from "../../OrdenCompra/Componentes/TablaDetalleOrden";
import TabDetalles from "./TabDetalles.jsx";
import { name } from "dayjs/locale/es-mx";
//import { GetDate } from "../../OrdenCompra/Componentes/RepOrdenCompra";
import { GetDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";
import { ConvertDate } from "../../../../../Lib/Funciones Generales/ObtenerFecha";
import { getInfoTraspasoMerca, guardar } from "../Funciones/TraspasarMercancia";
import ButtonTabs from "../../AjusteMercancia/Componentes/ButtonTabs/ButtonTabs";
//import { getInfoIngresoMerca, guardar } from "../Funciones/IngresoMercancia";

const MySwal = withReactContent(Swal);

export default function TraspasarMercancia() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [tab, setTab] = React.useState(0);
  const [productosCarrito, setProductosCarrito] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [Fecha, setFecha] = useState(GetDate);
  const [idAlmacenPrincipalOrigen, setIdAlmacenPrincipalOrigen] = useState(0);
  const [idAlmacenPrincipalDestino, setIdAlmacenPrincipalDestino] = useState(0);

  const [Values, setValues] = useState({
    id: id ?? "0",
    id_sucursal_origen: 0,
    id_almacen_origen: 0,
    id_sucursal_destino: 0,
    id_almacen_destino: 0,
    id_repartidor: 0,
    observaciones: "",
    productos: [],
  });
  const [Errores, setErrores] = useState({
    id_sucursal_origen: false,
    id_almacen_origen: false,
    id_sucursal_destino: false,
    id_almacen_destino: false,
    id_repartidor: false,
    id_receptor: false,
    observaciones: false,
  });
  //#region Handles
  const handlInputChange = ({ target }) => {
    const Name = target.name;
    let Value = target.value;
    if (Name == "id_sucursal_origen") {
      Values.id_almacen_origen = 0;
      setIdAlmacenPrincipalOrigen(0);
    }
    if (Name == "id_sucursal_destino") {
      Values.id_almacen_destino = 0;
    }

    setValues({
      ...Values,
      [Name]: Value,
    });

    setErrores({ ...Errores, [Name]: false });
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };
  const handleProductoSeleccionado = (producto, cantidad = 1) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PRODUCTO === producto.ID)
      : -1;
    if (itemIndex !== -1) {
      const productoExistente = items[itemIndex];
      productoExistente.CANTIDAD += cantidad;
    } else {
      producto = {
        ID_PRODUCTO: parseInt(producto.ID),
        DESCRIPCION_PRODUCTO: producto.DESC_CORTA,
        PRECIO: parseFloat(producto.PRECIO_COMPRA),
        CANTIDAD: cantidad,
        PRODUCTO_TERMINADO: producto.PRODUCTO_TERMINADO,
      };
      items.push(producto);
    }
    setProductosCarrito(items);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //#endregion

  //#region Funciones
  const GuardarTraspasoMercancia = () => {
    setIsGuardando(true);

    guardar(Values, Errores, setErrores, productosCarrito)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            navigate(-1);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };
  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );
  //#endregion

  //#region Handles
  useEffect(() => {
    setIsLoading(true);

    if (id != undefined) {
      getInfoTraspasoMerca(id)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            MySwal.fire({
              title: "Error",
              html: resp.mensaje,
              icon: "error",
              confirmButtonColor: "#3ABE88",
              showConfirmButton: true,
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick: false,
            }).then(() => {
              setIsLoading(false);
              navigate(-1);
            });
          } else {
            //console.log(resp.values);
            setValues(resp.values);
            setProductosCarrito(resp.productos);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          MySwal.fire({
            title: "Error",
            html: resp.mensaje,
            icon: "error",
            confirmButtonColor: "#3ABE88",
            showConfirmButton: true,
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(() => {
            setIsLoading(false);
            navigate(-1);
          });
        });
      //setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, []);

  console.log(Values);
  useEffect(() => {
    setProductosCarrito([]);
  }, [idAlmacenPrincipalOrigen]);

  //#endregion

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <Button
        onClick={() => navigate(-1)}
        className="btn  font-AvenirBold my-4 text-star ps-0 pe-4  "
        variant="text"
      >
        <ArrowBackIcon className="me-3" />
        Traspasar Mercancia
      </Button>
      <Box className=" mb-2">
        <Tabs
          TabIndicatorProps={{
            bgcolor: "#3abe88",
            style: {
              backgroundColor: "#3abe88",
            },
          }}
          value={tab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="#212529"
          indicatorColor=""
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Datos de traspaso" className="font-AvenirBold" />
          <Tab label="Mercancia" className="font-AvenirBold" />
        </Tabs>
      </Box>
      <Card elevation={3} className="mb-4">
        <CardContent className="p-0">
          <div className="p-3">
            <h6 className="mb-0 text-left font-AvenirBold">
              {id
                ? "Editar traspaso de mercancia"
                : "Nuevo traspaso de mercancia"}
            </h6>
          </div>
          <hr
            style={{ backgroundColor: "#DFDEE0", height: "1px", opacity: "1" }}
            className="m-0 p-0"
          />
          {IsLoading ? (
            <Row className="px-3">
              <Col sm={12} className="p-3">
                <Loading />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="p-3">
                {tab == 0 ? (
                  <TabDetalles
                    Values={Values}
                    Errores={Errores}
                    handlInputChange={handlInputChange}
                    Fecha={Fecha}
                    setFecha={setFecha}
                    idAlmacenPrincipalOrigen={idAlmacenPrincipalOrigen}
                    setIdAlmacenPrincipalOrigen={setIdAlmacenPrincipalOrigen}
                    idAlmacenPrincipalDestino={idAlmacenPrincipalDestino}
                    setIdAlmacenPrincipalDestino={setIdAlmacenPrincipalDestino}
                  />
                ) : (
                  <>
                    <div>
                      <Button
                        disabled={Values.id_almacen_origen == 0 ? true : false}
                        style={{ float: "right" }}
                        className="btn btn-Crexendo font-AvenirMedium  py-2 px-4"
                        onClick={() => {
                          setShowModal(!showModal);
                        }}
                      >
                        Agregar productos
                      </Button>
                      {showModal && (
                        <ModalSelectProd
                          onlyInventariables
                          onClose={() => {
                            setShowModal(!showModal);
                          }}
                          handleProductoSeleccionado={
                            handleProductoSeleccionado
                          }
                          titulo="Seleccionar productos inventariables"
                          id_almacen={Values.id_almacen_origen}
                          id_sucursal={Values.id_sucursal_origen}
                          existencias={true}
                          requiredTotal={false}
                          //onlyProductosTerminados={
                          //  idAlmacenPrincipalOrigen == 0 ? false : true
                          // }
                        />
                      )}
                    </div>
                    <Col sm={12} style={{ paddingTop: "15px" }}>
                      <TableDetalleOrden
                        handlInputChange={handlInputChange}
                        productosCarrito={productosCarrito ?? []}
                        setProductosCarrito={setProductosCarrito}
                        IsLoading={IsLoading}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </>
          )}
          <Stack
            className="p-3"
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            style={{}}
          >
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => GuardarTraspasoMercancia()}
              className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span className={IsGuardando ? "px-4" : "px-2"}>
                {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
              </span>
            </LoadingButton>
            <Button
              onClick={() => navigate(-1)}
              className="btn btn-cancelar font-AvenirMedium py-2 px-4"
              variant="outlined"
            >
              Cancelar
            </Button>
            {/*<Button
              onClick={() => {
                const nextTab = tab - 1 < 0 ? 0 : tab - 1;
                setTab(nextTab);
              }}
              className="btn btn-exp-Excel font-AvenirMedium py-2 px-4 ms-auto"
              variant="text"
            >
              <ArrowBackIosNewIcon style={{ fontSize: "20px" }} />
              Anterior
            </Button>
            <Button
              onClick={() => {
                const nextTab = tab + 1 > 3 ? 3 : tab + 1;
                setTab(nextTab);
              }}
              className="btn btn-exp-Excel font-AvenirMedium py-2 px-4 ms-n2"
              variant="text"
            >
              Siguiente
              <ArrowForwardIosIcon style={{ fontSize: "20px" }} />
            </Button>*/}
            <ButtonTabs tab={tab} setTab={setTab} />
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
