import {
  Alert,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItemButton,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { getFloristas, tomarPedido } from "../../Funciones/Pedidos";

import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import NumericKeypad from "../NumericKeypad";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Loading from "../../../../../Includes/Loading";
import Buscador from "../Buscador";

const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

const AsignarFlorista = ({
  setPedidoSeleccionado,
  pedidoSeleccionado,
  setShowDialogAsignarFlorista,
  getListaPedidos,
}) => {
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [floristas, setFloristas] = useState([]);
  const [listaRespaldo, setListaRespaldo] = useState([]);
  const [florista, setFlorista] = useState(0);
  const [password, setPassword] = useState("");
  const [IsLoading, setIsLoading] = useState(true);

  const handlePasswordComplete = (value) => {
    setPassword(value);
  };

  useEffect(() => {
    let array = [];
    setIsLoading(true);
    if (pedidoSeleccionado != "0") {
      getFloristas(pedidoSeleccionado)
        .then((resp) => {
          setFlorista(resp.florista);
          setFloristas(resp.data);
          setListaRespaldo(resp.data);
          setIsLoading(false);

        })
        .catch((resp) => {
          setIsLoading(false);
        });
    }
  }, []);

  const TomarPedido = () => {
    new Swal({
      title: "¿Estas seguro de asignar a este florista?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        tomarPedido(pedidoSeleccionado, florista, password)
          .then((data) => {
            if (data.codigo == "200") {
              getListaPedidos();
              MySwal.fire({
                title: "Correcto",
                html: data.respuesta.register,
                icon: "success",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: false,
                timer: 1700,
              }).then((result) => {
                setShowDialogAsignarFlorista(false);
                pedidoSeleccionado(0);
              });
            } else {
              setMensaje(data.mensaje);
              setOpen(true);
            }
          })
          .catch((data) => {
            setMensaje(data.mensaje);
            setOpen(true);
          });
      }
    });
  };


  const handleChangeInput = ({ target }) => {
    let newList = []
    if (target.value != "") {
      newList = listaRespaldo.filter(x => (
        x.NOMBRE_COMPLETO.toLowerCase().includes(target.value.toLowerCase()) || 
        x.PUESTO.toLowerCase().includes(target.value.toLowerCase())
      ));
    }
    else {
      newList = listaRespaldo
    }
    setFloristas(newList)
  }

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Dialog open={true} fullWidth={true} maxWidth={"md"}>
        <div className="position-absolute" style={{ right: 0 }}>
          <IconButton
            style={{ width: "60px", height: "60px" }}
            onClick={() => {
              setShowDialogAsignarFlorista(false);
            }}
          >
            <CloseIcon style={{ width: "60px", height: "60px" }} />
          </IconButton>
        </div>

        <DialogContent
          className="p-2 pt-0"
          style={{ backgroundColor: "#f9f9f9" }}
        >
          <div
            className="w-100 mt-3 mb-5"
            style={{ backgroundColor: "#f9f9f9" }}
          >
            <h4 className="text-center fw-bolder">Asignar Florista</h4>
          </div>

          <div className="container">
            <div className="row">
              {IsLoading ? (
                <div className="col-12  ">
                  <Loading />
                </div>
              ) : (
                <>
                  <div className="col-12 col-md-5 bg-white">
                    <div style={{ maxHeight: "725px", overflowY: "scroll" }}>
                      <h6 className="text-center fw-bolder mt-3">Floristas</h6>

                      <div className="px-3 pt-3 pb-2">
                        <Buscador
                          handleChangeBuscador={handleChangeInput}
                          placeholder={"Buscar florista"}
                        />
                      </div>

                      <List>
                        {floristas.map((row) => {
                          return (
                            <ListItemButton
                              style={{
                                backgroundColor:
                                  row.ID == florista ? "lightgray" : "",
                              }}
                              onClick={() => {
                                setFlorista(row.ID);
                              }}
                            >
                              <ListItemAvatar>
                                {row.ID == florista ? (
                                  <Avatar className="bg-success">
                                    <CheckCircleIcon />
                                  </Avatar>
                                ) : (
                                  <Avatar className="border  " src={row.IMG} />
                                )}
                              </ListItemAvatar>
                              <ListItemText
                                primary={row.NOMBRE_COMPLETO}
                                secondary={row.PUESTO}
                              />
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <NumericKeypad
                      TomarPedido={TomarPedido}
                      florista={florista}
                      password={password}
                      setPassword={setPassword}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AsignarFlorista;
