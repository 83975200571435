import { useState } from "react";
import { Card } from "@mui/material";
import { Row } from "react-bootstrap";
import RepHorarioEnvio from "./Componentes/RepHorarioEnvio";

const RouterHorarioEnvio = () => {

  const [Horarios, setHorarios] = useState({
    1: { rango: "", lista: [] },
    2: { rango: "", lista: [] },
    3: { rango: "", lista: [] },
    4: { rango: "", lista: [] },
    5: { rango: "", lista: [] },
    6: { rango: "", lista: [] },
    7: { rango: "", lista: [] },
  });

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Horarios de envío</h4>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3">
          <RepHorarioEnvio setHorarios={setHorarios} Horarios={Horarios} />
        </Row>
      </Card>
    </>
  );
}

export default RouterHorarioEnvio