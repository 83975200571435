import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getOpcionesSucursal } from "../Funciones/Sucursales";

const SelectSucursales = ({
  Value,
  Error,
  handlInputChange,
  Name = "id_sucursal",
  Label = "Sucursal",
  className = "",
  size = "medium",

  currencies = [],
  MostrarSucu,
}) => {
  const opcionInicial = { ID: 0, NOMBRE: "Sucursal" };
  const [ValorFiltrado, setFiltro] = useState();
  const [Sucursal, setSucursal] = useState({
    cargado: false,
    lista: [opcionInicial],
  });

  const cargarOps = () => {
    getOpcionesSucursal()
      .then((resp) => {
        let lists = JSON.parse(resp.data);
        let list = MostrarSucu
          ? JSON.parse(resp.data)
          : [{ ID: 0, NOMBRE: "Almacen sin sucursal" }, ...lists];

        if (list.length !== 0) {
          setSucursal({ cargado: true, lista: list });
        } else {
          setSucursal({
            cargado: true,
            lista: [{ ID: 0, NOMBRE: "Sin Almacenes" }],
          });
        }
      })
      .catch((resp) => {
        setSucursal({
          cargado: true,
          lista: [{ ID: 0, NOMBRE: "Sin Almacenes" }],
        });
      });
  };

  useEffect(() => {
    cargarOps();
  }, []);

  /*useEffect(() => {
    //console.log("cambio el id "+Value);

    setSucursal({
      cargado: false,
      lista: [],
    });
    cargarOps();
  }, [Value]);*/

  const getOpcion = () => {
    const opcion = Sucursal.lista.find((x) => x.ID === Value);
    //console.log("cambio el id "+opcion);
    if (opcion) {
      return opcion;
    } else {
      return null;
    }
  };

  return Sucursal.cargado ? (
    <Autocomplete
      size={size}
      fullWidth
      className={className}
      defaultValue={getOpcion}
      isOptionEqualToValue={(option, value) => {
        return option.ID === value.ID;
      }}
      inputValue={ValorFiltrado}
      onChange={(e, value) => {
        if (value) {
          handlInputChange({
            target: {
              name: Name,
              value: value.ID,
              dir: String(value.DIRECCION),
            },
          });
        } else {
          handlInputChange({ target: { name: Name, value: "", dir: "" } });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setFiltro(newInputValue);
      }}
      options={Sucursal.lista}
      getOptionLabel={(option) => {
        return option.NOMBRE;
      }}
      renderInput={(params) => {
        return (
          <TextField
            size={size}
            {...params}
            error={Error}
            helperText={Error ? "Selecciona un Sucursal" : ""}
            label={Label}
          />
        );
      }}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <span>{option.NOMBRE}</span>
          </li>
        );
      }}
    />
  ) : (
    <TextField
      size={size}
      fullWidth
      disabled={true}
      value={"Sucursal"}
      className={className}
    />
  );
};

export default SelectSucursales;
