import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../../../../Context/backend";
import validator from "validator";
import { json } from "react-router-dom";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (
  iTake,
  iSkip,
  fechaInicio,
  fechaFin,
  Sucursal
) => {
  //onsole.log(query)
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    fechaInicio: fechaInicio,
    fechaFin: fechaFin,
    Sucursal: Sucursal
  };

  const url = `cortes/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const getInfoCorte= async (Sucursal) => {
   console.log("id"+Sucursal)
   
    let data = [];
    let queryParamsObj = {
      Sucursal: Sucursal,
    };

    const url = `cortes/ConsultarInfo?${stringify(queryParamsObj)}`;
    const res = await getData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = JSON.parse(res.data.respuesta.data);
        if (data.length == 0) {
          resolve({
            err: [],
            mensaje: "No se encontró información para corte",
          });
        } else {
          data = JSON.parse(res.data.respuesta.data)[0];
         // console.log("jheuvh "+res.data.respuesta.venta);
          let d = {
            values: {
              fTotalTarjeta: Number(data.TOTAL_TARJETA),
              fTotalEfectivo: Number(data.TOTAL_EFECTIVO),
              fTotalMonedero: Number(data.TOTAL_MONEDERO),
              fTotalTransferencia: Number(data.TRANSFERENCIA),
              fTotalCompras: Number(data.TOTAL_COMPRAS),
              observaciones: data.OBSERVACIONES
            },
            detalle: JSON.parse(res.data.respuesta.venta),
          };
          resolve(d);
          //console.log("Datosss", d);
        }
      } else {
        reject({
          mensaje: "Error al conectar a internet, revisa tu conexion a internet",
        });
      }
    });
  };


  export const CancelarRequisicion = async (id) => {
    if (id != undefined) {
      let data = [];
      let queryParamsObj = {
        id: id,
      };
      const url = `requisicionmercancia/Cancelar?${stringify(queryParamsObj)}`;
      const res = await postUrl(url);
      return new Promise((resolve, reject) => {
        if (!res.error) {
          data = res.data;
          resolve(data);
        } else {
          reject(res);
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "No se pudo obtener el identificador" });
      });
    }
  };



  export const GuardarCorte = async (
    Values,
    Errores,
    setErrores,
    detalle,
    id_sucursal
  ) => {
    let error = false;
  
  


    Values.fTotalTarjeta = Number(Values.fTotalTarjeta);
    Values.fTotalEfectivo = Number(Values.fTotalEfectivo);
    Values.fTotalMonedero = Number(Values.fTotalMonedero);
    Values.fTotalTransferencia = Number(Values.fTotalTransferencia);
    Values.fTotalCompras = Number(Values.fTotalCompras);
  
    Values.productos = detalle;
    
    if (!error) {
      const body = {
        fTotalTarjeta: Values.fTotalTarjeta,
        fTotalEfectivo: Values.fTotalEfectivo,
        fTotalMonedero: Values.fTotalMonedero,
        fTotalTransferencia: Values.fTotalTransferencia,
        fTotalCompras: Values.fTotalCompras,
        observaciones: Values.observaciones,
        detalle: [...detalle],
        id_sucursal: id_sucursal
      };
       console.log("sdd"+ JSON.stringify(body));
      let data = [];
      const url = "cortes/Insertar";
      const res = await postdData(url, body);
  
      return new Promise((resolve, reject) => {
        if (!res.error) {
          data = res.data;
          resolve(data);
        } else {
          reject(res);
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve({ mensaje: "Completa todos los campos requeridos" });
      });
    }
  };


  export const getDetalleCorte= async (Sucursal) => {
    console.log("id"+Sucursal)
    
     let data = [];
     let queryParamsObj = {
       Sucursal: Sucursal,
     };
 
     const url = `cortes/ConsultarCorte?${stringify(queryParamsObj)}`;
     const res = await getData(url);
     return new Promise((resolve, reject) => {
       if (!res.error) {
         data = JSON.parse(res.data.respuesta.data);
         if (data.length == 0) {
           resolve({
             err: [],
             mensaje: "No se encontró información para corte",
           });
         } else {
           data = JSON.parse(res.data.respuesta.data)[0];
          // console.log("jheuvh "+res.data.respuesta.venta);
           let d = {
             values: {
               fTotalTarjeta: Number(data.TARJETA),
               fTotalEfectivo: Number(data.EFECTIVO),
               fTotalMonedero: Number(data.MONEDERO),
               fTotalTransferencia: Number(data.TRANSFERENCIA),
               fTotalCompras: Number(data.NO_VENTAS),
               observaciones: data.OBSERVACIONES
             },
             detalle: JSON.parse(res.data.respuesta.venta),
           };
           resolve(d);
           //console.log("Datosss", d);
         }
       } else {
         reject({
           mensaje: "Error al conectar a internet, revisa tu conexion a internet",
         });
       }
     });
   };