import React, { useState, useEffect } from "react";
import { Alert, Button, Fade, Snackbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead, Tooltip, Grid, MenuItem, Menu } from "@mui/material";
import { Link, json } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import MoreVert from "@mui/icons-material/MoreVert";
import Swal from "sweetalert2";
import styled from "@emotion/styled";
import AccEditar from "./../../../../assets/AccEditar.svg";
import Loading from "../../../../../Includes/Loading";
import {
  CancelarOrden,
  ProcesarOrden,
} from "../Funciones/OrdenCompra";
import { firstLeterMayus } from "../../../../../Lib/Funciones Generales/Generales";
import CancelIcon from "@mui/icons-material/Cancel";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";


const MySwal = withReactContent(Swal);

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));

export default function TableBusqueda({
  Lista,
  IsLoading,
  updateTable = () => {},
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [severity, setSeverity] = useState("error");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [idRow, setIdRow] = useState();
  const [stateRow, setStateRow] = useState();

  const [openModalProcesar, setOpenModalProcesar] = useState(false);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    //console.log("productos", event);
    setAnchorEl(event.currentTarget);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    //console.log(page);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const selectOrden = (id, tipo) => {
    console.log("ID: "+id+ " TIPO: "+tipo)
 
    let TIPO = tipo === 1 ? "requisición" : "orden";
    
    new Swal({
      title: "¿Estas seguro de procesar esta " +  TIPO + "?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, procesar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {

      if (isConfirm.isConfirmed) {
       /* if (id != undefined) {
          ProcesarOrden(id,productosDisponibles)
            .then((resp) => {
              new Swal({
                title: "Exito!",
                text: "Procesada correctamente",
                icon: "success",
              }).then(function () {
                updateTable();
                setOpenModalProcesar(false);
              });
            })
            .catch((resp) => {
              console.log(resp);
              alert("error al procesar orden");
            });
        }*/
      }
    });
  };


  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        // top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <TableContainer component={Paper}>

        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                FOLIO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                TIPO
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                FECHA
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="center">
                TOTAL PRODUCTOS
              </TableCell>
   
              <TableCell className="font-Avenir font-AvenirBold" align="right">
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={6}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? Lista.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : Lista
              ).map((row) => (
                <TableRow key={row.ID}>
                  <TableCell component="th" scope="row" className="">
                    000{row.FOLIO}
                  </TableCell>

                  <TableCell
                    component="th" scope="row" className="d-none d-sm-table-cell" >
                    {row.TIPO}
                  </TableCell>
                  
                  <TableCell component="th" scope="row" className="">
                    {firstLeterMayus(row.FECHA)}
                  </TableCell>

                  <TableCell component="th" scope="row" align="center">
                    {row.TOTAL_PRODUCTOS}
                  </TableCell>

                  <TableCell align="right">
                    <Grid key={row.ID} className="d-none d-sm-block">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >

                    <Button
                        className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 "
                        variant="contained"
                        onClick={() => {
                          selectOrden(row.ID, row.ID_TIPO)
                        }}
                   
                      >
                        Seleccionar
                    </Button>

      
                    </div>
                  </Grid>
  
               </TableCell>
             </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
