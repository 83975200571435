import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { ButtonGroup, Col, Container, Row } from "react-bootstrap";
import BackspaceIcon from "@mui/icons-material/Backspace";

function NumericKeypad({ setPassword, password, TomarPedido,florista }) 
{
  const handleInput = (digit) => {
    if (password.length < 4) {
      setPassword(password + digit);
      if (password.length == 4) {
        setPassword(password + digit);
      }
    }
  };

  const handleDelete = () => {
    setPassword(password.slice(0, -1));
  };

  const handleClear = () => {
    setPassword("");
  };

  const renderButton = (digit) => {
    return (
      <Button
        variant="contained"
        className="w-100 btn-accion"
        style={{ height: "100px", background: "#65748B", fontSize: "2rem" }}
        key={digit}
        onClick={() => handleInput(digit)}
      >
        {digit}
      </Button>
    );
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={12} className="p-2">
          <TextField
            size="lg"
            style={{ fontSize: "2rem" }}
            fullWidth
            readionly
            id="outlined-basic"
            label="Clave"
            variant="outlined"
            value={password}
          />
        </Col>

        <Col md={4} className="p-2">
          {renderButton("7")}
        </Col>

        <Col md={4} className="p-2">
          {renderButton("8")}
        </Col>
        <Col md={4} className="p-2">
          {renderButton("9")}
        </Col>
        <Col md={4} className="p-2">
          {renderButton("4")}
        </Col>

        <Col md={4} className="p-2">
          {renderButton("5")}
        </Col>
        <Col md={4} className="p-2">
          {renderButton("6")}
        </Col>
        <Col md={4} className="p-2">
          {renderButton("1")}
        </Col>
        <Col md={4} className="p-2">
          {renderButton("2")}
        </Col>
        <Col md={4} className="p-2">
          {renderButton("3")}
        </Col>

        <Col md={4} className="p-2">
          <Button
            variant="contained"
            className="w-100"
            style={{ height: "100px" }}
            color="error"
            onClick={handleClear}
          >
            Limpiar
          </Button>
        </Col>
        <Col md={4} className="p-2">
          {renderButton("0")}
        </Col>
        <Col md={4} className="p-2">
          <Button
            variant="contained"
            color="warning"
            className="w-100"
            style={{ height: "100px" }}
            onClick={handleDelete}
          >
            <BackspaceIcon />
          </Button>
        </Col>

        <Col md={12} className="p-2">
          <Button
          disabled={florista==undefined || florista =="" || florista==0 || florista==null || password==""  || password.length!=4}
            variant="contained"
            color="success"
            className="w-100"
            style={{ height: "80px" }}
            onClick={TomarPedido} 
          >
            Confirmar
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default NumericKeypad;
