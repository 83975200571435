import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DashboardLateral from "../../assets/DashboardLateral.svg";
import SeriesLateral from "../../assets/SeriesLateral.svg";
import imgInventario from "../../assets/Inventario.svg";
import imgPuntoVenta from "../../assets/PuntoVenta.svg";
import SettingsIcon from "@mui/icons-material/Settings";

import DescuentosLateral from "../assets/DescuentosLateralOFF.svg";
import Logoblanco from "./../../assets/Logoblanco.svg";
import MonPedido from "./../../assets/MonPedido.svg";
import FacturarWhite from "./../assets/FacturarWhite.svg";
import { Link } from "react-router-dom";
import ReportesLateral from "../assets/ReportesLateralOFF.svg";
import ConfigLateral from "../assets/ConfigLateral.svg";
import { useLocation } from "react-router-dom"; 
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CheckIcon from "@mui/icons-material/Check";

import DashboardLateralFoc from "../assets/ColorIconsMenu/DashboardLateralF.svg";
import imgPuntoVentaFoc from "../assets/ColorIconsMenu/PuntoVenta.svg";
import MonPedidoFoc from "../assets/ColorIconsMenu/MonPedido.svg";
import SeriesLateralFoc from "../assets/ColorIconsMenu/SeriesLateral.svg";
import DescuentosLateralFoc from "../assets/ColorIconsMenu/DescuentosLateralOFF.svg";
import imgInventarioFoc from "../assets/ColorIconsMenu/Inventario.svg";
import ReportesLateralFoc from "../assets/ColorIconsMenu/ReportesLateralOFF.svg";
import FacturarWhiteFAc from "../assets/ColorIconsMenu/FacturarWhiteFAc.svg";
import ConfigLateralFoc from "../assets/ColorIconsMenu/ConfigLateral.svg";
import CambioDeEmpresa from "./CambioDeEmpresa";
import AccEditarImagen from "../assets/AvatarMenu.svg";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import AccountMenu from "./MenuPerfil";
import {
  clearStorageJWT,
  getLocalStorage,
  setLocalStorage,
  setLocalStorageJWT,
} from "../../Context/Storage";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  CambiarEmpresa,
  getOpcionesEmpresas,
} from "../Components/Configuraciones/Empresas/Funciones/Empresas";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Avatar } from "@mui/material";
import WindowIcon from "@mui/icons-material/Window";
import StorefrontIcon from "@mui/icons-material/Storefront"; 
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
const MySwal = withReactContent(Swal);

export default function Sidebar({ openMenu, permisosMenu, IsLoading }) {
  const location = useLocation();
  const colors = {
    focus: "#006064",
    neutral: "#FFFFFF",
  };
  //const [open, setOpen] = React.useState(false);
  // const [openCatalogos, setOpenCatalogos] = React.useState(false);
  // const [openFacturacion, setOpenFacturacion] = React.useState(false);
  // const [openDescuentos, setDescuentos] = React.useState(false);
  // const [openInventario, setInventario] = React.useState(false);
  // const [openReporte, setReporte] = React.useState(false);
  // const [openEmpresa, setopenEmpresa] = React.useState(false);
  // const [openPerfil, setopenPerfil] = React.useState(false);
  // const [openConfig, setOpenConfig] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState(null);

  const [Values, setValues] = React.useState({
    img: AccEditarImagen,
  });
  // const handleClick = () => {
  //   setOpen(!open);
  // };

  const handleClickCatalogos = () => {
    if (activeItem === "Catalogos") {
      setActiveItem(null);
    } else {
      setActiveItem("Catalogos");
    }
  };

  const handleClickFacturacion = () => {
    if (activeItem === "Facturacion") {
      setActiveItem(null);
    } else {
      setActiveItem("Facturacion");
    }
  };

  const handleClickDescuentos = () => {
    if (activeItem === "Descuentos") {
      setActiveItem(null);
    } else {
      setActiveItem("Descuentos");
    }
  };

  const handleClickInventario = () => {
    if (activeItem === "Inventario") {
      setActiveItem(null);
    } else {
      setActiveItem("Inventario");
    }
  };

  const handleClickReporte = () => {
    if (activeItem === "Reporte") {
      setActiveItem(null);
    } else {
      setActiveItem("Reporte");
    }
  };

  const handleClickEmpresa = () => {
    if (activeItem === "Empresa") {
      setActiveItem(null);
    } else {
      setActiveItem("Empresa");
    }
  };

  const handleClickPerfil = () => {
    if (activeItem === "Perfil") {
      setActiveItem(null);
    } else {
      setActiveItem("Perfil");
    }
  };

  const handleClickConfig = () => {
    if (activeItem === "Configuraciones") {
      setActiveItem(null);
    } else {
      setActiveItem("Configuraciones");
    }
  };

  const img_url = IsLoading ? getLocalStorage("img") : Values.img;
  const CerrarSesion = () =>
    new Swal({
      title: "¿Estas seguro de cerrar sesión?",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Cerrando sesión...",
          showConfirmButton: false,
          timer: 1500,
        }).then(clearStorageJWT);
      }
    });
  //Empresa
  const [nombreEmpresa, setNombreEmpresa] = React.useState("Cargando...");
  const [logo, setLogo] = React.useState("");
  const [empresas, getEmpresas] = React.useState([]);
  const [IsGuardando, setIsGuardando] = React.useState(false);

  React.useEffect(() => {
    getOpcionesEmpresas()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        if (list.length != 0) {
          getEmpresas(list);
        } else {
          getEmpresas({});
        }
      })
      .catch((resp) => {
        getEmpresas([]);
      });
  }, []);

  React.useEffect(() => {
    try {
      let Ar = empresas.find((emp) => emp.KEY == getLocalStorage("key"));
      if (empresas.length > 0) {
        setNombreEmpresa(Ar.NOMBRE);
        setLogo(Ar.IMG);
      }
    } catch (error) {}
  }, [empresas]);

  const clicCambioEmpresa = (key) => {
    setIsGuardando(true);
    CambiarEmpresa(key)
      .then((data) => {
        if (data.codigo == "200") {
          setLocalStorage("key", data.respuesta.key);
          setLocalStorageJWT(data.respuesta.token);
          setLocalStorage("img", data.respuesta.img);
          setLocalStorage("tipo", data.respuesta.tipo);
          setLocalStorage("nombre_empresa", data.respuesta.nombre);

          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: "El cambio de empresa se realizó con éxito",
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1200,
          }).then((result) => {
            window.location.reload();
          });
        } else {
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setIsGuardando(false);
        MySwal.fire({
          title: "Error",
          html: "Ha ocurrido un error al relizar el cambio de empresa",
          icon: "error",
          confirmButtoColor: "#3ABE88",
          showConfirmButton: false,
          timer: 1200,
        }).then((result) => {});
      });
  };

  //console.log(permisosMenu);
  return (
    <div
      className=" d-flex flex-column justify-content-between h-100 "
      style={{
        backgroundColor: "#100D1C",
      }}
    >
      <List
        sx={{ width: "100%", maxWidth: 360 }}
        style={{
          backgroundColor: "#006064",
          height: "100%",
          color: "white",
          zIndex: 1,
          paddingTop: openMenu ? "10px" : "50px",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {
          //#region INICIO
        }
        {openMenu ? (
          <center>
            <Link to="inicio/" style={{ textDecoration: "none" }}>
              <img
                className="mb-5 mt-3"
                src={Logoblanco}
                width={180}
                style={{ margin: "auto" }}
              />
            </Link>
          </center>
        ) : (
          <></>
        )}
        <hr className="d-md-none d-md-block" />
        {/*------------------------------      PERFIL   ----------------------------------------------------*/}
        <ListItemButton
          className="ps-2 d-md-none d-md-block"
          onClick={handleClickPerfil}
          style={{
            color:
              location.pathname == "/perfil/" ? colors.focus : colors.neutral,
          }}
        >
          <ListItemIcon>
            <Avatar className="border" src={img_url}></Avatar>
          </ListItemIcon>
          <ListItemText
            className="text-truncate"
            style={{ maxWidth: "150px" }}
            primary={getLocalStorage("nombre")}
          />
          {activeItem === "Perfil" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={activeItem === "Perfil"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className="d-lg-none d-lg-block">
            <Link
              to="perfil/"
              style={{
                textDecoration: "none",
                color:
                  location.pathname == "/perfil/"
                    ? colors.focus
                    : colors.neutral,
              }}
            >
              <ListItemButton className="ps-3">
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Mi  perfil" />
              </ListItemButton>
            </Link>
            <Link
              onClick={CerrarSesion}
              style={{
                textDecoration: "none",
                color:
                  location.pathname == "/clientes/"
                    ? colors.focus
                    : colors.neutral,
              }}
            >
              <ListItemButton className="ps-3">
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Cerrar sesión" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <hr className="d-md-none d-md-block" />
        {/*------------------------------      EMPRESA   ----------------------------------------------------*/}
        <ListItemButton
          className="ps-2 d-md-none d-md-block"
          onClick={handleClickEmpresa}
        >
          <ListItemIcon>
            <Avatar className="border" src={logo}></Avatar>
          </ListItemIcon>
          <ListItemText
            className="text-truncate"
            style={{ maxWidth: "150px" }}
            primary={nombreEmpresa}
          />
          {activeItem === "Empresa" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={activeItem === "Empresa"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className="d-lg-none d-lg-block">
            <Link style={{ textDecoration: "none", color: "white" }}>
              {empresas.map((row) => {
                return (
                  <MenuItem onClick={() => clicCambioEmpresa(row.KEY)}>
                    <ListItemText className="p-2 text-truncate">
                      {" "}
                      {row.NOMBRE}
                    </ListItemText>
                    {IsGuardando ? (
                      <HourglassEmptyIcon
                        className="ms-3 text-success"
                        style={{ width: "15px" }}
                      />
                    ) : row.KEY == getLocalStorage("key") ? (
                      <CheckIcon
                        className="ms-3 text-success"
                        style={{ width: "15px" }}
                      />
                    ) : (
                      <></>
                    )}
                  </MenuItem>
                );
              })}
            </Link>
          </List>
        </Collapse>
        <hr className="d-lg-none d-lg-block" />
        {/*------------------------------      DASHBOARD   ----------------------------------------------------*/}
        <Link
          to="inicio/"
          style={{
            textDecoration: "none",
            color:
              location.pathname == "/" || location.pathname == "/inicio/"
                ? colors.focus
                : colors.neutral,
          }}
          className={"mb-1 mt-5 "}
        >
          <ListItemButton
            className={
              "ps-2 " +
              (location.pathname == "/" ||
                (location.pathname == "/inicio/" && " ItemActive"))
            }
          >
            <ListItemIcon>
              
               <WindowIcon
                width={20} 
                style={{
                  margin: "auto",
                  color:
                    location.pathname == "/inicio/"
                      ? colors.focus
                      : colors.neutral,
                }}
              />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </Link>
        {
          //#endregion
        }
        {/*------------------------------      PUNTO DE VENTA  ----------------------------------------------------*/}
        {
          //#region PUNTO DE VENTA
        }
        {permisosMenu.includes("PuntoDeVenta") == true ? (
          <Link
            to="punto_de_venta/"
            style={{
              textDecoration: "none",
              color:
                location.pathname == "/punto_de_venta/"
                  ? colors.focus
                  : colors.neutral,
            }}
            className="mb-1 mt-5"
          >
            <ListItemButton
              className={
                "ps-2 " +
                (location.pathname == "/punto_de_venta/" && " ItemActive")
              }
            >
              <ListItemIcon>
              <StorefrontIcon
                width={20} 
                style={{
                  margin: "auto",
                  color:
                    location.pathname == "/punto_de_venta/"
                      ? colors.focus
                      : colors.neutral,
                }}
              />
              </ListItemIcon>
              <ListItemText primary="Punto de venta" />
            </ListItemButton>
          </Link>
        ) : (
          ""
        )}

        {permisosMenu.includes("Pedidos") == true ? (
          <Link
            to="monitor_pedidos/"
            style={{
              textDecoration: "none",
              color:
                location.pathname == "/monitor_pedidos/"
                  ? colors.focus
                  : colors.neutral,
            }}
            className="mb-1 mt-5"
          >
            <ListItemButton
              className={
                "ps-2 " +
                (location.pathname == "/monitor_pedidos/" && " ItemActive")
              }
            >
              <ListItemIcon> 
                 <LocalShippingOutlinedIcon
                width={20} 
                style={{
                  margin: "auto",
                  color:
                    location.pathname == "/monitor_pedidos/"
                      ? colors.focus
                      : colors.neutral,
                }}
              />
              </ListItemIcon>
              <ListItemText primary="Pedidos" />
            </ListItemButton>
          </Link>
        ) : (
          ""
        )}
        {
          //#endregion
        }

        {/*------------------------------      FACTURAS  ----------------------------------------------------*/}
        {
          //#region FACTURAS
        }
        <ListItemButton
          className="ps-2"
          onClick={handleClickFacturacion}
          style={{
            color:
              location.pathname == "/facturas/"
                ? colors.neutral
                : colors.neutral,
          }}
        >
          <ListItemIcon>
            <img
              className="mb-1"
              src={
                location.pathname == "/facturas/"
                  ? FacturarWhite
                  : FacturarWhite
              }
              width={20}
              style={{ margin: "auto" }}
            />
          </ListItemIcon>
          <ListItemText primary="Facturación" />
          {activeItem === "Facturacion" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse
          in={activeItem === "Facturacion"}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {permisosMenu.includes("Facturas") == true ? (
              <Link
                to="facturas/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/facturas/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/facturas/" && " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Facturas" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
          </List>
        </Collapse>

        {/*------------------------------      CATALOGOS  ----------------------------------------------------*/}
        {
          //#region CATALOGOS
        }
        <ListItemButton
          className="ps-2"
          onClick={handleClickCatalogos}
          style={{
            color:
              location.pathname == "/almacenes/" ||
              location.pathname == "/series/" ||
              location.pathname == "/clientes/" ||
              location.pathname == "/categorias/" ||
              location.pathname == "/horariosEnvios/" ||
              location.pathname == "/productos/" ||
              location.pathname == "/proveedores/" ||
              location.pathname == "/puestos/" ||
              location.pathname == "/sucursales/" ||
              location.pathname == "/repartidores/" ||
              location.pathname == "/zonas/"
                ? colors.neutral
                : colors.neutral,
          }}
        >
          <ListItemIcon>
            <img
              className="mb-1"
              src={
                location.pathname == "/series/" ||
                location.pathname == "/almacenes/" ||
                location.pathname == "/clientes/" ||
                location.pathname == "/categorias/" ||
                location.pathname == "/horariosEnvios/" ||
                location.pathname == "/productos/" ||
                location.pathname == "/proveedores/" ||
                location.pathname == "/puestos/" ||
                location.pathname == "/sucursales/" ||
                location.pathname == "/repartidores/" ||
                location.pathname == "/zonas/"
                  ? SeriesLateral
                  : SeriesLateral
              }
              width={20}
              style={{ margin: "auto" }}
            />
          </ListItemIcon>
          <ListItemText primary="Catálogos" />
          {activeItem === "Catalogos" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={activeItem === "Catalogos"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {permisosMenu.includes("Series") == true ? (
              <Link
                to="series/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/series/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " + (location.pathname == "/series/" && " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Series" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("Almacenes") == true ? (
              <Link
                to="almacenes/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/almacenes/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/almacenes/" && " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Almacenes" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("Clientes") == true ? (
              <Link
                to="clientes/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/clientes/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/clientes/" && " ItemActive")
                  }
                >
                  <ListItemIcon>
                    {/*<PeopleAltIcon
                  width={20}
                  style={{ margin: "auto", color: "white" }}
      />*/}
                  </ListItemIcon>
                  <ListItemText primary="Clientes" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("Categorias") == true ? (
              <Link
                to="categorias/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/categorias/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/categorias/" && " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Categorías" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("HorariosEnvios") == true ? (
              <Link
                to="horariosEnvios/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/horariosEnvios/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/horariosEnvios/" && " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Horarios de envío" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("Productos") == true ? (
              <Link
                to="productos/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/productos/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/productos/" && " ItemActive")
                  }
                >
                  <ListItemIcon>
                    {/*<FolderSharedIcon
                  width={20}
                  style={{ margin: "auto", color: "white" }}
      />*/}
                  </ListItemIcon>
                  <ListItemText primary="Productos" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("Proveedores") == true ? (
              <Link
                to="proveedores/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/proveedores/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/proveedores/" && " ItemActive")
                  }
                >
                  <ListItemIcon>
                    {/*<PeopleAltIcon
                  width={20}
                  style={{ margin: "auto", color: "white" }}
      />*/}
                  </ListItemIcon>
                  <ListItemText primary="Proveedores" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("Puestos") == true ? (
              <Link
                to="puestos/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/puestos/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/puestos/" && " ItemActive")
                  }
                >
                  <ListItemIcon>
                    {/*<FolderSharedIcon
                    width={20}
                    style={{ margin: "auto", color: "white" }}
                    />*/}
                  </ListItemIcon>
                  <ListItemText primary="Puestos" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("Sucursales") == true ? (
              <Link
                to="sucursales/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/sucursales/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/sucursales/" && " ItemActive")
                  }
                >
                  <ListItemIcon>
                    {/*<FolderSharedIcon
                  width={20}
                  style={{ margin: "auto", color: "white" }}
      />*/}
                  </ListItemIcon>
                  <ListItemText primary="Sucursales" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("Repartidores") == true ? (
              <Link
                to="repartidores/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/repartidores/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/repartidores/" && " ItemActive")
                  }
                >
                  <ListItemIcon>
                    {/*<CategoryIcon
                  width={20}
                  style={{ margin: "auto", color: "white" }} />*/}
                  </ListItemIcon>
                  <ListItemText primary="Repartidores" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("Zonas") == true ? (
              <Link
                to="zonas/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/zonas/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " + (location.pathname == "/zonas/" && " ItemActive")
                  }
                >
                  <ListItemIcon>
                    {/*<FolderSharedIcon
                    width={20}
                    style={{ margin: "auto", color: "white" }}
                    />*/}
                  </ListItemIcon>
                  <ListItemText primary="Zonas" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
          </List>
        </Collapse>
        {
          //#endregion
        }
        {/*-------------------------------------------------DESCUENTOS Y CUPONES------------------------------------------------------------*/}
        {
          //#region DESCUENTOS Y CUPONES
        }
        <ListItemButton
          className="ps-2"
          onClick={handleClickDescuentos}
          style={{
            color:
              location.pathname == "/descuentos/" ||
              location.pathname == "/cupones/" ||
              location.pathname == "/productos_descuento/"
                ? colors.neutral
                : colors.neutral,
          }}
        >
          <ListItemIcon>
            {" "}
            <img
              className="mb-1"
              src={
                location.pathname == "/descuentos/" ||
                location.pathname == "/cupones/" ||
                location.pathname == "/productos_descuento/"
                  ? DescuentosLateral
                  : DescuentosLateral
              }
              width={22}
              style={{ margin: "auto" }}
            />
          </ListItemIcon>
          <ListItemText primary="Descuentos" />
          {activeItem === "Descuentos" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={activeItem === "Descuentos"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {permisosMenu.includes("Descuentos") == true ? (
              <Link
                to="descuentos/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/descuentos/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/descuentos/" && " ItemActive")
                  }
                >
                  <ListItemIcon>
                    {/*<PeopleAltIcon
                  width={20}
                  style={{ margin: "auto", color: "white" }}
      />*/}
                  </ListItemIcon>
                  <ListItemText primary="Descuentos" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("Cupones") == true ? (
              <Link
                to="cupones/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/cupones/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/cupones/" && " ItemActive")
                  }
                >
                  <ListItemIcon>
                    {/*<PeopleAltIcon
                  width={20}
                  style={{ margin: "auto", color: "white" }}
      />*/}
                  </ListItemIcon>
                  <ListItemText primary="Cupones" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("ProductosDescuento") == true ? (
              <Link
                to="productos_descuento/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/productos_descuento/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/productos_descuento/" &&
                      " ItemActive")
                  }
                >
                  <ListItemIcon>
                    {/*<PeopleAltIcon
                  width={20}
                  style={{ margin: "auto", color: "white" }}
      />*/}
                  </ListItemIcon>
                  <ListItemText primary="Productos descuento" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
          </List>
        </Collapse>
        {
          //#endregion
        }
        {/*-------------------------------------------------Inventarios------------------------------------------------------------*/}
        <ListItemButton
          className="ps-2"
          onClick={handleClickInventario}
          style={{
            color:
              location.pathname == "/ordencompra/" ||
              location.pathname == "/ingresomercancia/" ||
              location.pathname == "/traspasomercancia/" ||
              location.pathname == "/ajustemercancia/" ||
              location.pathname == "/requisionmercancia/"
                ? colors.neutral
                : colors.neutral,
          }}
        >
          <ListItemIcon>
            {" "}
            <img
              className="mb-1"
              src={
                location.pathname == "/ordencompra/" ||
                location.pathname == "/ordencompra/" ||
                location.pathname == "/ingresomercancia/" ||
                location.pathname == "/traspasomercancia/" ||
                location.pathname == "/ajustemercancia/" ||
                location.pathname == "/requisicionmercancia/"
                  ? imgInventario
                  : imgInventario
              }
              width={20}
              style={{ margin: "auto" }}
            />
          </ListItemIcon>
          <ListItemText primary="Inventario" />
          {activeItem === "Inventario" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={activeItem === "Inventario"} timeout="auto" unmountOnExit>
          {permisosMenu.includes("AjusteMercancia") == true ? (
            <Link
              to="ajustemercancia/"
              style={{
                textDecoration: "none",
                color:
                  location.pathname == "/ajustemercancia/"
                    ? colors.focus
                    : colors.neutral,
              }}
            >
              <ListItemButton
                className={
                  "ps-2 " +
                  (location.pathname == "/ajustemercancia/" && " ItemActive")
                }
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Ajuste Mercancía" />
              </ListItemButton>
            </Link>
          ) : (
            ""
          )}
          <List component="div" disablePadding>
            {permisosMenu.includes("IngresoMercancia") == true ? (
              <Link
                to="ingresomercancia/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/ingresomercancia/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/ingresomercancia/" && " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Ingreso Mercancía" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("OrdenDeCompra") == true ? (
              <Link
                to="ordencompra/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/ordencompra/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/ordencompra/" && " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Orden de Compra" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
            {permisosMenu.includes("TraspasoMercancia") == true ? (
              <Link
                to="traspasomercancia/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/traspasomercancia/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/traspasomercancia/" &&
                      " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Traspaso Mercancía" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("RequisicionMercancia") == true ? (
              <Link
                to="requisicionmercancia/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/requisicionmercancia/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/requisicionmercancia/" &&
                      " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Requisición Mercancía" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
          </List>
        </Collapse>
        {/*-------------------------------------------------Reportes------------------------------------------------------------*/}
        <ListItemButton
          className="ps-2"
          onClick={handleClickReporte}
          style={{
            color:
              location.pathname == "/reporte-venta/" ||
              location.pathname == "/exitenciamercancia/" ||
              location.pathname == "/bitacora/"
                ? colors.neutral
                : colors.neutral,
          }}
        >
          <ListItemIcon>
            {" "}
            <img
              className="mb-1"
              src={
                location.pathname == "/reporte-venta/" ||
                location.pathname == "/exitenciamercancia/" ||
                location.pathname == "/bitacora/"
                  ? ReportesLateral
                  : ReportesLateral
              }
              width={20}
              style={{ margin: "auto" }}
            />
          </ListItemIcon>
          <ListItemText primary="Reportes" />
          {activeItem === "Reporte" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={activeItem === "Reporte"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {permisosMenu.includes("BitacoraInventarios") == true ? (
              <Link
                to="bitacora/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/bitacora/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/bitacora/" && " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Bitacora" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("ExitenciaMercancia") == true ? (
              <Link
                to="exitenciamercancia/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/exitenciamercancia/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/exitenciamercancia/" &&
                      " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Existencia Mercancía" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
            <Link
              to="restock/"
              style={{
                textDecoration: "none",
                color:
                  location.pathname == "/restock/"
                    ? colors.focus
                    : colors.neutral,
              }}
            >
              <ListItemButton
                className={
                  "ps-2 " + (location.pathname == "/restock/" && " ItemActive")
                }
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary="Re-Stock" />
              </ListItemButton>
            </Link>
            {permisosMenu.includes("ReportePedidos") == true ? (
              <Link
                to="reportepedidos/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/reportepedidos/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/reportepedidos/" && " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Pedidos" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
            {permisosMenu.includes("ReporteVentas") == true ? (
              <Link
                to="reporteventas/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/reporteventas/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/reporteventas/" && " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Ventas" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("CorteCaja") == true ? (
              <Link
                to="cortes/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/cortes/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " + (location.pathname == "/cortes/" && " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Cortes" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
          </List>
        </Collapse>
        {/*-------------------------------------------------Config------------------------------------------------------------*/}
        <ListItemButton
          className="ps-2"
          onClick={handleClickConfig}
          style={{
            color:
              location.pathname == "/configCorreos/" ||
              location.pathname == "/configEmpresas/" ||
              location.pathname == "/gruposacceso/" ||
              location.pathname == "/administradores/"
                ? colors.neutral
                : colors.neutral,
          }}
        >
          <ListItemIcon>
            <ListItemIcon>
              <img
                src={
                  location.pathname == "/configCorreos/" ||
                  location.pathname == "/configEmpresas/" ||
                  location.pathname == "/gruposacceso/" ||
                  location.pathname == "/administradores/"
                    ? ConfigLateral
                    : ConfigLateral
                }
                className="mb-1"
                style={{
                  margin: "auto",
                  color:
                    location.pathname == "/configCorreos/" ||
                    location.pathname == "/configEmpresas/" ||
                    location.pathname == "/gruposacceso/" ||
                    location.pathname == "/administradores/"
                      ? colors.neutral
                      : colors.neutral,
                }}
                width={20}
              />
            </ListItemIcon>
          </ListItemIcon>
          <ListItemText primary="Configuraciones" />
          {activeItem === "Configuraciones" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse
          in={activeItem === "Configuraciones"}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {permisosMenu.includes("Correos") == true ? (
              <Link
                to="configCorreos/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/configCorreos/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/configCorreos/" && " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Correos" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("Empresas") == true ? (
              <Link
                to="configEmpresas/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/configEmpresas/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/configEmpresas/" && " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Empresas" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("GruposDeacceso") == true ? (
              <Link
                to="gruposacceso/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/gruposacceso/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/gruposacceso/" && " ItemActive")
                  }
                >
                  <ListItemIcon></ListItemIcon>
                  <ListItemText primary="Grupos de acceso" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {permisosMenu.includes("Administradores") == true ? (
              <Link
                to="administradores/"
                style={{
                  textDecoration: "none",
                  color:
                    location.pathname == "/administradores/"
                      ? colors.focus
                      : colors.neutral,
                }}
              >
                <ListItemButton
                  className={
                    "ps-2 " +
                    (location.pathname == "/administradores/" && " ItemActive")
                  }
                >
                  <ListItemIcon>
                    {/*<PeopleAltIcon
                  width={20}
                  style={{ margin: "auto", color: "white" }}
      />*/}
                  </ListItemIcon>
                  <ListItemText primary="Usuarios del sistema" />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
          </List>
        </Collapse>
      </List>
    </div>
  );
}
