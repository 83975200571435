import React, { useState, useEffect } from "react";
import { Alert, Avatar, Fade, Snackbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableHead, Tooltip, Grid, MenuItem, Menu } from "@mui/material";
import { Link, json } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import MoreVert from "@mui/icons-material/MoreVert";
import Swal from "sweetalert2";
import styled from "@emotion/styled";
import AccEditar from "./../../../../assets/AccEditar.svg";
import Loading from "../../../../../Includes/Loading";
import { firstLeterMayus } from "../../../../../Lib/Funciones Generales/Generales";
import { PictureAsPdf } from "@mui/icons-material";
import MailIcon from "@mui/icons-material/Mail";
import CancelIcon from "@mui/icons-material/Cancel";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import { useNavigate } from "react-router-dom";
import {
  CancelarTraspaso,
  ProcesarTraspaso,
  getListaDetalleTraspaso,
  getInfoTraspasoMerca,
} from "../Funciones/TraspasarMercancia";
import ModalProcesar from "../../OrdenCompra/Componentes/ModalProcesar";

import Pdf from "../../../../assets/Pdf.svg";

//#region
const MySwal = withReactContent(Swal);

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
}));
//#endregion

export default function Tabla({
  Lista = [],
  IsLoading,
  setLista = () => {},
  updateTable = () => {},
}) {
  //#region State V
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [severity, setSeverity] = useState("error");
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Lista.length) : 0;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [idRow, setIdRow] = useState();
  const [stateRow, setStateRow] = useState();
  const [openModalProcesar, setOpenModalProcesar] = useState(false);

  //#endregion

  //#region Handles
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    //console.log("productos", event);
    setAnchorEl(event.currentTarget);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    //console.log(page);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //#endregion

  //#region Funciones

  const Cancelar = (id) => {
    new Swal({
      title: "¿Estas seguro de cancelar esta orden?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      //console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          CancelarTraspaso(id)
            .then((resp) => {
              new Swal({
                title: "Exito!",
                text: "Cancelada correctamente",
                icon: "success",
              }).then(function () {
                updateTable();
              });
            })
            .catch((resp) => {
              alert("error al eliminar");
            });
        }
      }
    });
  };

  const Procesar = (id) => {
    new Swal({
      title: "¿Estas seguro de procesar esta orden?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, procesar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      //console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          ProcesarTraspaso(id)
            .then((resp) => {
              new Swal({
                title: "Exito!",
                text: "Procesada correctamente",
                icon: "success",
              }).then(function () {
                updateTable();
                setOpenModalProcesar(false);
              });
            })
            .catch((resp) => {
              alert("error al traspasar pedido");
            });
        }
      }
    });
  };

  //#endregion

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        // top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      {openModalProcesar && (
        <ModalProcesar
          id={idRow}
          onClose={() => {
            setOpenModalProcesar(false);
          }}
          save={Procesar}
          getProductos={getInfoTraspasoMerca}
          Boton="Procesar"
        />
      )}

      <TableContainer component={Paper}>
        <Menu
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          TransitionComponent={Fade}
        >
          <MenuItem
            onClick={() => {
              handleMenuClose();
            }}
            component={Link}
            to={"./edit/" + idRow}
          >
            <IconButton>
              <img src={AccEditar} />
            </IconButton>
            Editar
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
              Cancelar(idRow);
            }}
          >
            <IconButton>
              <CancelIcon style={{ color: "#0f0f0f" }} />
            </IconButton>
            Cancelar
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIdRow(idRow);
              setOpenModalProcesar(true);
              //Procesar(row.ID)
            }}
          >
            <IconButton>
              <AssignmentReturnedIcon style={{ color: "#0f0f0f" }} />
            </IconButton>
            Procesar
          </MenuItem>
          <MenuItem component={Link} to={"../pdf/traspasomercancia/" + idRow}>
            <Tooltip title="Imprimir informe" placement="top">
              <IconButton>
                <PictureAsPdf style={{ color: "#0f0f0f" }} />
              </IconButton>
            </Tooltip>
            Imprimir PDF
          </MenuItem>
        </Menu>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                FOLIO
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                FECHA
              </TableCell>
              <TableCell
                className="d-none d-sm-table-cell font-Avenir font-AvenirBold"
                align="left"
              >
                USUARIO
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                ORIGEN
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                DESTINO
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                ESTATUS
              </TableCell>

              <TableCell className="font-Avenir font-AvenirBold" align="right">
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={6}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? Lista.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : Lista
              ).map((row) => (
                <TableRow key={row.ID}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    000{row.FOLIO}
                  </TableCell>
                  <TableCell component="th" scope="row" className="">
                    {row.FECHA}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className="d-none d-sm-table-cell"
                  >
                    {row.USUARIO}
                  </TableCell>
                  <TableCell component="th" scope="row" className="">
                    {row.ORIGEN}
                  </TableCell>
                  <TableCell component="th" scope="row" className="">
                    {row.DESTINO}
                  </TableCell>
                  <TableCell component="th" scope="row" className="">
                    {row.ESTATUS === 1 ? (
                      <span class="badge rounded-pill bg-primary">
                        Pendiente
                      </span>
                    ) : row.ESTATUS === 2 ? (
                      <span class="badge rounded-pill bg-danger">
                        Cancelada
                      </span>
                    ) : row.ESTATUS === 3 ? (
                      <span class="badge rounded-pill bg-success">
                        Procesada
                      </span>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Grid key={row.ID} className="d-none d-sm-block">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {row.ESTATUS === 1 ? (
                          <>
                            <Link
                              to={"./edit/" + row.ID}
                              style={{ textDecoration: "none" }}
                            >
                              <Tooltip title="Editar" placement="top">
                                <IconButton>
                                  <img src={AccEditar} />
                                </IconButton>
                              </Tooltip>
                            </Link>
                            <Tooltip title="Cancelar" placement="top">
                              <IconButton onClick={() => Cancelar(row.ID)}>
                                <CancelIcon style={{ color: "#0f0f0f" }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Procesar" placement="top">
                              <IconButton
                                onClick={() => {
                                  setIdRow(row.ID);
                                  setOpenModalProcesar(true);

                                  //Procesar(row.ID)
                                }}
                              >
                                <AssignmentReturnedIcon
                                  style={{ color: "#0f0f0f" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </>
                        ) : (
                          <></>
                        )}
                        <Link
                          to={"../pdf/traspasomercancia/" + row.ID}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          <Tooltip title="Imprimir informe" placement="top">
                            <IconButton>
                              <img src={Pdf} width={20} />
                            </IconButton>
                          </Tooltip>
                        </Link>
                      </div>
                    </Grid>

                    <Grid>
                      <DrawerHeader
                        style={{ backgroundColor: "#FFFFFF" }}
                        className="d-xsm-none d-sm-block d-sm-none"
                        onClick={() => {
                          setIdRow(row.ID);
                          setStateRow(row.ACTIVO);
                        }}
                      >
                        <IconButton onClick={handleClick}>
                          <MoreVert />
                        </IconButton>
                      </DrawerHeader>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  15,
                  30,
                  100,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  //console.log(from, to, count, page);
                  return `${from} - ${to} de ${count}`;
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
