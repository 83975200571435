import React, { useState, useEffect } from "react";
import {
  DialogContent,
  Button,
  TextField,
  Card,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import Efectivo from "../../../../assets/Efectivo.svg";
import Monedero from "../../../../assets/Monedero.svg";
import Otros from "../../../../assets/Otro.svg";
import Tarjeta from "../../../../assets/Tarjeta.svg";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { formatMoneda } from "../../../../../Lib/Funciones Generales/Generales";
import { getInfoPago, guardarInfoPago } from "../../Funciones/Pedidos";

const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

export default function ModalFormaPago({
  setShowDialogFormaPago = () => {},
  setPedidoSeleccionado,
  pedidoSeleccionado,
  setShowDialogAsignarFlorista,
  getListaPedidos,
  setShowDiloglImprimir = () => {},
}) {
  const [IsGuardando, setIsGuardando] = useState(false);
  const [totalCarrito, setTotalCarrito] = useState(0);
  const [infoDePago, setInfoDePago] = useState({
    PAGADO: 1,
    COMPROBANTE: "",
    FORMA_DE_PAGO: "",
  });
  const [Values, setValues] = useState({
    id_pedido: pedidoSeleccionado,
    cobro_desde: "monitor",
    tarjeta_referencia: "",
    metodos_pago: {
      efectivo: 0,
      tarjeta: 0,
      monedero: 0,
      transferencia: 0,
    },
  });
  const [mensaje, setMensaje] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [formasPago, setFormasPago] = useState({
    efectivo: { active: false, monto: 0 },
    tarjeta: { active: false, monto: 0 },
    monedero: { active: false, monto: 0 },
    otros: { active: false, monto: 0 },
  });

  let cambio = 0;

  let Total = parseFloat(totalCarrito); // - descuento.descuento;
  let efectivo =
    formasPago.efectivo.monto == "" || formasPago.efectivo.monto == undefined
      ? 0
      : parseFloat(formasPago.efectivo.monto);
  let tarjeta =
    formasPago.tarjeta.monto == "" || formasPago.tarjeta.monto == undefined
      ? 0
      : parseFloat(formasPago.tarjeta.monto);
  let monedero =
    formasPago.monedero.monto == "" || formasPago.monedero.monto == undefined
      ? 0
      : parseFloat(formasPago.monedero.monto);
  let otros =
    formasPago.otros.monto == "" || formasPago.otros.monto == undefined
      ? 0
      : parseFloat(formasPago.otros.monto);

  let TotalPagado = efectivo + tarjeta + monedero + otros;

  if (formasPago.efectivo.monto != 0) {
    cambio = TotalPagado - Total;
  }

  const resetFormasPago = () => {
    setFormasPago({
      efectivo: { active: false, monto: 0 },
      tarjeta: { active: false, monto: 0 },
      monedero: { active: false, monto: 0 },
      otros: { active: false, monto: 0 },
    });
    const val = {
      tarjeta: 0,
      transferencia: 0,
      efectivo: 0,
      monedero: 0,
    };
  };

  const setForma = (tipo, active, monto) => {
    let Value = { active: active, monto: monto };
    if (Value.active == true && monto == 0) {
      Value.monto = "";
    }
    let NewValue = {
      ...formasPago,
      [tipo]: Value,
    };
    setFormasPago(NewValue);
    const val = {
      tarjeta: Number(tipo == "tarjeta" ? monto : formasPago.tarjeta.monto),
      transferencia: Number(tipo == "otros" ? monto : formasPago.otros.monto),
      efectivo: Number(tipo == "efectivo" ? monto : formasPago.efectivo.monto),
      monedero: Number(tipo == "monedero" ? monto : formasPago.monedero.monto),
    };
  };

  const Guardar = () => {
    new Swal({
      title: "¿Estas seguro de guardar el pago?",
      text: "Esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        guardarInfoPago(Values, formasPago)
          .then((data) => {
            if (data.codigo == "200") {
              getListaPedidos();
              MySwal.fire({
                title: "Correcto",
                icon: "success",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: true,
                confirmButtonText: "Imprimir ticket",
                showCancelButton: true,
                cancelButtonText: "Cerrar",
                timer: 10000,
              }).then((result) => {
                if (result.isConfirmed) {
                  setShowDiloglImprimir(true);
                }
                setShowDialogFormaPago(false);
                if (window.fnConsultarDetalleCompra) {
                  window.fnConsultarDetalleCompra();
                }
              });
            } else {
              setMensaje(data.mensaje);
              setOpenAlert(true);
            }
          })
          .catch((data) => {
            setMensaje(data.mensaje);
            setOpenAlert(true);
          });
      }
    });
  };

  useEffect(() => {
    getInfoPago(pedidoSeleccionado)
      .then((resp) => {
        const data = JSON.parse(resp.data);
        console.log("INFO PAGO:  ", data);
        if (Array.isArray(data)) {
          setTotalCarrito(data[0].TOTAL);
          setInfoDePago(data[0]);
        }
      })
      .catch((errr) => console.log(errr));
  }, []);

  const handleClose = () => {
    setOpenAlert(false);
  };

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Dialog fullWidth maxWidth="sm" open={true}>
        <IconButton
          aria-label="Close"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            // setPedidoSeleccionado(0)
            setShowDialogFormaPago(false);
          }}
        >
          <CloseIcon style={{ width: "60px", height: "60px" }} />
        </IconButton>
        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">Seleccionar forma de pago</h6>
        </DialogTitle>

        <DialogContent>
          {/* <FormGroup className="align-items-end">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={Values.pagado}
                                    name="pagado"
                                    error={Errores.pagado}
                                    onChange={(checked) => {
                                        if (checked == false) {
                                            resetFormasPago();
                                        }

                                        handlInputChange({
                                            target: {
                                                name: "pagado",
                                                value: checked,
                                            },
                                        });
                                    }}
                                    tituloActivo="Pagado"
                                    tituloInactivo="No Pagado"
                                    on
                                />
                            }
                            label="Pagado"
                        />
                    </FormGroup> */}

          <div className="row " style={{ minHeight: "45vh" }}>
            {/* <div className="col-12 mb-2">
                            <span className="font-AvenirMedium mb-3">Total a pagar: </span>
                            <span className="font-AvenirBold">
                                {formatMoneda(totalCarrito)}
                            </span>
                        </div>
                        <div className="col-12 mb-2">
                            {cambio > 0 ? (
                                <tr>
                                    <td className="text-end">
                                        <h6 className=" font-gray font-carrito text-end font-AvenirMedium">
                                            CAMBIO
                                        </h6>
                                    </td>
                                    <td className="text-end">
                                        <h6
                                            className="font-carrito text-end font-AvenirMedium"
                                            style={{ color: "#3abe88" }}
                                        >
                                            ${cambio}
                                        </h6>
                                    </td>
                                </tr>
                            ) : (
                                ""
                            )}
                        </div> */}

            {infoDePago.COMPROBANTE == "" && (
              <div className="w-100 px-3">
                <div
                  className="col-12 mb-2 p-3 mt-3 mb-4 mx-0"
                  style={{ backgroundColor: "#fff1d2" }}
                >
                  <strong> El comprobante no ha sido cargado </strong>
                </div>
              </div>
            )}

            <div className="col-12">
              <div style={{ maxWidth: "500_" }}>
                <h6 className="font-AvenirBold mb-3" style={{ color: "black" }}>
                  Formas de pago
                </h6>

                <div className="mt-0 d-flex justify-content-between mt-3">
                  <div className="flex-grow-1">
                    <Card
                      sx={{ width: "100%" }}
                      className={
                        "card-descuento" +
                        (formasPago.efectivo.active
                          ? " card-descuento-active "
                          : "")
                      }
                      onClick={() =>
                        setForma("efectivo", !formasPago.efectivo.active, 0)
                      }
                    >
                      <div className="d-flex p-2 align-content-center align-items-center">
                        <div className="pe-2">
                          <img
                            src={Efectivo}
                            className="img-fluid"
                            style={{ maxWidth: "40px" }}
                          />
                        </div>
                        <div className="">
                          <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                            Efectivo
                          </h6>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="ms-3" style={{ width: "150px" }}>
                    <TextField
                      id="outlined-basic"
                      label="Efectivo"
                      fullWidth
                      variant="outlined"
                      size="small"
                      disabled={!formasPago.efectivo.active}
                      value={formasPago.efectivo.monto}
                      onChange={(event) => {
                        setForma(
                          "efectivo",
                          true,
                          event.target.value.toDecimal()
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="mt-3 d-flex justify-content-between">
                  <div className="flex-grow-1">
                    <Card
                      sx={{ width: "100%" }}
                      className={
                        "card-descuento" +
                        (formasPago.tarjeta.active
                          ? " card-descuento-active "
                          : "")
                      }
                      onClick={() =>
                        setForma("tarjeta", !formasPago.tarjeta.active, 0)
                      }
                    >
                      <div className="d-flex p-2 align-content-center align-items-center">
                        <div className="pe-2">
                          <img
                            src={Tarjeta}
                            className="img-fluid"
                            style={{ maxWidth: "40px" }}
                          />
                        </div>
                        <div className="">
                          <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                            Tarjeta de Cred / Deb
                          </h6>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="ms-3" style={{ width: "150px" }}>
                    <TextField
                      id="outlined-basic"
                      label="Tarjeta"
                      fullWidth
                      size="small"
                      variant="outlined"
                      disabled={!formasPago.tarjeta.active}
                      value={formasPago.tarjeta.monto}
                      onChange={(event) => {
                        setForma(
                          "tarjeta",
                          true,
                          event.target.value.toDecimal()
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="mt-3 d-flex justify-content-between">
                  <div className="flex-grow-1">
                    <Card
                      sx={{ width: "100%" }}
                      className={
                        "card-descuento" +
                        (formasPago.monedero.active
                          ? " card-descuento-active "
                          : "")
                      }
                      onClick={() =>
                        setForma("monedero", !formasPago.monedero.active, 0)
                      }
                    >
                      <div className="d-flex p-2 align-content-center align-items-center">
                        <div className="pe-2">
                          <img
                            src={Monedero}
                            className="img-fluid"
                            style={{ maxWidth: "40px" }}
                          />
                        </div>
                        <div className="">
                          <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                            Monedero electronico
                          </h6>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="ms-3" style={{ width: "150px" }}>
                    <TextField
                      id="outlined-basic"
                      label="Monedero"
                      fullWidth
                      size="small"
                      variant="outlined"
                      disabled={!formasPago.monedero.active}
                      value={formasPago.monedero.monto}
                      onChange={(event) => {
                        setForma(
                          "monedero",
                          true,
                          event.target.value.toDecimal()
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="mt-3 d-flex justify-content-between">
                  <div className="flex-grow-1">
                    <Card
                      sx={{ width: "100%" }}
                      className={
                        "card-descuento" +
                        (formasPago.otros.active
                          ? " card-descuento-active "
                          : "")
                      }
                      onClick={() =>
                        setForma("otros", !formasPago.otros.active, 0)
                      }
                    >
                      <div className="d-flex p-2 align-content-center align-items-center">
                        <div className="pe-2">
                          <img
                            src={Otros}
                            className="img-fluid"
                            style={{ maxWidth: "40px" }}
                          />
                        </div>
                        <div className="">
                          <h6 className="font-AvenirBold parrafo2lineastruncate text-center my-0 ">
                            Transferencia
                          </h6>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="ms-3" style={{ width: "150px" }}>
                    <TextField
                      id="outlined-basic"
                      label="Transferencia"
                      fullWidth
                      size="small"
                      variant="outlined"
                      disabled={!formasPago.otros.active}
                      value={formasPago.otros.monto}
                      onChange={(event) => {
                        setForma("otros", true, event.target.value.toDecimal());
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end w-100 mt-4">
                  <TextField
                    id="outlined-basic"
                    label="Referencia"
                    fullWidth
                    size="small"
                    variant="outlined"
                    name="tarjeta_referencia"
                    value={Values.tarjeta_referencia}
                    inputProps={{ maxLength: 150 }}
                    onChange={({ target }) => {
                      setValues({ ...Values, [target.name]: target.value });
                    }}
                  />
                </div>
              </div>

              <div className="col-12 mb-2 text-end mt-3">
                <h4>
                  <span className="font-AvenirMedium mb-3">
                    Total a pagar:{" "}
                  </span>
                  <span className="font-AvenirBold">
                    {formatMoneda(totalCarrito)}
                  </span>
                </h4>
                {cambio > 0 && (
                  <h4 className="text-success">
                    <span className="font-AvenirMedium mb-3">Cambio: </span>
                    <span className="font-AvenirBold">
                      ${cambio.toFixed(2)}
                    </span>
                  </h4>
                )}
              </div>

              <div className="d-flex justify-content-end mt-5">
                <Button
                  onClick={() => setShowDialogFormaPago(false)}
                  className="btn btn-cancelar font-AvenirMedium py-2 px-4 mx-2 me-sm-0  "
                  variant="outlined"
                >
                  Cancelar
                </Button>
                <LoadingButton
                  disabled={totalCarrito == 0}
                  loading={IsGuardando}
                  loadingPosition="start"
                  onClick={() => Guardar()}
                  className="btn btn-Crexendo font-AvenirMedium  py-2 px-4 ms-3"
                  variant="contained"
                >
                  <span className={IsGuardando ? "px-4" : "px-2"}>
                    {IsGuardando ? "Guardando..." : "Pagar"}
                  </span>
                </LoadingButton>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
