import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import ExportarDanger from "../../../../assets/ExportarDanger.svg";
import ExportarSucess from "../../../../assets/ExportarSucess.svg";
import { Col, Row } from "react-bootstrap";
import TableFacturas from "./Tabla";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import SelectOrdenamiento from "../../../../includes/SelectOrdenamiento";
import Buscador from "../../../../includes/Buscador";
import { getFacturas } from "../Funciones/Facturas";
import MapIcon from "@mui/icons-material/Map";
import { BasicDatePicker } from "../../../../includes/DatePicker";
import {
  ConvertDate,
  GetDate,
} from "../../../../../Lib/Funciones Generales/ObtenerFecha";
import ModalEnvioFactura from "./Dialogos/ModalEnvioFactura";
import ModalCancelarFactura from "./Dialogos/ModalCancelarFactura";

const Facturas = () => {
  const [facturaSeleccionada, setFacturaSeleccionada] = useState(-1);
  const [correoEnvio, setCorreoEnvio] = useState("");
  const [showModalEnviarFactura, setShowModalEnviarFactura] = useState(false);
  const [showModalCancelarFactura, setShowModalCancelarFactura] =
    useState(false);

  const [ValueSelect, setValueSelect] = useState("1");
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  const [rangoFecha, setRangoFecha] = useState({
    inicio: GetDate(-7),
    fin: GetDate(0),
  });

  const handleChangeFecha = (tipo, fecha) => {
    fecha = ConvertDate(fecha);
    setRangoFecha({ ...rangoFecha, [tipo]: fecha });
  };

  const getListaFacturas = () => {
    setIsLoading(true);
    getFacturas(
      0,
      0,
      ValueSelect,
      ValueBusqueda,
      rangoFecha.inicio,
      rangoFecha.fin
    )
      .then((resp) => {
        let list = JSON.parse(resp.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = `Facturas`;
  }, []);

  useEffect(() => {
    getListaFacturas();
  }, [ValueBusqueda, ValueSelect, rangoFecha]);

  return (
    <>
      <div class="d-flex justify-content-between flex-wrap my-4">
        <div className="p-2">
          <h4 className="text-left font-AvenirBold">Facturas</h4>
        </div>
        <div className="p-2">
          <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
            <Link to="./add" style={{ textDecoration: "none" }}>
              <Button className="btn btn-Crexendo px-4 " variant="contained">
                Nueva factura
              </Button>
            </Link>
          </Stack>
        </div>
      </div>

      <Card variant="outlined" className="p-0 shadow">
        <Row className="p-3 mb-2">
          {/*
 <Col xs={12} md={6}>
            <Buscador
              placeholder="Buscar factura por cliente"
              ValueBusqueda={ValueBusqueda}
              setValueBusqueda={setValueBusqueda}
            />
          </Col> 

            */}

          <Col xs={12} md={3} className="mt-3 mt-md-0">
            <BasicDatePicker
              fullWidth
              format="DD/MM/YYYY"
              label="Desde"
              size="small"
              fecha={rangoFecha.inicio}
              setFecha={(fecha) => {
                handleChangeFecha("inicio", fecha);
              }}
            />
          </Col>
          <Col xs={12} md={3} className="mt-3 mt-md-0">
            <BasicDatePicker
              fullWidth
              format="DD/MM/YYYY"
              label="Hasta"
              size="small"
              fecha={rangoFecha.fin}
              setFecha={(fecha) => {
                handleChangeFecha("fin", fecha);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TableFacturas
              Lista={Lista ?? []}
              IsLoading={IsLoading}
              setLista={setLista}
              ValueBusqueda={ValueBusqueda}
              setFacturaSeleccionada={setFacturaSeleccionada}
              setShowModalEnviarFactura={setShowModalEnviarFactura}
              setShowModalCancelarFactura={setShowModalCancelarFactura}
              setCorreoEnvio={setCorreoEnvio}
            />
          </Col>
        </Row>
      </Card>

      {showModalEnviarFactura &&
        facturaSeleccionada != -1 &&
        correoEnvio != "" && (
          <ModalEnvioFactura
            facturaSeleccionada={facturaSeleccionada}
            correoEnvio={correoEnvio}
            setCorreoEnvio={setCorreoEnvio}
            setFacturaSeleccionada={setFacturaSeleccionada}
            setShowModalEnviarFactura={setShowModalEnviarFactura}
          />
        )}

      {showModalCancelarFactura && (
        <ModalCancelarFactura
          facturaSeleccionada={facturaSeleccionada}
          correoEnvio={correoEnvio}
          setCorreoEnvio={setCorreoEnvio}
          setFacturaSeleccionada={setFacturaSeleccionada}
          setShowModalCancelarFactura={setShowModalCancelarFactura}
          getListaFacturas={getListaFacturas}
        />
      )}
    </>
  );
};

export default Facturas;
