import { stringify } from "query-string";

import { getData, postdData, postUrl } from "../../../../../Context/backend";

const LAT = 1,
  LNG = 0;

export const getListaPuntos = (LISTA_PUNTOS) => {
  let ListaPuntos = [];
  if (LISTA_PUNTOS) {
    const Puntos = LISTA_PUNTOS.split(",");
    Puntos.map((punto) => {
      const LatLng = punto.trim().split(" ");
      if (LatLng.length > 0) {
        ListaPuntos.push({
          lat: parseFloat(LatLng[LAT].trim()),
          lng: parseFloat(LatLng[LNG].trim()),
        });
      }
    });
  }
  return ListaPuntos;
};

///////////////////
/* FUNCIONES GET */
///////////////////
export const getZonas = async (iTake, iSkip, Order, query) => {
  let data = [];
  let queryParamsObj = {
    iTake: iTake,
    iSkip: iSkip,
    Order: Order,
    query: query,
  };

  const url = `zonas/Listar?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      resolve(data);
    } else {
      reject(res);
    }
  });
};

export const guardarZona = async (Values, Errores, setErrores, ListaPuntos) => {
  let error = false;

  if (!Values.nombre || Values.nombre == "") {
    Errores.nombre = true;
    setErrores(Errores);
    error = true;
  }


  if(!Values.precio_de_envio){
    Values.precio_de_envio = 0;
  }

  if(!Values.precio_de_envio.trim() == ""){
    Values.precio_de_envio = 0;
  }

  if (!error) {
    let data = [];

    Values.id = parseFloat(Values.id);
    Values.precio_de_envio = parseFloat(Values.precio_de_envio);
    Values.geocerca = ListaPuntos;

    let queryParamsObj = {};
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "zonas/Insertar"
        : "zonas/Editar";

    const body = Values;
    const res = await postdData(url, body);

    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const eliminarZona = async (id) => {
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
    };
    const url = `zonas/Eliminar?${stringify(queryParamsObj)}`;
    const res = await postdData(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getInfoZona = async (id) => {
  //console.log("Entro a la peticion getInfZona");
  let data = [];
  let queryParamsObj = {
    ID: id,
  };
  const url = `zonas/Consultar?${stringify(queryParamsObj)}`;
  //console.log(url);
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      //console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de esta zona",
        });
      } else {
        data = JSON.parse(res.data.respuesta.data)[0];
        //console.log(data);
        const ListaPuntos = getListaPuntos(data.LISTA_PUNTOS);
        let Value = {
          id: String(data.ID),
          nombre: String(data.NOMBRE),
          precio_de_envio: String(data.PRECIO_DE_ENVIO),
          descripcion: String(data.DESCRIPCION),
          geocerca: ListaPuntos,
        };
        resolve(Value);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const getGeocercaCadaZona = async () => {
  //console.log("Entro a la peticion getInfZona");
  let data = [];
  let queryParamsObj = {};
  const url = `zonas/ConsultarGeocercaCadaZona?${stringify(queryParamsObj)}`;
  //console.log(url);
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = JSON.parse(res.data.respuesta.data);
      console.log("ZONAS: ", data);
      resolve(data);
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const getPrecioZona = async (lat, lng) => {
  //console.log("Entro a la peticion getInfZona");
  let data = [];
  let queryParamsObj = { lat, lng };
  const url = `zonas/ConsultarPrecio?${stringify(queryParamsObj)}`;
  //console.log(url);
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      //console.log(data);
      data = JSON.parse(res.data.respuesta);
      //console.log(data);
      resolve(data);
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

export const Desactivar = async (id, checked) => {
  // console.log("Activar desactivar");
  // console.log(id, checked);
  //return "ok";
  if (id != undefined) {
    let data = [];
    let queryParamsObj = {
      id: id,
      activo: checked,
    };
    // console.log(queryParamsObj)
    const url = `zonas/Desactivar?${stringify(queryParamsObj)}`;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
};

export const getOpciones = async (query = "") => {
  let data = [];
  let queryParamsObj = {
    query: query,
  };
  const url = `zonas/getOpciones?${stringify(queryParamsObj)}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data.respuesta;
      // console.log(data);
      resolve(data);
    } else {
      reject(res);
    }
  });
};
