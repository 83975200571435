import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { TableHead } from "@mui/material";
import Loading from "../../../../../Includes/Loading";
import {
  firstLeterMayus,
  formatMoneda,
} from "../../../../../Lib/Funciones Generales/Generales";
import Menos from "../../../../assets/Menos.svg";
import Mas from "../../../../assets/Mas.svg";
import EliminarRojo from "../../../../assets/EliminarRojo.svg";

export default function TableDetalleOrden({
  Lista,
  IsLoading,
  productosCarrito,
  setProductosCarrito,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState();

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - productosCarrito.length)
      : 0;

  let total = 0;
  {
    if (productosCarrito.length > 0) {
      for (let row of productosCarrito) {
        if (!isNaN(row.PRECIO) && !isNaN(parseInt(row.CANTIDAD))) {
          total += parseFloat(row.PRECIO) * parseInt(row.CANTIDAD);
        }
      }
    } else {
      total = 0;
    }
  }

  const addItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PRODUCTO === newItem.ID_PRODUCTO)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD += 1;
    } else {
      //items.push({...newItem, CANTIDAD: 1});
      items.push(newItem);
    }
    setProductosCarrito(items);
  };

  const disminuirItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PRODUCTO === newItem.ID_PRODUCTO)
      : -1;
    if (itemIndex !== -1) {
      items[itemIndex].CANTIDAD -= 1;
      if (items[itemIndex].CANTIDAD <= 0) {
        items.splice(itemIndex, 1);
      }
    } else {
      // items.push(newItem);
    }
    setProductosCarrito(items);
  };

  const deleteItem = (newItem) => {
    const items = [...productosCarrito];
    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PRODUCTO === newItem.ID_PRODUCTO)
      : -1;
    if (itemIndex !== -1) {
      items.splice(itemIndex, 1);
    } else {
    }
    setProductosCarrito(items);
  };

  const handlInputChange = ({ target }) => {
    let ID = target.id;
    let Value = target.value;
    // validar que sea entero  Value
    const items = [...productosCarrito];
    //console.log(ID);
    //console.log(items);

    const itemIndex = Array.isArray(items)
      ? items.findIndex((item) => item.ID_PRODUCTO == ID)
      : -1;
    if (itemIndex !== -1) {
      let cantidad = parseInt(Value);
      items[itemIndex].CANTIDAD = cantidad.toString();
    } else {
      alert("producto no encontrado");
    }
    setProductosCarrito(items);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                DESCRIPCION
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="left">
                PRODUCTO TERMINADO
              </TableCell>
              <TableCell className="font-Avenir font-AvenirBold" align="right">
                CANTIDAD
              </TableCell>
            </TableRow>
          </TableHead>

          {IsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" colSpan={5}>
                  <Loading />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? productosCarrito.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : productosCarrito
              ).map((row) => (
                <TableRow key={row.ID_PRODUCTO}>
                  <TableCell component="th" scope="row" className="">
                    {firstLeterMayus(row.DESCRIPCION_PRODUCTO)}
                  </TableCell>
                  <TableCell component="th" scope="row" className="">
                    {row.PRODUCTO_TERMINADO}
                  </TableCell>
                  <TableCell component="th" scope="row" className="">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <IconButton className="mx-2" onClick={() => addItem(row)}>
                        <img src={Mas} style={{ width: "15px" }} />
                      </IconButton>
                      <div
                        className="d-flex justify-content-center flex-column"
                        style={{ maxWidth: "150px" }}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          id={row.ID_PRODUCTO}
                          label="Cantidad"
                          variant="outlined"
                          name={row.ID_PRODUCTO}
                          value={isNaN(row.CANTIDAD) ? "" : row.CANTIDAD}
                          //  helperText={Errores.cantidad ? "Cantidad no valida" : ""}
                          onChange={handlInputChange}
                        />
                      </div>
                      <IconButton
                        aria-label="delete"
                        className="mx-2"
                        onClick={() => disminuirItem(row)}
                      >
                        <img src={Menos} style={{ width: "15px" }} />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        className="   mx-1"
                        onClick={() => deleteItem(row)}
                      >
                        <img src={EliminarRojo} style={{ width: "15px" }} />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              <TableCell></TableCell>
              <TableCell></TableCell>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* <TableCell
                  className="font-Avenir font-AvenirBold"
                  align="right"
                >
                  <div style={{ display: "flex" }}>
                    <p>Total: {formatMoneda(total)}</p>
                  </div>
                </TableCell> */}
              </div>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}
