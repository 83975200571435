import * as React from "react";
import {
  Avatar,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";

import { Col, Row } from "react-bootstrap";

import Categorias from "./Categorias";
import Productos from "./Productos";

export default function BuscarProductos({
  productosCarrito = [],
  setProductosCarrito = () => {},
  IVA,
  sucursalSelector = 0,
}) {
  const [categoria, setCategoria] = React.useState(0);
  const [query, setQuery] = React.useState("");
  const [valueBusqueda, setValueBusqueda] = React.useState("");
  const [intervalo, setIntervalo] = React.useState("");

  const handleChangeBuscador = (event) => {
    clearInterval(intervalo);
    setQuery(event.target.value);
    let id = setInterval(function () {
      clearInterval(id);
      setValueBusqueda(event.target.value);
      setCategoria(0);
    }, 1000);
    setIntervalo(id);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      clearInterval(intervalo);
      setValueBusqueda(e.target.value);
      setCategoria(0);
    }
  };

  const limpairBusqueda = () => {
    setValueBusqueda("");
    setQuery("");
    setCategoria(0);
  };

  return (
    <>
      <Row>
        <Col xs={12} className="py-2 ps-1">
          <FormControl
            size="small"
            sx={{ m: 2, width: "95%" }}
            variant="outlined"
            fullWidth
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Buscar
            </InputLabel>
            <OutlinedInput
              onChange={handleChangeBuscador}
              onKeyDown={handleKeyDown}
              size="small"
              id="outlined-adornment-password"
              fullWidth
              value={query}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Col>

        <Col xs={12}>
          {categoria == 0 && query == "" ? (
            <Categorias
              setCategoria={setCategoria}
              categoria={categoria}
              sucursal={sucursalSelector}
              ecommerce={sucursalSelector == 0 ? true : false}
            />
          ) : (
            <Productos
              productosCarrito={productosCarrito}
              categoria={categoria}
              valueBusqueda={valueBusqueda}
              limpairBusqueda={limpairBusqueda}
              setCategoria={setCategoria}
              setProductosCarrito={setProductosCarrito}
              IVA={IVA}
              sucursalSelector={sucursalSelector}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
