import * as React from "react";
import { MenuItem, TextField } from "@mui/material";

export default function SelectOrderPedidos({
  ValueSelect,
  size = "",
  handleChanges,
  currencies = [
    {
      value: "1",
      label: "Fecha y hora de entrega",
    },
    {
      value: "2",
      label: "Folio de compra",
    },
  ],
}) {
  return (
    <TextField
      fullWidth
      id="outlined-select-currency"
      select
      onChange={handleChanges}
      label="Ordenamiento"
      value={ValueSelect}
      className="mt-0 mt-sm-0 mt-md-0"
      size={size}
    >
      {currencies.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
